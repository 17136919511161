@import "../colors";
@import "../effects";
@import "../themes";

.executive-summary-product-performance {
  font-size: var(--regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color") !important;
  }

  .tile {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
    }
    height: 100%;
    border: none;
    border-radius: 0;
    display: flex;

    .tile-header {
      display: flex;
      font-size: var(--header_font);
      margin-bottom: 1rem;
      justify-content: space-between;
      align-items: center;

      .title-container {
        display: flex;

        .title {
          display: flex;
          flex-direction: column;
        }

        .sub-text {
          font-size: var(--smaller_regular_font_size);
        }
      }

      .filters-container {
        display: flex;
      }
    }
  }

  .metric-tile {
    .tile-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .value {
      display: flex;
      align-items: center;
      font-size: var(--small_number);
    }

    .comparison {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .yoy-comparison,
      .pop-comparison {
        display: flex;
        align-items: center;
      }
    }
  }

  .grid-3 {
    display: grid;
    grid-template-columns: 0.8fr 1.1fr 1.1fr;
    gap: 0.8rem;
  }

  .metric-tiles-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .performance-row {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1.75fr 1fr;
    gap: 0.8rem;
    min-height: 30rem;
  }

  .map-tile {
    display: flex;
    @media screen and (min-width: 1920px) {
      height: 43rem;
    }
    height: 31.5rem;
  }

  .mapWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;

    .map {
      height: 100%;
      width: 85%;
    }

    #legenddiv {
      width: 15%;
    }
  }

  .performance-by-market-tables {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .performance-by-market-table {
    table {
      border-collapse: collapse;
      td {
        border: 1px solid;
        height: 2rem;
      }
      thead {
        tr:nth-of-type(1),
        tr:nth-of-type(2) {
          td:nth-of-type(1) {
            border: none;
          }
        }

        tr:nth-of-type(2) {
          td:nth-of-type(1) {
            width: 10%;
          }
          td:nth-of-type(2),
          td:nth-of-type(3),
          td:nth-of-type(4) {
            width: 30%;
          }
        }
      }
    }
  }

  .top-performance-table {
    thead {
      td:not(:first-of-type) {
        background: #008000;
        color: #fff;
      }
    }
  }

  .bottom-performance-table {
    thead {
      td:not(:first-of-type) {
        background: #ff0000;
        color: #fff;
      }
    }
  }

  .products-activated-market {
    margin-top: 1rem;
    height: 30rem;
    display: flex;
  }

  .grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.8rem;
    margin-top: 1rem;
  }

  .points-by-visits {
    margin-top: 1rem;
    height: 30rem;
    min-height: 30rem;
  }
}
