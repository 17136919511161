@import "../colors";

.legend {
  display: flex;
  max-height: 30px;
  position: relative;
}

#kbasTrendLegend {
  max-height: 85px;
}
