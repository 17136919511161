@import "../colors";
@import "../effects";
@import "../themes";

.midas_sotu {
  display: grid;
  grid-gap: 0.75rem;
  grid-template-areas: "fmi_cumulative";
  grid-template-rows: max-content;

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .primary_background {
    @include themify($themes) {
      background: themed("primary-tile-background");
    }
  }

  max-width: calc(100vw - 4em);

  h3 {
    font-size: var(--header_font);
    font-weight: 300;
    margin-bottom: 0.2em;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }

    text-align: left;
  }

  .quarter_title {
    font-size: var(--regular_font_size);
  }

  .chartWrapper,
  .graph {
    height: 100%;
    width: 100%;
    min-height: 250px;
    padding-bottom: 1px;
    min-height: 30vh;
  }

  .header {
    display: block;
  }

  .toogle_container {
    font-size: var(--smaller_regular_font_size);
    display: flex;
    gap: 5px;
  }

  .title_container {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.cumulative_trend,
.cumulative_bar,
.mtp_spend {
  padding: 0.2em 1.5em 0.2em;
  position: relative;
  display: flex;
  flex-direction: column;
}

.legend {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  font-size: var(--regular_font_size);
  padding-left: 5em;
  margin-bottom: 0.2em;

  .legend_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1em;

    .legend_box {
      width: 1rem;
      height: 1rem;
      margin-right: 1em;
      border-radius: 0.2em;
    }
  }
}

@media screen and (min-width: 1250px) {
  .midas_sotu {
    .fmi_cumulative {
      display: grid;
      grid-template-areas: "cumulative_trend" "cumulative_bar";
      grid-template-columns: 1fr 1fr;

      .main_content {
        min-height: 230px;

        .no_data {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .chartWrapper,
        .graph {
          min-height: 250px !important;
        }
      }

      .cumulative_trend {
        @include themify($themes) {
          border-right: themed("thinBorder");
        }
      }
    }

    .mtp_spend {
      .charts {
        display: grid;
        grid-template-areas: "mtp_quarterly" "mtp_yearly";
        grid-template-columns: calc(80% - 0.375rem) calc(20% - 0.375rem);

        .mtp_spend_quarterly {
          .charts {
            display: grid;

            grid-template-columns: 1.3fr 1fr 1fr 1fr;

            .quarter_breakdown {
              display: flex;
              flex-flow: column;
              align-items: center;
              min-height: 230px;

              .no_data {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .chartWrapper {
              @include themify($themes) {
                border-right: themed("thinBorder");
              }
            }

            .chartWrapper,
            .graph {
              min-height: 230px !important;
            }
          }
        }

        .mtp_spend_yearly {
          .quarter_breakdown {
            display: flex;
            flex-flow: column;
            align-items: center;
          }

          .chartWrapper,
          .graph {
            height: 235px !important;
          }
        }
      }
    }

    .chartWrapper,
    .graph {
      height: 230px !important;
    }

    .fmi_tile_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media screen and (min-width: 1920px) {
  .midas_sotu {
    .fmi_cumulative {
      display: grid;
      grid-template-areas: "cumulative_trend" "cumulative_bar";
      grid-template-columns: calc(50% - 0.375rem) calc(50% - 0.375rem);

      .main_content {
        min-height: 330px;

        .no_data {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .chartWrapper,
        .graph {
          min-height: 350px !important;
        }
      }
    }

    .mtp_spend {
      .charts {
        display: grid;
        grid-template-areas: "mtp_quarterly" "mtp_yearly";
        grid-template-columns: calc(80% - 0.375rem) calc(20% - 0.375rem);

        .mtp_spend_quarterly {
          .charts {
            display: grid;

            grid-template-columns: 1.3fr 1fr 1fr 1fr;

            .quarter_breakdown {
              display: flex;
              flex-flow: column;
              align-items: center;
              min-height: 330px;

              .no_data {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            .chartWrapper {
              @include themify($themes) {
                border-right: themed("thinBorder");
              }
            }

            .chartWrapper,
            .graph {
              min-height: 330px !important;
            }
          }
        }

        .mtp_spend_yearly {
          .quarter_breakdown {
            display: flex;
            flex-flow: column;
            align-items: center;
          }

          .chartWrapper,
          .graph {
            height: 335px !important;
          }
        }
      }
    }

    .chartWrapper,
    .graph {
      height: 230px !important;
    }

    .fmi_tile_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media screen and (min-width: 390px) {
  .midas_sotu {
    .chartWrapper,
    .graph {
      min-height: 65vh;
    }
  }
}

.cluster_wrapper {
  margin-bottom: 4em;
}
