@import "../colors";
@import "../effects";
@import "../themes";

.ccs_europe_activation_container {
  display: grid;
  grid-template-rows: auto;
  row-gap: 1rem;
  font-size: var(--regular_font_size);
  margin-bottom: 2em;
  @include themify($themes) {
    color: themed("primary-font-color");
  }

  h3 {
    font-weight: normal;
    font-size: var(--regular_font_size);
  }
}
.activation_toggle_container {
  display: flex;
  justify-content: space-between;
}

.activation_container {
  padding: 1em;
  border-radius: 5px;
  position: relative;

  @include themify($themes) {
    border: 1px solid themed("primary-font-color");
    background: themed("ccsContentBackground");
  }

  .chart_area {
    margin-top: 2em;
  }

  .activation_breakdown_chart {
    height: 40vh;
  }
  .show_more_btn {
    color: $cyan;
    cursor: pointer;
  }
  .show_more_div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: calc(var(--regular_font_size) + 1em);
  }
}
.activationTableWrapper {
  padding-top: 1.5em;
}
.activation_overview_title {
  text-align: center;
}

.activation_overview_values {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  column-gap: 1rem;

  .value_container {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

.MuiLinearProgress-root {
  width: 100%;
}

.ccs_activation_rate_breakdown {
  display: grid;
  gap: 1rem;
}

@media screen and (min-width: 992px) {
  .activation_overview {
    width: 85%;
    margin: 0 auto;
  }
  .ccs_activation_rate_breakdown {
    grid-template-columns: repeat(3, 1fr);
  }
}
