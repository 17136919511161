@import "../colors";
@import "../themes";

.data_documentation {
  ul {
    padding-left: 1rem;
    margin-bottom: 1em;
  }
  li {
    @include themify($themes) {
      color: themed("primary-font-color");
    }
  }
  i {
    padding-right: 0.2rem;
  }
}
