@import "../colors";
@import "../effects";
@import "../themes";

.seo_dashboard_title {
  li {
    display: flex;
    align-items: center;
    position: relative;
  }
}

.seo_tooltip {
  &__icon {
    background-color: transparent;
    color: $white;
    width: 1.275rem;
    height: 1.275rem;
    font-family: "nissan_light", "Open Sans", sans-serif;
    font-size: 1rem;
    cursor: pointer;

    @include themify($themes) {
      background-image: themed("infoTooltipIcon");
    }

    background-size: 1.1em;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 0.5rem;
    position: relative;
    display: flex;
    align-items: center;
  }

  &__data {
    display: none;
    background-color: rgba(0, 0, 0, 0.8);
    color: $bright_white;
    border-radius: 6px;
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    font-size: 0.8rem;
    top: 100%;

    &.indicator_tooltip {
      top: 20%;
    }

    span {
      color: $cyan;
      padding-left: 0.1rem;

      &:hover {
        color: $cyan_bright;
      }
    }

    &.show {
      display: block;
    }
  }
}

.seo_container {
  display: grid;
  row-gap: 0.75rem;
}

.seo_section {
  display: grid;
  grid-template-rows: auto;
  row-gap: 0.75rem;
}

.seo_tile {
  padding: 1.25em 1.5em;
  border-radius: 5px;
  position: relative;

  @include themify($themes) {
    background: themed("primary-tile-background");
  }

  &.disabled {
    * {
      @include themify($themes) {
        color: themed("secondary-font-color");
        pointer-events: none;
      }
    }
  }

  h3 {
    font-size: var(--header_font);
    text-align: left;
    margin-bottom: 0.75em;
    font-weight: 300;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }
  }
}

.performance_overview_container {
  display: grid;
  grid-gap: 1.5em;
  grid-auto-rows: auto;
  position: relative;

  .volume_indicator {
    display: grid;
    grid-template-areas: "header" "title" "main" "conversion";
    padding: 0.85em 0.5em;
    position: relative;

    @include themify($themes) {
      border: themed("thinBorder");
      color: themed("primary-font-color");
    }

    &.disabled {
      @include themify($themes) {
        color: themed("secondary-font-color");

        .indicator.up {
          background-image: url("../../images/Ratings/up_arrow_gray.svg");
        }

        .indicator.down {
          background-image: url("../../images/Ratings/down_arrow_gray.svg");
        }
      }
    }
  }

  .volume_indicator_title {
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h4 {
    font-weight: 300;
    font-size: var(--regular_font_size);
    margin: auto 0;
    text-align: left;
  }

  .main {
    font-size: 1.75rem;
    margin: 0.25em 0;
    align-self: center;
    grid-area: main;
  }

  .comparison-values {
    p {
      display: flex;
      align-items: center;
      font-size: var(--regular_font_size);
    }
  }
}

.insights-tile {
  overflow-y: auto;
  @include scroll-bar;
}

.insights-container {
  font-size: var(--regular_font_size);
  position: relative;

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .padded-content {
    padding: 0.2rem 0;
  }

  .insight-content {
    div {
      @include themify($themes) {
        color: themed("primary-font-color") !important;
      }
    }
  }

  a {
    color: $cyan;
    text-decoration: none;
  }
}

.filters_container {
  display: flex;
  align-items: center;

  .filter {
    border-bottom: 0;

    @include themify($themes) {
      border-right: 1px solid themed("borderColor");
    }

    &:last-of-type {
      border-right: none;
      padding-left: 1em;
    }

    .filter_input {
      width: 90%;
    }
  }

  .showOptions {
    &.metrics_list {
      display: flex;
      flex-flow: column;

      label {
        padding: 0.5rem;
        font-size: var(--form_font);

        // cursor: pointer;
        @include themify($themes) {
          color: themed("filter_font_color");
        }
      }

      input {
        margin-right: 0.5rem;
      }
    }
  }
}

.filter_menu {
  @include themify($themes) {
    background-color: themed("primary-font-color");
    box-shadow: 0 0 0.5em rgba(themed("secondary-font-color"), 0.5);
  }

  padding: 0.25em 0;
  position: absolute;
  display: none;
  z-index: 9999;
  text-align: left;
  max-height: 19rem;
  overflow-y: auto;
  color: $grey_transparent;
  left: 0;
  right: 0;
  top: 100%;
  text-transform: initial;
  @include scroll-bar;

  &.showOptions {
    display: block;
  }

  li,
  .multiselect_container {
    list-style: none;
    cursor: pointer;
    font-size: 1.2em;
    letter-spacing: 0;
    font-family: "nissan", "Open Sans", sans-serif;
    display: inline-block;
    width: 100%;
    word-break: break-word;
    padding: 0.85em 0.5em;
    margin-bottom: 0;

    @include themify($themes) {
      color: themed("filter_font_color");
    }

    @media screen and (min-width: 666px) {
      font-size: 1.5em;
      padding: 0.85em 0.5em;
    }

    @media screen and (min-width: 1024px) {
      font-size: var(--form_font);
      padding: 0.5em;
      @include no-select;
    }

    &:hover,
    &.selected {
      @include themify($themes) {
        background-color: themed("filter_background_color");
      }
    }
  }
}

.visits_trend_breakdown,
.impressions_trend_breakdown {
  max-width: calc(100vw - 4em);
}

#impressions-chart,
#visits-chart {
  padding-bottom: 1rem;
}

.breakdown-table {
  overflow-x: auto;
  @include scroll-bar;

  p {
    font-size: var(--regular_font_size);

    // padding: 1em;
    @include themify($themes) {
      color: themed("primary-font-color");
    }
  }

  .table-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0 0.5rem;
  }

  .hidden-columns {
    span {
      font-size: var(--regular_font_size);
      color: $cyan;
      cursor: pointer;
      font-weight: 700;
    }
  }

  .search_container {
    display: flex;
    padding: 0.5rem 0;

    .input_arrow {
      padding: 0.2rem 0;
    }

    .search_criteria {
      width: 40%;

      .arrow_down {
        left: 0;
        bottom: 20%;
      }

      .filter_input {
        margin-left: 1rem;
      }

      @include themify($themes) {
        border-bottom: 1px solid themed("borderColor");
      }

      .filter_header {
        padding: 0.5rem 0;
      }
    }

    .search_bar {
      display: flex;
      align-items: flex-end;

      @include themify($themes) {
        border-bottom: 1px solid themed("borderColor");
      }

      input {
        font-size: var(--regular_font_size);
      }

      span {
        height: 1rem;
        width: 1rem;

        &.search {
          background-image: url(../../images/Filter/search-icon.svg);
        }

        &.cancel {
          background-image: url(../../images/Filter/cancel.svg);
        }
      }
    }
  }

  .chart_apply_btn {
    display: flex;
    width: 30%;

    .btn {
      font-size: var(--smaller_regular_font_size);
      margin: auto 0 0 auto;
      cursor: pointer;
      background-color: $cyan;
      border-radius: 0.25rem;
      padding: 0.375rem 0.75rem;

      @include themify($themes) {
        color: themed("primary-font-color");
      }
    }
  }

  &.disabled {
    * {
      @include themify($themes) {
        color: themed("secondary-font-color");
        pointer-events: none;
      }
    }

    table {
      @include themify($themes) {
        color: themed("secondary-font-color");

        .specific_link {
          @include themify($themes) {
            color: themed("secondary-font-color");
          }
        }

        .metric_column__bar {
          background: $white;
          border: 1px solid $white;
        }

        .indicator.up {
          background-image: url("../../images/Ratings/up_arrow_gray.svg");
        }

        .indicator.down {
          background-image: url("../../images/Ratings/down_arrow_gray.svg");
        }
      }
    }
  }

  table {
    @include themify($themes) {
      color: themed("primary-font-color");
    }

    a {
      text-decoration: none;
      white-space: nowrap;
    }

    tr {
      th {
        padding: 0.4em 0;

        &:first-of-type {
          padding-left: 0.4em;
        }
      }

      td {
        position: relative;

        .table_link_div {
          overflow: hidden;
          text-overflow: ellipsis;
          color: $cyan;

          &:hover + .tooltip-cell {
            display: unset;
          }
        }

        .tooltip-cell {
          display: none;
          background-color: rgba(0, 0, 0, 0.8);
          color: #fff;
          border-radius: 6px;
          position: absolute;
          z-index: 1;
          bottom: 100%;
          left: 100%;
          margin-left: -100%;
          cursor: pointer;
          font-size: 0.8rem;
          padding: 0.2rem;
          width: max-content;
          max-width: 90vw;
          word-break: break-all;
          white-space: pre-wrap;

          @media only screen and (min-width: 1280px) {
            max-width: calc(50vw - 2rem - 4em);
          }
        }

        .td_comparison {
          .value {
            min-width: 3.5em;
          }
        }
      }

      &.disabled {
        @include themify($themes) {
          color: themed("secondary-font-color");
        }

        * {
          @include themify($themes) {
            color: themed("secondary-font-color");
          }
        }

        pointer-events: none;

        td .table_link_div,
        a {
          @include themify($themes) {
            color: themed("secondary-font-color");
          }
        }

        .metric_column__bar {
          background: $white;
          border: 1px solid $white;
        }

        .indicator.up {
          background-image: url("../../images/Ratings/up_arrow_gray.svg");
        }

        .indicator.down {
          background-image: url("../../images/Ratings/down_arrow_gray.svg");
        }

        & + .child-row {
          color: $white;
          pointer-events: none;

          .metric_column__bar {
            background: $white;
            border: 1px solid $white;
          }

          .indicator.up {
            background-image: url("../../images/Ratings/up_arrow_gray.svg");
          }

          .indicator.down {
            background-image: url("../../images/Ratings/down_arrow_gray.svg");
          }
        }
      }
    }

    .metric_column {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
      cursor: pointer;

      &:hover {
        .tooltip-cell {
          display: unset;
        }
      }

      &__bar {
        width: 0.5rem;
        height: 1rem;

        &.purple {
          background: $charts_purple;
          border: 1px solid $charts_purple;
        }

        &.green {
          background: $charts_green;
          border: 1px solid $charts_green;
        }
      }
    }
  }

  .pagination {
    font-size: var(--regular_font_size);
    display: flex;
    list-style: none;

    @include themify($themes) {
      color: themed("primary-font-color");
    }

    button {
      background: none;
      border: none;

      @include themify($themes) {
        color: themed("secondary-font-color");
      }

      padding: 0 1rem;
      cursor: pointer;
      outline: none;
      font-weight: bold;

      &:focus {
        outline: none;
      }

      &.active {
        @include themify($themes) {
          color: themed("primary-font-color");
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .seo_section {
    grid-template-rows: unset;
    row-gap: unset;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.75rem;
  }

  .seo_tooltip__data {
    top: -0.5rem;
    left: 30%;
    width: 80vw;

    &.indicator_tooltip {
      top: -0.5rem;
      left: 75%;
      width: 30vw;
    }
  }

  .seo_container {
    grid-template-rows: auto minmax(33vh, 33vh) auto auto;
  }

  .performance_overview_container {
    grid-template-rows: unset;
    grid-template-columns: repeat(3, 1fr);
    min-height: 80%;
  }

  #impressions-chart,
  #visits-chart {
    height: 40vh;
    padding-bottom: 1rem;
  }
}

@media only screen and (min-width: 1280px) and (max-height: 690px) {
  .seo_container {
    grid-template-rows: auto 250px auto auto;
  }
}
