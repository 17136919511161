@import "../colors";
@import "../themes";

$checkbox_width: 1em;
$checkbox_height: 1em;

.baseline_definition {
  font-size: var(--smaller_regular_font_size);
  @include themify($themes) {
    color: themed("secondary-font-color");
  }
}

.baseline_trend {
  .main_content {
    display: flex;
    flex-flow: column;
    position: relative;

    .oce_metric_selection {
      margin-top: -2em;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      @media screen and (max-width: 600px) {
        margin-top: 0em;
      }

      @media screen and (min-width: 1920px) {
        margin-top: -2.5em;
      }
      .metric_selection {
        position: relative;
        cursor: pointer;

        input[type="checkbox"] {
          visibility: hidden;
        }
        .checkmark {
          background-color: #eee;
          border: 0.5em solid #eee;
        }
        input[type="checkbox"],
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          width: $checkbox_width;
          height: $checkbox_height;
          border-radius: 2px;
          @media screen and (min-width: 1920px) {
            height: 2em;
            width: 2em;
          }
        }

        .description {
          line-height: $checkbox_height;
          margin: 0 1em 0 1.5em;
          font-size: var(--smaller_regular_font_size);
        }

        input[type="checkbox"]:checked ~ .checkmark {
          @include themify($themes) {
            background: themed("accent_color");
            border: 0.5em solid themed("accent_color");
          }
        }
        @media screen and (min-width: 1920px) {
          input[type="checkbox"],
          .checkmark {
            height: 2em;
            width: 2em;
          }
        }
      }
    }
  }
}
