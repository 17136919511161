@import "../colors";
@import "../themes";

#dashboard_items {
  display: grid;
  grid-template-areas:
    "nav data"
    "back_link back_link"
    "dashboard_options dashboard_options"
    "main_content main_content";

  grid-gap: 0.5rem;

  &.media_mix {
    grid-gap: 0;
  }

  padding: 1em 2em 0;
  width: 100%;

  .back_button {
    margin: 0;
    font-size: 0.425em;
    width: max-content;

    &:hover {
      cursor: pointer;
    }

    @include themify($themes) {
      color: themed("accent_color");
    }

    a {
      @include themify($themes) {
        color: themed("accent_color");
      }
    }
  }
}

.dashboard_banner {
  width: 100%;
  font-size: var(--regular_font_size);
  padding: 0.25em 1em;
  cursor: pointer;

  &.isHidden {
    display: none;
  }

  @include themify($themes) {
    color: themed("bannerFontColor");
    background-color: themed("bannerBackgroundColor");
  }

  .detail {
    font-size: calc(var(--regular_font_size) - 0.25rem);
  }
}

.dashboard_options {
  grid-area: dashboard_options;
  white-space: pre-line;

  @include themify($themes) {
    color: themed("secondary-font-color");
  }

  text-align: left;
  margin: 0;
  font-size: calc(var(--page_title) + 1em);
  font-weight: 600;
  list-style: none;

  .pageTitleText {
    white-space: pre-wrap;
  }

  .active {
    display: flex;
    align-items: baseline;

    &:hover {
      cursor: pointer;
    }

    @include themify($themes) {
      color: themed("primary-font-color");
    }
  }

  &:hover,
  &.active {
    @include themify($themes) {
      color: themed("primary-font-color");
      cursor: pointer;
    }
  }
}

.flagIcon {
  height: 35px;
  width: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  margin-right: 0.5rem;

  &.China {
    background-image: url(../../images/Flags/flag-china.png);
  }

  &.USA {
    background-image: url(../../images/Flags/flag-us-round.png);
  }

  &.Canada {
    background-image: url(../../images/Flags/flag-canada.png);
  }

  &.Brazil {
    background-image: url(../../images/Flags/flag-brazil-round.png);
  }

  &.Japan {
    background-image: url(../../images/Flags/flag-jp-round.png);
  }

  &.UK {
    background-image: url(../../images/Flags/flag-gb-round.png);
  }

  &.Mexico {
    background-image: url(../../images/Flags/flag-mexico-round.png);
  }

  &.Australia {
    background-image: url(../../images/Flags/flag-australia-round.png);
  }

  &.France {
    background-image: url(../../images/Flags/flag-fr-round.png);
  }

  &.Germany {
    background-image: url(../../images/Flags/flag-de-round.png);
  }

  &.Italy {
    background-image: url(../../images/Flags/flag-ita-round.png);
  }

  &.Saudi {
    background-image: url(../../images/Flags/saudi-arabia-flag-round.png);
  }

  &.Spain {
    background-image: url(../../images/Flags/flag-spain-round.png);
  }

  &.Sweden {
    background-image: url(../../images/Flags/flag-swe-round.png);
  }

  &.Thailand {
    background-image: url(../../images/Flags/flag-thailand-round.png);
  }

  &.Philippines {
    background-image: url(../../images/Flags/flag-philippines-round.png);
  }
}

.page_title_description {
  font-size: var(--smaller_regular_font_size);
  padding: 0.4rem;

  @include themify($themes) {
    color: themed("secondary-font-color");
  }
}

.contentWrapper {
  grid-area: main_content;
  margin-bottom: 10em;

  @media screen and (min-width: 1250px) {
    /*margin-bottom: 0;*/
  }
}

.contentWrapperExtend {
  grid-area: main_content;
  display: grid;
  grid-template-rows: 1fr;
  margin-bottom: 10em;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1em;
    margin-bottom: 5em;
    margin-right: 2.9em;
  }
}

.iframe_container {
  position: relative;
  grid-area: body;

  #container {
    height: 100%;
  }
}

.iframeWrapper {
  height: 100%;

  div,
  iframe {
    width: 100%;
    height: 100%;
  }
}

.adminWrapper {
  height: 100%;

  div,
  iframe {
    height: 100%;
  }
}

.mtm_report_container {
  display: flex;
  padding: 5em;
  justify-content: center;
  font-size: var(--regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color");
  }
}

.filter_float {
  position: fixed;
  width: 100vw;
  z-index: 1000;
  left: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
}

#metric_button {
  color: $bright_white;
  font-size: 1rem;
  border: none;
  border-bottom: 1.5px $bright_white solid;
  background: $cyan;
  line-height: 2.5em;

  &.isVisible {
    display: unset;
  }

  .remove_specific_metric {
    position: absolute;
    right: 2em;
  }
}

.metric_pill {
  display: none;
}

.pdf_last_updated {
  grid-area: data;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.ariya {

    .last_updated,
    .data_button {
      @include themify($themes) {
        color: themed("secondary-font-color");
      }
    }

    .refreshDate {
      @include themify($themes) {
        color: themed("primary-font-color");
      }
    }
  }
}

.last_updated {
  font-size: var(--smaller_regular_font_size);
  display: block;
  white-space: nowrap;

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  text-align: right;
}

.data_button {
  color: $cyan;
  cursor: pointer;

  &:hover {
    color: $cyan_bright;
  }
}

.data_dictionary_button {
  background-color: $cyan;
}

.last_refreshed_alert {
  align-items: center;
  width: 1.2rem;
  margin-right: 1rem;
  position: relative;
  display: none;
  cursor: pointer;

  &.show_alert {
    display: flex;
  }
}

.alert_icon {
  width: 100%;
}

.page_content_tooltip {
  visibility: hidden;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 99;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  align-self: flex-start;
  font-size: var(--filter_font);

  @include themify($themes) {
    background-color: themed("primary-font-color");
    color: themed("menu-background") !important;
  }
}

.alert_tooltip {
  padding: 0.5rem;
  width: 12rem;
  top: 110%;
  font-size: var(--smaller_regular_font_size);
  text-align: left;

  &.show_alert_tooltip {
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (min-width: 1024px) {
  #dashboard_items {
    grid-template-areas:
      "dashboard_options data"
      "data_toggle data_toggle"
      "main_content main_content";
  }

  .last_updated,
  .pdf_menu {
    margin: auto 0;
  }

  .pdf_menu {
    display: flex;
    padding-left: 1rem;
  }

  .alert_tooltip {
    width: 15rem;
  }

  .metric_pill {
    display: inline-flex;
    color: $bright_white;
    background-color: $cyan;
    padding: 0 0.5em;
    border-radius: 5px;
    height: min-content;
    margin: auto auto auto 1.5em;

    span {
      font-size: var(--regular_font_size);
      text-transform: none;
      margin: auto;
    }

    .remove_specific_metric {
      margin-left: 0.75em;
      cursor: pointer;
    }
  }

  .dashboard_options {
    margin: auto 0;

    .active {
      font-size: var(--page_title);
      font-weight: 300;
      white-space: nowrap;
    }
  }

  .blue_pdf {
    width: var(--pdf_menu_img);
    height: var(--pdf_menu_img);
    background-size: contain !important;
    background: url("../../images/PageContent/pdf_menu.png") no-repeat;
    cursor: pointer;

    &:hover {
      background-image: url("../../images/PageContent/pdf_menu_hover.png");
    }
  }

  .ariya {
    .blue_pdf {
      background: url("../../images/PageContent/pink_pdf_menu.png") no-repeat;

      &:hover {
        background-image: url("../../images/PageContent/white-pink-pdf.svg");
      }
    }

    @media screen and (min-width: 1024px) {
      padding-right: 2rem;
    }
  }

  .pdf_menu_tooltip {
    right: 3.5rem;
    font-size: var(--filter_font);
    padding: 0.5rem 0;
    white-space: nowrap;

    span {
      @include themify($themes) {
        color: themed("filter_font_color");
      }

      padding: 0.2rem 0.5rem;
      width: 100%;
      display: flex;

      &:hover {
        @include themify($themes) {
          background-color: themed("filter_background_color");
          color: themed("filter_font_color") !important;
        }
      }
    }
  }

  #oce_csv_download_options,
  #voc_csv_download_options,
  #dis_csv_download_options,
  #executive_summary_dis_csv_download_options {
    top: 35%;
    right: 100.5%;
    font-size: var(--filter_font);
    padding: 0.5rem 0;
    white-space: nowrap;

    span {
      @include themify($themes) {
        color: themed("filter_font_color");
      }

      padding: 0.2rem 0.5rem;
      width: 100%;
      display: flex;

      &:hover {
        @include themify($themes) {
          background-color: themed("filter_background_color");
          color: themed("filter_font_color") !important;
        }
      }
    }
  }

  .pdf_menu {
    &:hover {
      .pdf_menu_tooltip {
        visibility: visible;
        opacity: 1;

        #oce_download_csv {
          &:hover {
            #oce_csv_download_options {
              visibility: visible;
              opacity: 1;
            }
          }
        }

        #voc_download_csv {
          &:hover {
            #voc_csv_download_options {
              visibility: visible;
              opacity: 1;
            }
          }
        }

        #dis_download_csv {
          &:hover {
            #dis_csv_download_options {
              visibility: visible;
              opacity: 1;
            }
          }
        }

        #executive_summary_download_csv {
          &:hover {
            #executive_summary_dis_csv_download_options {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.chartFilterContainer {
  width: 50%;
  margin-left: auto;
}

.filter_submit_btn {
  font-size: var(--form_font);
}

.hidden {
  display: none;
}

.cluster_wrapper {
  margin-bottom: 4em;
}

.cluster_text {
  font-size: var(--header_font);
  font-weight: 300;
  margin-bottom: 0.5em;
  cursor: pointer;

  @include themify($themes) {
    color: themed("secondary-font-color");
  }
}

.text-green {
  color: $green;
}

.text-red {
  color: $charts_red;
}

.text-amber {
  color: #f2c043;
}