@import "../colors";
@import "../themes";

.table_legend {
  display: flex;
  flex-flow: row !important;
  justify-content: space-around;
  width: 100%;

  .legend_item {
    flex-flow: row !important;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 30px;
  }
}

#top_used_services_table {
  thead {
    @include themify($themes) {
      border-bottom: 1px solid themed("primary-font-color");
    }
  }

  tr {
    &.odd {
      background-color: transparent !important;
    }
    th,
    td {
      padding: 0.5px 5px !important;

      &:first-child {
        width: 55%;
        text-align: right;

        @media screen and (min-width: 400px) {
          width: 42.5%;
        }

        @media screen and (min-width: 750px) {
          width: 30%;
        }
      }

      &:nth-child(2) {
        width: auto;
        @media screen and (min-width: 750px) {
          width: 20%;
        }
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 15%;

        @media screen and (min-width: 750px) {
          width: 10%;
        }
      }

      .progress-bar {
        display: flex;
        width: 100%;

        span {
          background-color: $cyan;
          height: 7.5px;
          border-radius: 2px;
        }

        &.Off {
          span {
            @include themify($themes) {
              background-color: themed("topUsedServicesOffboard");
            }
          }
        }
      }
    }
  }
}
