@import "../colors";
@import "../effects";
@import "../themes";

.cpo_operational_sotu {
  --min-height: 40vh;
  --margin-bottom: 1em;
  --padding: 1.25em 1.5em 2em;
  margin-bottom: 2em;

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .primary_background {
    @include themify($themes) {
      background: themed("primary-tile-background");
    }
  }

  .tile_title {
    text-align: left;
    font-size: var(--header_font);
    font-weight: 300;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }
  }

  .tile_total {
    @include themify($themes) {
      color: themed("primary-font-color");
    }
  }

  .main_content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .trendline_graphs,
  .inventory_graphs {
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;
    min-height: var(--min-height);
  }

  .trendline_graphs {
    grid-template-columns: 1fr;

    @media screen and (min-width: 700px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .trendline_graph {
      position: relative;
      padding: var(--padding);
      min-height: 45vh;
      padding-bottom: 2em;

      .main_content {
        max-height: 95% !important;
      }

      .no_data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .dealer_tables {
    margin-bottom: 1rem;
    padding: var(--padding);
  }

  .inventory_graphs {
    grid-template-columns: 1fr;

    @media screen and (min-width: 700px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .inventory_graph {
      position: relative;
      padding: var(--padding);
      min-height: 60vh;
      display: flex;
      flex-direction: column;

      .no_data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .more-info {
        display: flex;
        margin-bottom: 1rem;
        justify-content: space-evenly;
        align-items: center;
        font-size: 1.5em;

        .more-info-btn {
          transition: color 0.2s linear;
          background: none;
          color: $bright_white;
          font-size: 0.7rem;
          border: none;
          border-radius: 2px;
          cursor: pointer;
          width: auto;
          background: $cyan;
          bottom: 0;
          padding: 1em 3em;

          &:focus,
          &:active {
            outline: none;
          }
        }
      }
    }
  }

  .info_tooltip {
    float: unset;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
