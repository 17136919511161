@import "themes";

@mixin scroll-bar {
  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.25em;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: $black;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $accent_brown;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $accent_brown;
  }
}

@mixin progress-bar {
  progress[value] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 18px;
    position: relative;
    background-color: transparent;
    border: none;
    color: $accent_brown;

    &:before {
      content: attr(data-label);
      font-size: 0.8em;
      position: absolute;
      text-align: center;
      color: white;
    }

    &::-webkit-progress-bar {
      background-color: transparent;
    }

    &::-webkit-progress-value,
    &::-moz-progress-bar {
      background-color: $accent_brown;
    }
  }
}
