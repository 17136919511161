@import "../colors";
@import "../effects";
@import "../themes";

.channel-mix-performance-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  @include themify($themes) {
    border: 1px solid themed("primary-font-color");
  }

  th {
    background-color: #00b050;
    color: #fff;
    text-align: center;
    font-size: 0.9em;
  }

  tr:first-of-type {
    th:nth-of-type(1),
    th:nth-of-type(2) {
      background-color: transparent;
      @include themify($themes) {
        color: themed("primary-font-color");
      }
    }
    th:nth-of-type(3),
    th:nth-of-type(4),
    th:nth-of-type(5),
    th:nth-of-type(6) {
      background-color: #002060 !important;
      color: #fff;
    }
  }

  tr:nth-of-type(3) {
    th:nth-of-type(odd) {
      background-color: #c4e0b2;
      color: black;
    }

    th:nth-of-type(even) {
      background-color: #fee599;
      color: black;
    }
  }

  tr {
    .lead {
      background-color: #e1efd8;
      color: black;
    }

    .cr {
      background-color: #fff2cc;
      color: black;
    }
  }

  th,
  td {
    @include themify($themes) {
      border-bottom: 1px solid themed("primary-font-color");
      border-right: 1px solid themed("primary-font-color");
    }
    font-size: 0.8em;
  }

  tr:first-of-type {
    th:nth-of-type(1),
    th:nth-of-type(2),
    th:nth-of-type(3),
    th:nth-of-type(4),
    th:nth-of-type(5),
    th:nth-of-type(6) {
      width: 6em;
    }
  }

  tbody {
    tr:first-of-type {
      td:nth-of-type(2) {
        text-align: left;
      }
    }
  }
}
