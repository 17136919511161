@import "../colors";
@import "../effects";
@import "../themes";

.dfp_sotu {
  display: grid;
  grid-gap: 1em;
  grid-template-areas: "media_spend";
  grid-template-rows: max-content;
  padding-bottom: 10em;

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .primary_background {
    @include themify($themes) {
      background: themed("primary-tile-background");
    }
  }

  h3 {
    font-size: var(--header_font);

    font-weight: 300;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }

    text-align: left;
  }

  .period_comparison {
    font-size: var(--header_font);
    width: 80%;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .main_value {
      margin-right: 0.5em;
      font-size: 1.75rem;
      font-weight: 400;
    }

    .comparison_value {
      margin-right: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: var(--regular_font_size);
    }

    .indicator {
      display: block;
      height: 0.75em;
      width: 0.75em;
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
      background-position: 50%;
      margin-right: 0.5em;
    }

    .comparison_value_number {
      margin-right: 0.5em;
    }
  }

  .dfp_tile_header {
    .title {
      display: flex;
      justify-content: unset;
      align-items: center;

      h3 {
        margin-right: 0.2em;
      }
    }
  }

  .kpi-tile {
    padding: 1.25em 1.5em 0;
  }

  .metric_comparison_and_grade_mix {
    display: contents;
  }

  .grade_mix {
    display: flex;
    flex-direction: column;
    min-height: 450px;

    .main_content {
      height: 100%;
    }
  }
}

.media_spend,
.leads_funnel {
  display: grid;
  grid-template-columns: 1fr;

  .dfp_tile_header {
    margin-bottom: 1em;
  }

  .main_content {
    padding: 1.25em 1.5em 0;
  }

  .main_content {
    min-height: 300px;

    .no_data {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  position: relative;
}

@media screen and (min-width: 1250px) {
  .dfp_sotu {
    grid-template-areas: "media_spend_and_showroom" "metric_comparison_and_grade_mix";
    grid-template-rows: max-content max-content;

    .media_spend_and_showroom,
    .metric_comparison_and_grade_mix {
      grid-template-columns: calc(65% - 0.375rem) calc(35% - 0.375rem);
      display: grid;
    }

    .media_spend_and_showroom {
      grid-template-areas: "media_spend_leads show_room_traffic";
    }

    .metric_comparison_and_grade_mix {
      grid-template-areas: "metric_comparisons_module grade_mix_module";

      .metric_comparisons_module {
        display: grid;
        grid-area: metric_comparisons_module;

        .metric_comparisons {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-row-gap: 1em;

          .chartWrapper,
          .graph {
            max-height: calc(200px + 5em) !important;
            min-height: calc(200px + 5em) !important;
            padding-bottom: 0em;
          }

          #visitsByChannel {
            max-height: calc(239px + 5em) !important;
            min-height: calc(239px + 5em) !important;
          }
        }
      }
    }

    .media_spend_leads {
      display: grid;
      grid-area: media_spend_leads;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1em;
      grid-template-areas: "media_spend total_leads";
      grid-template-rows: max-content;
    }

    .media_spend {
      grid-area: media_spend;

      .chartWrapper,
      .graph {
        padding-bottom: 1.15em;
      }
    }

    .show_room {
      grid-area: show_room_traffic;

      .dfp_tile_header {
        padding: 0;
      }
    }

    .leads_funnel {
      grid-area: total_leads;
      margin-right: 1em;

      .chartWrapper,
      .graph {
        margin-bottom: 2em !important;
      }
    }

    .metric_graph,
    .grade_mix,
    .show_room {
      padding: 1.25em 1.5em 0em;
      margin-right: 1em;

      .main_content {
        margin-right: -2.8em;
      }

      .main_content {
        .no_data {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      position: relative;
    }

    .show_room {
      padding: 1.25em 1.5em 2.5em;
    }

    .grade_mix {
      padding: 1.25em 1.5em 3em;
    }

    .chartWrapper,
    .graph {
      max-height: 550px !important;
      padding-bottom: 0em;
      margin-left: auto;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
