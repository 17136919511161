@import "../../../../../components/Layouts/Ariya/styles/colors";
@import "../../../../../components/Layouts/Ariya/styles/scroll_bar";

.card {
  display: flex;
  flex-flow: column;
  border: solid 1px $borderGrey;
  border-radius: 10px;
  padding: 1em;
  padding-bottom: 0.5em;
}

.title {
  font-size: 0.875rem;
  font-weight: normal;
  margin-bottom: 0.5em;
}

.content {
  height: 100%;
  overflow-y: auto;
  padding-right: 1em;
  @include scroll-bar();

  @media screen and (min-width: 1024px) {
    max-height: 325px;
  }
}

.footnote {
  font-size: 0.75rem;
  color: $lightGrey;
  margin: auto 0 0;
}
