@import "../colors";
@import "../effects";
@import "../themes";

.journey-excellence-layout {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  font-size: var(--regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color") !important;
  }

  .title-menu {
    min-height: 80vh;
    display: grid;
    gap: 10px;
    grid-template-rows: 1fr 3fr 2fr 2fr;

    .section-title {
      height: 100%;
      @include themify($themes) {
        background: themed("cssTitleBackground");
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 5px;
      width: 100%;

      @media screen and (min-width: 1280px) {
        margin-bottom: 0;
        width: 8.5em;
        padding: 2.25em 0.5em;

        &:hover,
        &.active {
          background-color: $cyan;
        }
      }

      .title {
        @include themify($themes) {
          color: themed("ccsTitleFontColor") !important;
        }
      }
    }
  }

  .journey-excellence-main-content {
    display: grid;
    grid-template-rows: 1fr 3fr 2fr 2fr;
    gap: 10px;

    .section-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: 10px;

      .section-content-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        height: inherit;
      }
    }

    .section-toggle {
      display: grid;
      justify-content: end;
    }

    .tile {
      border: 1px solid $bright_white;

      @include themify($themes) {
        border: 1px solid themed("primary-font-color");
        background: themed("ccsContentBackground");
      }

      padding: 0.5em;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      flex-flow: column;
      align-items: center;

      .tile-header {
        display: flex;
      }

      .tile-content {
        display: flex;
        flex-flow: column;
        align-items: center;

        .main-value {
          font-size: var(--small_number_metric);
        }
        .sub-text {
          display: flex;
          align-items: center;
          font-size: var(--header_font);
        }
      }
    }
  }

  .journey-excellence-row {
    display: flex;
    flex-flow: row;
  }

  #product_journey {
    @include themify($themes) {
      background: themed("primary-tile-background");
    }
  }

  .value-tile-toggle {
    align-self: flex-start;
  }

  .chart_tile {
    height: 100%;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    position: relative;

    .tile-header {
      display: flex;
    }

    .tile-content {
      height: inherit;
    }
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

  .journey-excellence-fmi {
    display: flex;
    flex-direction: column;

    .section-content {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .section-header {
        display: grid;

        .toggle-justify-end {
          justify-self: end;
        }
      }

      .section-content-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        min-height: 5rem;
      }
    }

    .detailed-tile {
      border: 1px solid $bright_white;
      position: relative;

      @include themify($themes) {
        border: 1px solid themed("primary-font-color");
        background: themed("ccsContentBackground");
      }

      padding: 0.5em;
      border-radius: 5px;
      cursor: pointer;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      .metric-container {
        width: 100%;

        .tile-header {
          display: flex;
        }

        .tile-content {
          display: flex;
          flex-flow: column;

          .main-value {
            font-size: var(--small_number_metric);
          }
        }

        @include themify($themes) {
          border-right: 3px solid themed("primary-font-color");
          padding-right: 5px;
        }
      }

      .sub-text {
        display: flex;
        font-size: var(--header_font);
        align-items: center;
      }
    }

    .fmi-trend-chart {
      position: relative;
      margin-top: 20px;
      min-height: 30rem;
      @include themify($themes) {
        background: themed("primary-tile-background");
      }
    }
  }

  .journey-excellence-online-content {
    display: flex;
    flex-direction: column;

    .metric-comparison-row {
      display: grid;
      grid-template-columns: 3fr 2.5fr;
      gap: 20px;
      min-height: 9rem;

      &:first-of-type {
        margin-bottom: 2rem;
      }

      .metric-comparison-tiles {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        height: 100%;
      }

      .chart-container,
      .tiles-container {
        display: flex;
        flex-direction: column;
      }
    }

    .detailed-tile {
      border: 1px solid $bright_white;
      position: relative;

      @include themify($themes) {
        border: 1px solid themed("primary-font-color");
        background: themed("ccsContentBackground");
      }

      padding: 0.5em;
      border-radius: 5px;
      cursor: pointer;
      gap: 1rem;

      .metric-container {
        width: 100%;

        .tile-header {
          display: flex;
        }

        .tile-content {
          display: flex;
          flex-flow: column;

          .main-value {
            font-size: var(--small_number_metric);
          }
        }
      }

      .sub-text {
        display: flex;
        font-size: var(--header_font);
        align-items: center;
      }
    }

    .metric-comparison-chart {
      position: relative;
      height: 100%;
      @include themify($themes) {
        background: themed("primary-tile-background");
      }
    }

    .outer-tile-header {
      display: flex;
      justify-content: space-between;
    }

    .journey-conversion-toggle {
      display: grid;
      justify-content: end;
      margin-bottom: 1rem;
    }

    .journey-conversion-table-tile {
      .title {
        opacity: 0;
      }
      display: flex;
      flex-direction: column;
      .journey-conversion-table {
        flex: 1;
        position: relative;
        @include themify($themes) {
          background: themed("primary-tile-background");
        }
        #journeyConversionTable {
          th.disabled {
            background-color: transparent;
          }
        }
      }
    }

    .journey-conversion-table {
      display: flex;
      position: relative;
      @include themify($themes) {
        background: themed("primary-tile-background");
      }
      #journeyConversionTable {
        th.disabled {
          background-color: transparent;
        }
      }
    }

    .website-visit-source-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      min-height: 25rem;
      gap: 20px;

      .website-visit-source-tile {
        @include themify($themes) {
          background: themed("primary-tile-background");
        }
        position: relative;
        padding: 0.5em;
        display: flex;
        flex-direction: column;

        .tile-header {
          display: flex;
          justify-content: space-between;

          .title {
            display: flex;
            align-items: center;
          }
        }

        .tile-content {
          flex: 1;
        }
      }
    }

    .customer-experience-row {
      display: grid;
      grid-template-columns: 0.5fr 1fr 1fr;
      min-height: 8rem;
      gap: 10px;

      .customer-experience-dxs,
      .website-star-rating,
      .exit-survey {
        display: flex;
        flex-direction: column;
        .title {
          display: flex;
        }
      }

      .website-star-rating-row,
      .exit-survey-row {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }
    }

    .customer-experience-tile {
      @include themify($themes) {
        background: themed("primary-tile-background");
      }
      padding: 1rem;
      flex: 1;
      position: relative;

      .tile-header {
        display: flex;
      }

      .main-value {
        font-size: 2.5rem;
        margin-bottom: 1rem;
      }

      .comparison {
        display: flex;
        align-items: center;
      }
    }
  }

  .journey-conversion-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1.665fr;
    min-height: 20rem;
    gap: 10px;

    .customer-journey {
      position: relative;
      display: flex;
      flex-direction: column;

      .tile-header {
        display: flex;
      }

      .tile-content {
        height: 100%;
        @include themify($themes) {
          background: themed("primary-tile-background");
        }
      }
    }

    .conversion-trend {
      position: relative;
      display: flex;
      flex-direction: column;
      .main-content {
        @include themify($themes) {
          background: themed("primary-tile-background");
        }
        display: flex;
        height: 100%;
      }
    }
  }

  .journey-excellence-leads-management-system {
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      min-height: 15rem;
      gap: 20px;
    }

    .tile {
      @include themify($themes) {
        background: themed("primary-tile-background");
      }
      border: none;
      border-radius: 0;

      .tile-header {
        display: flex;
      }

      .tile-content {
        display: flex;
        height: 100%;
      }
    }

    .key-indicators-container {
      padding: 0.85em 0.5em;
      @include themify($themes) {
        border: themed("thinBorder");
        color: themed("primary-font-color");
      }

      .key-indicator-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      .key-indicator-data {
        margin-top: 1.5rem;
      }

      .duration-values {
        padding: 1rem 0.5rem 0;
      }

      .duration {
        margin-bottom: 1rem;
      }

      .title {
        display: flex;
      }

      .values {
        display: flex;
      }

      .current-values {
        @include themify($themes) {
          border-right: themed("thinBorder");
          color: themed("primary-font-color");
        }
        margin-right: 0.75rem;
      }

      .main-value,
      .comparison-values {
        flex: 1;
      }

      .main-value {
        display: flex;
        flex-direction: column;
      }

      .main-value-number {
        font-size: 1.75rem;
      }

      .main-value-target {
        font-size: var(--menu_size);
      }

      .comparison-values {
        font-size: var(--regular_font_size);

        div {
          display: flex;
          align-items: center;
        }
      }
    }

    .conversion-and-cost-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 30px;
    }

    .section-toggle {
      display: grid;
      justify-content: end;
    }

    .detailed-tile {
      border: 1px solid $bright_white;
      position: relative;

      @include themify($themes) {
        border: 1px solid themed("primary-font-color");
        background: themed("ccsContentBackground");
      }

      padding: 0.5em;
      border-radius: 5px;
      cursor: pointer;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      .metric-container {
        width: 100%;

        .tile-header {
          display: flex;
        }

        .tile-content {
          display: flex;
          flex-flow: column;

          .main-value {
            font-size: var(--small_number_metric);
          }
        }

        @include themify($themes) {
          border-right: 3px solid themed("primary-font-color");
          padding-right: 5px;
        }
      }

      .sub-text {
        display: flex;
        font-size: var(--header_font);
        align-items: center;
      }
    }
  }

  .journey-excellence-product-journey {
    .product-website-performance-overview {
      min-height: 25rem;
      display: flex;
    }

    .tile {
      @include themify($themes) {
        background: themed("primary-tile-background");
      }
      border: none;
      border-radius: 0;

      .tile-header {
        display: flex;
        justify-content: space-between;
      }

      .tile-content {
        display: grid;
        flex: 1;
        height: 100%;
      }
    }

    .donut-charts-row {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(3, 1fr);

      .tile-15 {
        min-height: 15rem;
      }
    }
  }

  .split-value-tile {
    display: flex !important;
    flex-direction: row !important;

    .split {
      flex: 1;
    }

    .split:nth-of-type(even) {
      @include themify($themes) {
        border-left: 3px solid themed("primary-font-color");
      }
    }

    .tile-header {
      justify-content: center;
    }
  }

  .more-info {
    display: flex;
    margin: 2rem;
    align-items: center;

    .more-info-btn {
      transition: color 0.2s linear;
      background: none;
      color: $bright_white;
      font-size: 0.8rem;
      border: none;
      border-radius: 2px;
      cursor: pointer;
      width: auto;
      background: $cyan;
      bottom: 0;
      padding: 1em 3em;
      margin-left: 4rem;
      &:focus,
      &:active {
        outline: none;
      }
    }
  }

  .cluster_wrapper {
    margin-bottom: 2rem;
  }
}
