@import "../colors";
@import "../effects";
@import "../themes";

.dis-by-market-current-table {
  border-collapse: collapse;
  table-layout: fixed;

  tr {
    display: block;
    float: left;
  }

  tr {
    td:first-of-type {
      border-left: 0 !important;
    }
  }

  th,
  td {
    display: block;
    height: 5em;
    font-size: 0.9em;
    text-align: center !important;
  }

  .visits {
    height: 3em;
    background: #002060;
    color: #fff;
    font-weight: 900;
    text-align: left;
  }

  .visits-to-lead {
    height: 3em;
    text-align: right;

    @include themify($themes) {
      border-bottom: 5px solid themed("primary-font-color") !important;
    }
  }

  .tracked-leads {
    height: 3em;
    background: #2f5496;
    color: #fff;
    font-weight: 900;
    text-align: left;
  }

  .oem-leads {
    height: 3.5em;
    text-align: right;

    @include themify($themes) {
      border-bottom: 5px solid themed("primary-font-color") !important;
    }
  }

  .non-oem-leads {
    height: 2.5em;
    background: #aeabab;
    color: #fff;
  }

  .non-oem-leads-to-dis {
    height: 2.5em;
  }

  .total-digital-leads {
    height: 2.5em;
    background: #2f5496;
    color: #fff;
    font-weight: 900;
    text-align: left;
  }

  .total-dis {
    background: #00b0f0;
    color: #fff;
    font-weight: 900;
    text-align: left;
    height: 2.5em;
  }

  .private-sales {
    height: 2em;
    background: #aeabab;
    color: #fff;
    font-weight: 900;
    text-align: left;
  }

  .dis {
    border: 0 !important;
  }

  .visits,
  .visits-to-lead,
  .tracked-leads,
  .oem-leads,
  .non-oem-leads,
  .non-oem-leads-to-dis,
  .total-dis,
  .private-sales,
  .dis {
    font-size: 1em;
  }

  .market-flag-cell {
    width: 172%;
    height: 3.5em;

    .market-flag {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
  }

  .performance-header {
    height: 2em;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  tr:first-of-type {
    td {
      text-align: start !important;
      font-size: 0.8em;
    }

    td:nth-of-type(1) {
      background-color: transparent;
      height: 4.95em;
      font-size: 1em;

      @include themify($themes) {
        border-bottom: 1px solid themed("primary-font-color");
      }

      @include themify($themes) {
        color: themed("primary-font-color");
      }
    }

    td:nth-of-type(2) {
      border-top: 0 !important;
    }

    .visits {
      height: 3.75em;
    }

    .visits-to-lead {
      height: 3.74em;
    }

    .tracked-leads {
      height: 3.74em;
    }

    .oem-leads {
      height: 4.39em;
    }

    .non-oem-leads {
      height: 3.14em;
    }

    .non-oem-leads-to-dis {
      height: 3.11em;
    }

    .total-digital-leads {
      height: 2.83em;
    }

    .total-dis {
      height: 3.1em;
    }

    .private-sales {
      height: 2.53em;
    }
  }

  tr:nth-of-type(1) {
    width: 14.5%;

    td {
      @include themify($themes) {
        border-left: 1px solid themed("primary-font-color");
      }
    }

    td:nth-of-type(1),
    td:nth-of-type(2) {
      @include themify($themes) {
        border-right: 1px solid themed("primary-font-color") !important;
      }
    }
  }

  .performance-row {
    width: 6.18%;
  }

  .yoy-row {
    width: 4.5%;

    td {
      text-align: right !important;
    }
  }

  tr:not(:first-child) {
    td:first-of-type {
      @include themify($themes) {
        border-top: 1px solid themed("primary-font-color") !important;
      }
    }
  }

  tr:nth-child(even) {
    td:last-of-type {
      width: 160%;
    }
  }

  .dis-data {
    display: flex;
    flex-direction: column;
  }

  .bold {
    font-weight: 900;
  }

  .textGreen {
    color: #558336;
  }

  .textRed {
    color: #d03335;
  }

  .textGray {
    color: #929292;
  }

  .bg-green {
    background-color: rgb(198, 239, 206);
  }

  .bg-red {
    background-color: rgb(255, 199, 206);
  }

  .bg-gray {
    background-color: rgb(217, 217, 217);
  }

  .sub-text {
    font-size: 0.8em;
  }

  .flagIcon {
    height: 1.2rem;
    width: 1.2rem;
  }
}
