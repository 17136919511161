$accent_brown: #95685b;
$lowlight_grey: rgba(255, 255, 255, 0.6);
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$bright_white: #fff;
$black_transparent: rgba(0, 0, 0, 0.25);
$dark_blue: rgb(13, 16, 26);
$cyan: #00a0dd;
$cyan_bright: lighten($cyan, 25%);
$cyan_light: lighten($cyan, 25%);
//Light Theme
$lightBackgroundGrey: #e7ecf0;
$lightKeyIndicatorColor: #2c394e;
// Theme colors
$lightThemeAccentColor: #00a0dd;
$lightKeyIndicatorColor: #2c394e;
$lightBackgroundGrey: #e7ecf0;
$black_thin_border: 1px solid $black_transparent;

$grey: #626262;

$borderGrey: #666;
$lightGrey: rgba($white, 0.75);
