@import "../colors";
@import "../effects";
@import "../themes";

.series_item {
  display: flex;
  align-items: center;
}

.parent_div {
  width: 20px;
  height: 10px;
  margin-right: 1em;
  position: relative;

  .line {
    width: 15px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .square {
    width: 14px;
    height: 14px;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.label_name {
  font-size: 13px;
  margin-right: 1em;
}
