@import "../colors";
@import "../effects";
@import "../themes";

.product-performance-page {
  .grid-6 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 2em;

    $start: 1;
    $end: 6;

    @for $i from $start through $end {
      .grid-col-span-#{$i} {
        grid-column: span #{$i};
      }
    }
  }

  .grid-5 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 2em;

    $start: 1;
    $end: 5;

    @for $i from $start through $end {
      .grid-col-span-#{$i} {
        grid-column: span #{$i};
      }
    }
  }

  .h-15 {
    height: 15rem;
  }

  .tile-header {
    font-size: var(--header_font);
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .justify-content-left {
    justify-content: left;
  }

  .value {
    font-size: var(--conversion_rate);
    margin: auto;
  }

  .text-center {
    text-align: center;
  }

  .pp-tile {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
      color: themed("primary-font-color");
    }
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 1.5rem;
    height: inherit;

    .indicator {
      font-size: var(--header_font);
    }

    .value {
      font-size: var(--small_number_oce);
    }

    .comparison {
      font-size: var(--regular_font_size);
      display: flex;
      align-items: center;
      margin: 0.3rem auto;
    }

    .bugs-value {
      font-size: var(--small_number_oce);
      margin: auto 0;
      line-height: 0.5;
      span {
        font-size: var(--regular_font_size);
      }
    }

    .bugs-comparison {
      margin: 0.3rem 0;
    }

    .flex-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .full-height {
    height: 100%;
  }

  .full-width {
    width: 100%;
  }

  .mt-1 {
    margin-top: 1rem;
  }

  #digitalPerformanceTable,
  #influenceOnShoppingToolsTable,
  #influenceLeadsTable {
    th.disabled {
      background-color: transparent;
    }
    th:first-of-type {
      width: 44%;
    }
    th:not(first-of-type) {
      width: 28%;
    }
  }

  .bug-release-container {
    padding: 0.5rem 0;
    font-size: 1.25em;
  }

  .bug-release-items {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .flex-v-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .border-bottom-dotted {
    border-bottom: 1px dashed;
    @include themify($themes) {
      border-color: themed("secondary-font-color");
    }
  }

  .page-overall-performance {
    display: flex;
    height: 45%;
    font-size: var(--regular_font_size);

    .device-container {
      padding: 0rem 3rem;
      margin: auto 0;
      text-align: center;
    }

    .avg-value {
      font-size: var(--small_number_oce);
    }

    .comparison-container {
      display: flex;
      flex-direction: column;
      padding: 0rem 3rem;
      text-align: center;
      margin: auto 0;
    }
  }

  .half-height {
    height: 50%;
  }

  .h-20 {
    height: 20rem;
  }
}
