@import "../colors";
@import "../themes";

.indicator_funnel {
  display: grid;
  grid-gap: 1.1em;
  grid-template-columns: 1fr;
}

.content {
  display: grid;
  @include themify($themes) {
    border: themed("thinBorder");
  }
  padding: 0.85em 0.5em;
  grid-template-rows: auto auto auto;
  grid-template-areas: "header" "main" "comparison";
  position: relative;

  &.volume {
    &.active,
    &:hover {
      @include themify($themes) {
        background-color: themed("keyIndicator_accentColor");
      }

      h4,
      .main,
      .comparison_values p {
        color: $bright_white !important;
      }
    }
  }

  h4 {
    @include themify($themes) {
      color: themed("primary-font-color");
    }
    font-weight: 300;
    font-size: var(--regular_font_size);
    margin: auto 0;
    grid-area: header;
    text-align: left;
  }

  .main {
    font-size: 1.75rem;
    margin: 0.25em 0;
    align-self: center;
    grid-area: main;
  }

  .comparison {
    display: flex;
    flex-flow: row;
    margin: 0.5em 0;
    text-align: left;
    justify-content: space-between;
    grid-area: comparison;

    p {
      // width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      font-size: var(--regular_font_size);
    }
  }

  .comparison_values {
    display: flex;
    flex-flow: row;
    margin: 0.5em 0;
    text-align: left;
    justify-content: end;
    grid-area: comparison;

    p {
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      font-size: var(--regular_font_size);
    }
  }
}

span {
  &.comparison_value {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1250px) {
  #volumes {
    grid-template-columns: 1fr 1fr 1fr;

    .content {
      cursor: pointer;
    }
  }

  #conversions {
    grid-template-columns: 1fr 1fr;
  }

  .indicator_funnel {
    height: 100%;
  }

  .main_content {
    height: 100%;
  }

  .content {
    .comparison_values {
      flex-flow: column;
    }
  }
}
