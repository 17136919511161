@import "../colors";
@import "../effects";
@import "../themes";

.overview-page {
  display: grid;
  grid-template-columns: 7% 91%;
  gap: 20px;
  font-size: var(--regular_font_size);
  min-height: 83vh;

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .tile {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
      color: themed("primary-font-color");
    }

    border: none;
    border-radius: 0;
    cursor: auto;

    .tile-content {
      height: 100%;
    }

    .title-container,
    .sub-text-container {
      display: flex;
      align-items: center;
    }

    .title-subtext {
      font-size: 0.5rem;
    }

    .sub-text-container {
      margin-bottom: 5px;
    }

    .variance {
      display: flex;
      align-items: end;
      font-size: var(--header_font);

      .pop-variance {
        margin: 0 auto;
      }

      .yoy-variance,
      .pop-variance {
        display: flex;
        align-items: center;
      }
    }
  }

  .okr {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-header {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;
    margin-bottom: 15px;

    .section-header-title {
      display: flex;
    }
  }

  .service-section {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    gap: 15px;

    .online-account,
    .service-leads,
    .self-service {
      display: flex;
      flex-direction: column;
    }

    .online-account-tiles,
    .self-service-tiles {
      display: grid;
      height: 100%;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }

    .service-leads-tiles {
      display: grid;
      height: 100%;
    }

    .online-account-tiles,
    .self-service-tiles,
    .service-leads-tiles,
    .ccs-engagement-metric-tiles {
      .tile {
        padding-bottom: 10px;
      }
    }

    .values {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .value {
      margin-top: auto;
      font-size: var(--small_number);
    }
  }

  .ccs-section {
    margin-top: 20px;

    .ccs-engagement-section {
      display: grid;
      grid-template-columns: 2fr 3fr;
      gap: 15px;

      .ccs-engagement-metric-tiles {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;

        .tile {
          padding-bottom: 10px;

          .value {
            padding: 1rem 0 0;
            font-size: var(--small_number);
          }
        }
      }

      .ccs-engagement-chart {
        .title {
          display: flex;
          justify-content: center;
        }

        .chart {
          display: flex;
          height: 100%;
        }
      }
    }

    .ccs-renewal-section {
      margin-top: 15px;
      display: grid;
      grid-template-columns: 2fr 3fr;
      gap: 15px;

      .ccs-renewal-metric-tiles {
        max-height: 2rem;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;

        .tile {
          padding-bottom: 10px;

          .value {
            padding: 1rem 0 0;
            font-size: var(--small_number);
          }
        }
      }

      .ccs-renewal-chart {
        min-height: 15rem;

        .title {
          display: flex;
          justify-content: center;
        }

        .chart {
          display: flex;
          height: 100%;
        }
      }
    }
  }

  .more-info {
    display: flex;
    margin: 2rem;
    align-items: center;

    .more-info-btn {
      transition: color 0.2s linear;
      background: none;
      color: $bright_white;
      font-size: 0.8rem;
      border: none;
      border-radius: 2px;
      cursor: pointer;
      width: auto;
      background: $cyan;
      bottom: 0;
      padding: 1em 3em;
      margin-left: 4rem;

      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}

.online-account-engagement-engagement-page {
  font-size: var(--regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .tile {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
    }

    border: none;
    border-radius: 0;

    .tile-header {
      display: flex;
      align-items: center;
    }

    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        display: flex;
        flex-direction: column;

        .title-subtext {
          font-size: 0.5rem;
        }
      }
    }

    .variance {
      margin-top: auto;
      display: flex;

      .pop-variance {
        margin: auto;
      }

      .yoy-variance,
      .pop-variance {
        display: flex;
        align-items: center;
      }
    }
  }

  .row {
    display: grid;
    grid-template-columns: 0.7fr 2fr 3fr;
    gap: 10px;
    margin-bottom: 15px;

    .section-title {
      display: grid;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }

    .metric-tile-chart-container {
      display: grid;
      grid-column: span 2;
      gap: 10px;

      .metric-tile-chart-row {
        display: grid;
        grid-template-columns: 1.4fr 2.1fr;
        gap: 10px;
      }
    }

    .metric-tiles {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .chart-tile {
      min-height: 20rem;
    }
  }

  .private-sales-tile {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .metrics {
      grid-template-columns: none;

      div:nth-child(odd) {
        border-right: 0px;
      }
    }
  }

  .metric-tile {
    display: flex;
    flex-direction: row;

    .icon {
      display: flex;
      align-items: center;
    }

    .tile-content {
      padding-left: 1rem;
      width: 100%;

      .value {
        padding: 1rem 0;
        font-size: var(--small_number);
      }
    }
  }

  .metrics {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 25px;
    margin-top: 1rem;
  }

  .metrics>div:nth-child(odd) {
    border-right: 0.2rem solid;
    padding-right: 10px;
  }

  .MuiSvgIcon-fontSizeLarge {
    font-size: 60px;
  }
}

.service-lead-page {
  font-size: var(--regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .tile {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
    }

    border: none;
    border-radius: 0;

    .tile-header {
      display: flex;
      align-items: center;
    }

    .title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .value {
      font-size: var(--small_number);
    }

    .variance {
      margin-top: auto;
      display: flex;

      .pop-variance {
        margin: auto;
      }

      .yoy-variance,
      .pop-variance {
        display: flex;
        align-items: center;
      }
    }
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 2.5fr 2fr 2fr;
    gap: 10px;
    margin-bottom: 15px;

    .section-title {
      display: grid;
      grid-auto-rows: 1.5fr;
      gap: 10px;
      margin-right: 10px;
    }

    .section-title>div {
      display: flex;
      height: 100%;
      justify-content: center;
      text-align: center;
      align-items: center;
      width: 100%;
    }

    .metric-tiles {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .service-chart-tile {
      height: 100%;

      .chart {
        display: flex;
        height: 100%;
      }
    }

    .metric-chart-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .metrics-container {
      display: grid;
      gap: 10px;
    }
  }

  .service-booking-content {
    grid-column: span 3;

    .service-booking-content-row {
      display: grid;
      grid-column: span 3;
      grid-template-columns: 1.23fr 2fr;
      gap: 10px;

      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-top: 10px;
      }
    }

    .service-leads {
      margin-top: 10px;
      grid-template-columns: 1fr 1fr;
    }
  }

  .toggles-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;

    .radio {
      justify-content: unset;
      gap: 20px;
    }

    .radio div {
      width: unset;
      justify-content: unset;
    }
  }

  .service-booking {
    .chart {
      min-height: 400px;
      height: 100%;
      display: flex;
    }
  }

  .service-booking-vs-leads {
    .chart {
      min-height: 300px;
      height: 100%;
      display: flex;
    }
  }

  .legend-container {
    display: flex;

    .legend-line-container {
      font-size: var(--regular_font_size);
      position: relative;
      width: 50px;
      height: 25px;
      margin: 0px 5px;

      .circle {
        position: absolute;
        top: 65%;
        left: 52%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 10px;
        background-color: skyblue;
        border-radius: 100%;
        z-index: 1;
      }

      .line {
        position: absolute;
        top: 60%;
        width: 100%;
        height: 3px;
        background-color: skyblue;
      }
    }

    .legend-text {
      font-size: var(--form_font);
    }
  }

  .metric-and-chart {
    display: flex;
    gap: 10px;
    height: 100%;

    .chart-metric {
      display: grid;
      width: 20%;
      font-size: var(--small_number_metric);
    }

    .chart {
      display: flex;
      height: 100%;
    }
  }

  .gap-10 {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .service-leads-tile {
    grid-column: span 3;

    table {
      border-collapse: collapse;

      th {
        background: #2f5496;
      }

      th:first-of-type {
        background: unset;
      }

      th,
      td {
        border: 1px solid;
        text-align: center;
      }

      th:first-of-type,
      td:first-of-type {
        border: none;
      }

      .flagIcon {
        margin: auto;
      }

      tfoot {
        td {
          padding-top: 0.5rem !important;
          border: none;
          vertical-align: baseline;

          div {
            font-family: "nissan_bold", "Open Sans", sans-serif;
          }
        }
      }
    }

    .flagIcon {
      height: 20px;
      width: 20px;
    }
  }

  .metrics {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 25px;
    margin-top: 1rem;
  }

  .metrics>div:nth-child(odd) {
    border-right: 0.2rem solid;
    padding-right: 10px;
  }

  .MuiSvgIcon-fontSizeLarge {
    font-size: 60px;
  }
}

.self-service-page {
  font-size: var(--regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .tile {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
    }

    border: none;
    border-radius: 0;

    .title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        display: flex;
      }
    }

    .value {
      padding: 1rem 0;
      font-size: var(--small_number);
    }

    .variance {
      margin-top: auto;
      display: flex;

      .pop-variance {
        margin: auto;
      }

      .yoy-variance,
      .pop-variance {
        display: flex;
        align-items: center;
      }
    }
  }

  .chart {
    display: flex;
    height: 100%;
  }

  .row {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 15px;
  }

  .metric-chart-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .self-learning {
    min-height: 400px;
  }

  .top-categories {
    grid-column: span 2;

    .chipContainer {
      display: flex;

      .chip {
        @include themify($themes) {
          color: themed("primary-font-color");
          font-size: var(--tooltip_font);
        }

        padding: 0.3em 1.8em;
        font-weight: 900;
        border-radius: 0.5em;
        margin: 1em;

        @media screen and (max-width: 767px) {
          padding: 0.3em 1em;
          margin: 1em 0.7em;
        }
      }
    }
  }

  .span-3 {
    grid-column: span 3;
  }

  .top-category-tile {
    display: flex;
    flex-direction: row;
    min-height: 20rem;

    .market-answers {
      display: flex;
      flex-direction: column;
      font-size: var(--header_font);
      justify-content: center;
      text-align: center;
      width: 10%;
    }

    .categoryContainer {
      width: 90%;
    }
  }

  .verbatim-tile {
    grid-column: span 3;
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll-bar;

    @media screen and (max-width: 767px) {
      overflow-x: auto;
    }

    .filter {
      border-right: none;
    }

    max-height: 30rem;

    .table-container {
      overflow-y: auto;
      overflow-x: hidden;
      @include scroll-bar;

      @media screen and (max-width: 767px) {
        overflow-x: auto;
      }
    }
  }

  .verbatimTable {
    table-layout: fixed;

    .Market {
      width: 80px;
    }

    .Date {
      width: 80px;
    }

    .Topic {
      width: 150px;
      word-wrap: break-word;

      .link {
        color: $cyan;
      }
    }

    .Touchpoints {
      width: 150px;
    }

    .Subcategory {
      width: 150px;
    }

    .Device {
      width: 150px;
    }

    .URL {
      width: 250px;
      word-wrap: break-word;

      .link {
        color: $cyan;
      }
    }

    .Comment {
      word-wrap: break-word;

      @media screen and (max-width: 767px) {
        word-wrap: normal;
      }
    }
  }

  .space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .flex-row {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .popular-search-row {
    grid-template-columns: 1fr 1fr;
  }

  .table-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .call-center-volumes-row {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
    }

    width: 100%;
    height: 400px;
    padding: 0.5em;
    display: flex;
    flex-direction: column;

    .title-container {
      display: flex;
    }

    .charts {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      height: 100%;
    }
  }

  .metrics {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 25px;
    margin-top: 1rem;
  }

  .right-border {
    border-right: 0.2rem solid;
    padding-right: 10px;
  }
}