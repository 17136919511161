@import "../colors";
@import "../effects";
@import "../themes";

.brand_performance_sotu {
  display: grid;
  grid-gap: 2em;
  grid-template-areas: "navigation";
  grid-template-rows: max-content;

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .primary_background {
    @include themify($themes) {
      background: themed("primary-tile-background");
    }
  }
  max-width: calc(100vw - 4em);

  h3 {
    font-size: var(--header_font);
    font-weight: 300;
    margin-bottom: 0.2em;
    text-align: left;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }
  }

  .title_container {
    display: flex;
    flex-flow: row;
    align-items: center;
    h3 {
      margin-right: 1em;
    }
  }
  .perf_nav_buttons {
    padding: 1em;
    padding-top: 2em;
    .nav_button {
      margin-bottom: 1.5em;
      .filter_btn_element {
        width: 100% !important;
      }
    }
  }
  .competitor_radios {
    display: flex;
    flex-flow: row;
    text-align: right;
    .trend {
      margin-left: 1em;
    }
  }

  .chart_content,
  .chart_wrapper,
  .graph {
    min-height: 150px !important;
    position: relative;
  }
  .no_data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media screen and (min-width: 1250px) {
    .perf_dashboard {
      display: grid;
      grid-gap: 2em;
      grid-template-columns: 1fr 8fr;
    }
    .competitor_radios {
      margin-right: 0em;
    }

    .performance_page {
      .data_area {
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-gap: 2em;
        .chart_content,
        .chart_wrapper,
        .graph {
          min-height: 400px !important;
          height: 95% !important;
        }
      }

      .brand_perf_table {
        overflow-y: auto;
        @include scroll-bar;
        max-height: 65vh !important;
      }
    }
  }
}
