@import "../colors";
@import "../effects";
@import "../themes";

.executive-summary-product-performance-deep-dive {
  font-size: var(--regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color") !important;
  }

  .tile {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
    }

    height: 100%;
    border: none;
    border-radius: 0;
    display: flex;

    .tile-header {
      display: flex;
      font-size: var(--header_font);
      margin-bottom: 1rem;
      justify-content: space-between;
      align-items: center;

      .title-container {
        display: flex;
        height: 100%;

        .title {
          display: flex;
          flex-direction: column;
        }

        .sub-text {
          font-size: var(--smaller_regular_font_size);
        }
      }

      .filters-container {
        display: flex;
      }
    }

    .tile-content {
      height: 100%;
      display: flex;
    }
  }

  .metric-tile {
    .tile-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .value {
      display: flex;
      align-items: center;
      font-size: var(--small_number);
    }

    .comparison {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .yoy-comparison,
      .pop-comparison {
        display: flex;
        align-items: center;
      }
    }
  }

  .customer-experience-metric-tile {
    .tile-header {
      justify-content: center;
    }

    .tile-content {
      display: grid;

      .value {
        display: flex;
        align-items: end;
        justify-content: center;
        font-size: var(--small_number);
      }

      .comparison {
        display: flex;
        flex-direction: column;
        justify-content: end;

        .yoy-comparison,
        .pop-comparison {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        margin-bottom: 2rem;
      }
    }
  }

  .contribution-to-market-numbers-tile {
    grid-column: span 4;
  }

  .overall-product-performance-metric-tile {
    .tile-header {
      justify-content: start;
    }

    .tile-content {
      display: grid;

      .value {
        display: flex;
        align-items: end;
        justify-content: center;
        font-size: var(--small_number);
      }

      .comparison {
        display: flex;
        flex-direction: column;
        justify-content: end;

        .yoy-comparison,
        .pop-comparison {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        margin-bottom: 2rem;
      }
    }
  }

  .page-overall-performance-tile {
    grid-column: span 3;

    .tile-content {
      display: initial;
    }

    .page-overall-performance {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      font-size: var(--regular_font_size);

      .device-container {
        padding: 0rem 3rem;
        margin: auto 0;
        text-align: center;
      }

      .avg-value {
        font-size: var(--small_number_oce);
      }

      .comparison-container {
        display: flex;
        flex-direction: column;
        padding: 0rem 3rem;
        text-align: center;
        margin: auto 0;

        .comparison {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .chart-container {
        grid-column: span 2;
      }
    }
  }

  .bugs-tile {
    grid-column: span 3;

    .total-bugs {
      display: flex;
      flex-direction: column;
      height: 100%;

      .value {
        font-size: var(--small_number_oce);
        margin: auto 0;
        line-height: 0.5;

        span {
          font-size: var(--regular_font_size);
        }
      }

      .comparison {
        display: flex;
        align-items: center;
      }
    }

    .tile-content {
      display: flex;
      justify-content: space-between;
    }

    .bug-release {
      margin-top: auto;
      margin-bottom: auto;

      .bug-release-container {
        padding: 0.5rem 0;
        font-size: 0.65em;

        .release-items {
          display: flex;
          width: 100%;
          justify-content: space-between;
          gap: 3rem;
        }
      }
    }
  }

  .border-bottom-dotted {
    border-bottom: 1px dashed;

    @include themify($themes) {
      border-color: themed("secondary-font-color");
    }
  }

  .text-center {
    text-align: center;
  }

  .h-25 {
    min-height: 25rem;
  }

  .h-20 {
    min-height: 20rem;
  }

  .h-15 {
    min-height: 15rem;
  }

  .row-1 {
    display: grid;
    min-height: 40rem;
    margin-bottom: 10px;
  }

  .row-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 10px;
    min-height: 30rem;
  }

  .row-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 10px;
  }

  .row-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-bottom: 10px;
    min-height: 25rem;
  }

  .row-5 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    margin-bottom: 10px;
  }

  .col-2 {
    display: grid;
    gap: 10px;
  }

  .website-performance-tile {
    &:hover {
      z-index: 100;
    }
  }
}
