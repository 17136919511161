@import "../colors";
@import "../effects";
@import "../themes";

.pills_container {
  width: fit-content;

  .pill_header {
    margin-bottom: 0.5em;
  }

  .pill_selections {
    display: flex;
    margin-bottom: 0.5em;
  }

  .pill {
    @include themify($themes) {
      background-color: themed("pillBackgroundColor");
      color: themed("primary-font-color") !important;
    }
    padding: 0 1.75em !important;
    margin-right: 0.5em;
    border-radius: 10px !important;

    cursor: pointer;
    @include no-select();

    &:last-of-type {
      margin-right: 0;
    }

    &.active {
      @include themify($themes) {
        background-color: themed("keyIndicator_accentColor");
        color: themed("activePill_fontColor") !important;
      }
    }
    &:hover {
      background-color: rgb(0, 160, 221);
    }
  }
}
