@import "../../../../../components/Layouts/Ariya/styles/colors";

.card {
  border: solid 1px $borderGrey;
  border-radius: 10px;
  padding: 2.5em 1.5em;
  margin-bottom: 35px !important;
  height: 100%;
  cursor: pointer;

  display: flex;
  flex-flow: column;
}

.cardTitle {
  margin-bottom: 0.5rem;
}

.titleContainer {
  display: flex;
  flex-flow: row;
  gap: 1.5rem;
}

.titleContent {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.headerContent {
  display: flex;
  gap: 10px;
  align-content: center;
}

.title {
  font-size: 0.875rem;
  margin: 0.75em 0;
}

.chartLegend {
  display: inline-block;
  width: 2rem;
  border-bottom: 3px solid $accent_brown;
  margin: auto;
  margin-left: 0;
}

.dotted {
  composes: chartLegend;
  border-bottom-style: dotted;
}

.solid {
  composes: chartLegend;
  border-bottom-style: solid;
}

.mainNumber {
  font-size: 2.25rem;
  font-family: "nissan_bold", "Open Sans", sans-serif;
}

.trend {
  font-size: 0.75rem;
  color: $lightGrey;
}

.regionSplitContainer {
  margin-bottom: 2em;
}

.regionSplitContent {
  text-align: center;
}

.regionSplitHeader {
  font-size: 0.625rem;
  text-align: left;
  margin: 20px 0 10px;
}

.commonRegionStyles {
  font-size: 1.25rem;
  text-align: center;
}

.regionSplitTitle {
  composes: commonRegionStyles;
  color: $accent_brown;
}

.regionSplitValue {
  composes: commonRegionStyles;
}

.moreText {
  color: $accent_brown;
  text-align: center;
  font-size: 1rem;
}

.regionSplitTrend {
  text-align: center;
  font-size: 0.75rem;
}

.cardDescriptionContainer {
  margin: auto 0;
  margin-bottom: 0;
}

.cardDescription {
  color: $lightGrey;
  font-size: 0.625rem;
}

.flagDiv {
  height: 35px;
  width: 35px;
  margin: auto;
  margin-bottom: 1.5em;
  cursor: unset;
}

.marketFlag {
  composes: flagDiv;
  background: url("../../../../../assets/images/Ariya/flags/moreIcon.svg");
  background-size: contain;
}

.GB {
  background-image: url("../../../../../assets/images/Flags/flag-gb-round.png");
}

.DE {
  background-image: url("../../../../../assets/images/Flags/flag-de-round.png");
}

.NO {
  background-image: url("../../../../../assets/images/Flags/flag-no-round.png");
}

.JP {
  background-image: url("../../../../../assets/images/Flags/flag-jp-round.png");
}

.ITA {
  background-image: url("../../../../../assets/images/Flags/flag-ita-round.png");
}

.SWE {
  background-image: url("../../../../../assets/images/Flags/flag-swe-round.png");
}

.BE {
  background-image: url("../../../../../assets/images/Flags/flag-be-round.png");
}

.moreIcon {
  composes: flagDiv;
  background: url("../../../../../assets/images/Ariya/flags/moreIcon.svg");
  background-size: contain;
  cursor: pointer;
}

.btn {
  font-size: 10px !important;
  padding: 13px 5px !important;
  min-width: unset !important;
  margin-top: 1.5em !important;
}

.chart {
  height: 100%;
}

.chartHeight {
  height: 250px;
}
