@import "../colors";
@import "../effects";
@import "../themes";

.dis-by-market-table {
  border-collapse: collapse;
  table-layout: fixed;

  tr {
    display: block;
    float: left;
  }

  tr {
    td:first-of-type {
      border-left: 0 !important;
      height: 3.5em;
    }
  }

  th,
  td {
    display: block;
    height: 5em;
    font-size: 0.9em;
    text-align: center !important;
  }

  .visits {
    height: 3em;
    background: #002060;
    color: #fff;
    font-weight: 900;
    text-align: left;
  }

  .visits-to-lead {
    height: 3em;
    text-align: right;

    @include themify($themes) {
      border-bottom: 5px solid themed("primary-font-color") !important;
    }
  }

  .tracked-leads {
    height: 3em;
    background: #2f5496;
    color: #fff;
    font-weight: 900;
    text-align: left;
  }

  .oem-leads {
    height: 3em;
    text-align: right;

    @include themify($themes) {
      border-bottom: 5px solid themed("primary-font-color") !important;
    }
  }

  .non-oem-leads {
    height: 2.5em;
    background: #aeabab;
    color: #fff;
  }

  .non-oem-leads-to-dis {
    height: 3.11em;
  }

  .total-digital-leads {
    height: 2.5em;
    background: #2f5496;
    color: #fff;
    font-weight: 900;
    text-align: left;
  }

  .total-dis {
    background: #00b0f0;
    color: #fff;
    font-weight: 900;
    text-align: left;
    height: 2.5em;
  }

  .private-sales {
    height: 2em;
    background: #aeabab;
    color: #fff;
    font-weight: 900;
    text-align: left;
  }

  .dis {
    height: 3.5em;
    border: 0 !important;
  }

  .visits,
  .visits-to-lead,
  .tracked-leads,
  .oem-leads,
  .total-dis,
  .private-sales,
  .dis {
    font-size: 1em;
  }

  .performance-header {
    height: 2em;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  tr:first-of-type {
    width: 16%;

    td {
      text-align: start !important;
      font-size: 0.8em;

      @include themify($themes) {
        border-left: 1px solid themed("primary-font-color");
      }
    }

    td:nth-of-type(1) {
      background-color: transparent;
      border-top: 0 !important;
      height: 6.17em;

      @include themify($themes) {
        border-right: 1px solid themed("primary-font-color") !important;
      }

      @include themify($themes) {
        color: themed("primary-font-color");
      }
    }

    .visits {
      height: 3.75em;
    }

    .visits-to-lead {
      height: 3.8em;
    }

    .tracked-leads {
      height: 3.72em;
    }

    .oem-leads {
      height: 3.77em;
    }

    .non-oem-leads {
      height: 2.79em;
    }

    .non-oem-leads-to-dis {
      height: 3.5em;
    }

    .total-digital-leads {
      height: 2.83em;
    }

    .total-dis {
      height: 3.1em;
    }

    .private-sales {
      height: 2.5em;
    }
  }

  tr:not(:first-child) {
    width: 10.5%;

    td:first-of-type {
      @include themify($themes) {
        border-top: 1px solid themed("primary-font-color") !important;
      }
    }
  }

  .flagIcon {
    height: 1.2rem;
    width: 1.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    margin: 0 !important;

    &.China {
      background-image: url(../../images/Flags/flag-china.png);
    }

    &.USA {
      background-image: url(../../images/Flags/flag-us-round.png);
    }

    &.Canada {
      background-image: url(../../images/Flags/flag-canada.png);
    }

    &.Brazil {
      background-image: url(../../images/Flags/flag-brazil-round.png);
    }

    &.Japan {
      background-image: url(../../images/Flags/flag-jp-round.png);
    }

    &.UK {
      background-image: url(../../images/Flags/flag-gb-round.png);
    }

    &.Mexico {
      background-image: url(../../images/Flags/flag-mexico-round.png);
    }

    &.Philippines {
      background-image: url(../../images/Flags/flag-philippines-round.png);
    }
  }

  .bold {
    font-weight: 900;
  }

  .market-flag {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .sub-text {
    font-size: 0.8em;
  }
}
