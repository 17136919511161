@import "../colors";
@import "../effects";
@import "../themes";

.ccs_app_ratings {
  font-size: var(--regular_font_size);
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  gap: 10px;

  @media screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      // "customer_experience customer_experience"

      "app_ratings app_ratings"
      "app_reviews app_reviews"
      "app_performance app_performance";
    grid-gap: 10px;
    height: 100%;
    position: relative;
  }

  @include themify($themes) {
    color: themed("primary-font-color") !important;
  }

  * {
    &.hide_on_desktop {
      display: flex !important;

      @media screen and (min-width: 1280px) {
        display: none !important;
      }
    }

    &.hide_on_mobile {
      display: none;

      @media screen and (min-width: 1280px) {
        display: flex;
      }
    }
  }

  .app_ratings {
    grid-area: app_ratings;
    display: block;

    @media screen and (min-width: 1280px) {
      display: flex;
      flex-direction: row-reverse;
      gap: 2em;
    }
  }

  .average_app_ratings {
    width: 100%;
    margin-bottom: 20px;

    @media screen and (min-width: 1280px) {
      width: 50%;
    }
  }

  .average_app_ratings_table {
    border-collapse: collapse;

    td {
      border-bottom: 1px solid;
      border-right: 1px solid;

      &.disabled {
        pointer-events: none;
        background-color: rgb(211, 211, 211, 0.3);

        @include themify($themes) {
          color: #a4a4a4;
        }
      }
    }

    th {
      width: 10rem;
    }

    th:first-of-type {
      width: 5rem;
    }
  }

  .customer_experience {
    grid-area: customer_experience;
  }

  .app_performance {
    grid-area: app_performance;

    @media screen and (min-width: 1280px) {
      display: flex;
      flex-direction: row;
      gap: 2em;

      .chart_tile_content {
        height: 55vh;
      }
    }
  }

  .app_review_charts {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5em;
    padding-bottom: 20px;
    height: 100vh;

    @media screen and (min-width: 1280px) {
      width: 50%;
      height: 100% !important;
    }
  }

  .app_review_comments {
    width: 100%;

    @media screen and (min-width: 1280px) {
      width: 50%;
    }
  }

  .section_header_chip {
    display: flex;

    @include themify($themes) {
      background: themed("primary-tile-background") !important;
      color: themed("primary-font-color");
    }

    justify-content: center;
    padding: 0.8em 0;
    font-size: var(--header_font);
    margin-bottom: 0.8em;
    border-radius: 0.5em;
  }

  .app_reviews {
    grid-area: app_reviews;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1280px) {
      flex-direction: row;
      height: 100vh !important;
    }

    gap: 20px;
  }

  .reviews_by_topic_container,
  .app_satisfaction_container {
    height: 50%;
  }

  .ratings_tile {
    padding: 1.25em;
    border-radius: 5px;
    position: relative;
    overflow: hidden;

    .ratings_select {
      margin-right: 0;
    }

    .review_language_toggle {
      position: absolute;
      right: 3em;

      .toggle_container {
        display: flex;
        flex-direction: column;
      }
    }

    @include themify($themes) {
      border: 1px solid themed("primary-font-color");
      background: themed("ccsContentBackground");
      height: 100%;
    }
  }

  .ratings_tile_content {
    display: flex;
    flex-flow: column;
    gap: 15px;
  }

  .chart_tile_content {
    display: block;
    height: 40vh;
    padding-bottom: 0.5em;
    width: 100%;

    @media screen and (min-width: 1280px) {
      height: 100%;
      width: 50%;
      min-height: 40vh;
    }

    .metricChart {
      @media screen and (min-width: 1024px) {
        width: 100%;
      }
    }
  }

  #grouped_ratings {
    .ratings_tile_content {
      @media screen and (min-width: 1024px) {
        flex-flow: row;
      }
    }
  }

  .application_type {
    display: flex;
    align-items: center;
  }

  .clickable_app_type {
    display: flex;
    justify-content: center;
    padding: 0.5em 1em;
    text-align: center;
    height: 4.15em;
    align-items: center;
    cursor: pointer;
    border-radius: 30px;

    @include themify($themes) {
      color: themed("accent_color");
    }

    @media screen and (min-width: 1024px) {
      padding: 0.1em 1em;
    }

    &.disabled {
      pointer-events: none;

      @include themify($themes) {
        color: #a4a4a4;
      }
    }
  }

  .ratings_tile_header {
    margin-bottom: 0.75em;
    font-size: var(--header_font);
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .title_tooltip {
      display: flex;
      flex-flow: row;
    }

    .module_note {
      font-size: calc(var(--header_font) - 0.1em);
    }
  }

  .pills_container {
    margin-bottom: 2em;
  }

  .comments_header {
    position: sticky;
    top: 0;

    @include themify($themes) {
      background-color: themed("primary-tile-background") !important;
    }
  }

  .comments_table {
    max-height: 75vh;
    overflow-y: auto;
    @include scroll-bar();

    @media screen and (min-width: 1280px) {
      max-height: 76.5vh;
      height: 100%;
    }
  }

  .market_ratings {
    display: flex;
    gap: 15px;
    flex-flow: column;

    @media screen and (min-width: 1024px) {
      flex-flow: row;
      width: 100%;
    }

    .tri-sectioned {
      @include themify($themes) {
        border-left: 1px solid themed("primary-font-color");
      }
    }
  }

  .tri-sectioned {
    display: flex;
    width: 100%;
    gap: 10px;

    @media screen and (min-width: 1024px) {
      flex-flow: column;
      height: 100%;
    }

    &.disabled {
      .first_div {
        .tile_header {
          pointer-events: none;

          @include themify($themes) {
            color: themed("secondary-font-color");
          }
        }
      }
    }

    .first_div {
      width: calc((1 / 4 * 100%));
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid;

      @media screen and (min-width: 1024px) {
        width: 100%;
        height: calc((1 / 4 * 100%));
      }

      .tile_header {
        cursor: pointer;

        @include themify($themes) {
          color: themed("accent_color");
        }
      }

      &.disabled {
        pointer-events: none;

        @include themify($themes) {
          color: themed("secondary-font-color");
        }
      }
    }

    .second_div,
    .third_div,
    .fourth_div,
    .fifth_div,
    .sixth_div {
      width: calc((1.5 / 4 * 100%));
      border-bottom: 1px solid;

      @media screen and (min-width: 1024px) {
        width: 100%;
        height: calc((1.5 / 4 * 100%));
      }

      &.disabled {
        pointer-events: none;

        @include themify($themes) {
          color: themed("secondary-font-color");
        }
      }
    }

    .tile_content {
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    .main,
    .count {
      text-align: center;
    }

    .main {
      font-size: var(--header_font);
    }
  }

  .marketBreakdown {
    &.disabled {
      pointer-events: none;

      @include themify($themes) {
        color: themed("secondary-font-color");
      }

      .MuiRating-root,
      .MuiRating-iconEmpty {
        @include themify($themes) {
          color: themed("secondary-font-color") !important;
        }
      }

      .LegendMarker {
        @include themify($themes) {
          background-color: themed("secondary-font-color") !important;
          border-color: themed("secondary-font-color") !important;
        }
      }
    }
  }

  .appRatingsBreakdownTableContainer {
    height: 98%;
    overflow-y: scroll;
    @include scroll-bar;
  }

  // ccs europe breakdown table classes
  #europe_app_ratings_breakdown_table {
    border-collapse: separate;
    border-spacing: 0;
    padding-right: 1em;
    height: 100%;

    th {
      position: sticky;
      top: 0;

      @include themify($themes) {
        background: themed("ccsDashboardImage");
      }

      padding: 1.1em;
    }

    tr.odd {
      background-color: inherit !important;
    }

    tbody tr {
      height: 1em;
    }

    tbody tr td {
      border: 1px solid gray;
    }

    tbody tr td:first-of-type {
      border: none;
    }

    tbody tr td:nth-of-type(2) {
      border-left: none;
      border-right: none;
    }

    tbody tr td:nth-of-type(3) {
      border-right: none;
    }

    tfoot,
    tfoot td {
      position: -webkit-sticky;
      position: sticky;
      bottom: 0;
      z-index: 4;

      @include themify($themes) {
        background: themed("ccsDashboardImage");
      }
    }

    thead tr th:nth-of-type(3),
    tfoot tr td:nth-of-type(3) {
      border-left: 1px solid gray;
    }

    .row-disabled {
      @include themify($themes) {
        color: themed("secondary-font-color");
      }
    }

    .legend-box-disabled {
      @include themify($themes) {
        background-color: themed("secondary-font-color") !important;
      }
    }
  }

  .tile_header {
    align-items: center;
  }
}

.trend-legend-color {
  display: flex;
  flex-direction: column;
  font-size: var(--regular_font_size);

  .legend-item-color {
    display: flex;
    flex-direction: row;
    margin-bottom: auto;
    cursor: pointer;
  }

  .legend-box-color {
    width: 1rem;
    height: 1rem;
    margin-right: 1em;
    border-radius: 0.2em;
  }
}

.disabled-text {
  color: gray;
}

.disabled {
  background-color: rgb(211, 211, 211, 0.5);
}
