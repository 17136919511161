@import "../colors";
@import "../effects";
@import "../themes";

.midas_fmi_by_model_sotu {
  display: grid;
  grid-gap: 0.75rem;
  grid-template-areas: "fmi_by_model";
  grid-template-rows: max-content;

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .primary_background {
    @include themify($themes) {
      background: themed("primary-tile-background");
    }
  }

  max-width: calc(100vw - 4em);

  h3 {
    font-size: var(--header_font);
    font-weight: 300;
    margin-bottom: 0.2em;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }

    text-align: left;
  }

  .quarter_title {
    font-size: var(--regular_font_size);
  }

  .legend {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    font-size: var(--regular_font_size);
    padding-left: 1em;
    padding-top: 0.5em;
    padding-bottom: 0em;

    .legend_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 0.3em;

      .legend_box {
        width: 1rem;
        height: 1rem;
        margin-right: 0.3em;
        border-radius: 0.2em;
      }
    }
  }

  @media screen and (min-width: 1250px) {
    .fmi_model_by_quarter {
      position: relative;

      .fmi_by_model_charts {
        padding: 0.5em 1.5em 0.5em;
        position: relative;

        .charts {
          position: relative;
          display: grid;
          grid-template-columns: 1.3fr 0.8fr 0.8fr 0.8fr 1.1fr;

          .quarter_breakdown {
            position: relative;
            display: flex;
            flex-flow: column;
            align-items: left;
            min-height: 70vh;

            &.q1 {
              .quarter_title {
                margin-left: 2.4em;
              }
            }

            .vertical_line {
              position: absolute;
              top: 3.5%;
              left: 15.2em;
              height: 96%;
              @include themify($themes) {
                border-left: themed("thinBorder");
              }
            }

            &:nth-child(1) {
              align-items: center;
            }

            .no_data {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .h-150 {
            min-height: 300vh;
          }

          .h-100 {
            min-height: 150vh;
          }

          .chartWrapper {
            @include themify($themes) {
              border-right: themed("thinBorder");
            }
          }

          .chartWrapper,
          .graph {
            min-height: 70vh !important;
          }
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .fmi_model_by_quarter {
      .fmi_by_model_charts {
        .charts {
          margin-left: -2%;
          grid-template-columns: 1.3fr 0.8fr 0.8fr 0.8fr 1.1fr;
          .quarter_breakdown {
            &.q1 {
              .quarter_title {
                margin-left: -7%;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .fmi_model_by_quarter {
      .fmi_by_model_charts {
        .charts {
          .quarter_breakdown {
            &.q1 {
              .quarter_title {
                margin-left: -18%;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1920px) {
    .fmi_model_by_quarter {
      .fmi_by_model_charts {
        .charts {
          grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;

          .quarter_breakdown {
            &.q1 {
              .quarter_title {
                margin-left: -20%;
              }
            }
          }
        }
      }
    }
  }
}
