@import "../colors";
@import "../effects";
@import "../themes";

.pf_data_status {
  display: flex;
  flex-direction: column;
  font-size: var(--smaller_regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -1rem;
    margin-right: calc(var(--pdf_menu_img) + 1rem);
    gap: 5px;
  }

  .bullet {
    padding: 0 0.2rem;
  }

  .status {
    padding-left: 0.2rem;
  }

  .Pending {
    color: $amber;
  }

  .Forecasted,
  .Available {
    color: $green;
  }
}

.pf_container {
  display: grid;
  row-gap: 0.75rem;
  padding-bottom: 3.5rem;
}

.pf_tile {
  padding: 1.25em 1.5em;
  border-radius: 5px;
  position: relative;

  @include themify($themes) {
    background: themed("primary-tile-background");
  }

  h3 {
    font-size: var(--header_font);
    text-align: left;
    font-weight: 300;

    @include themify($themes) {
      color: themed("primary-font-color");
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  table {
    @include themify($themes) {
      color: themed("primary-font-color");
    }

    th {
      background-color: unset;
    }

    thead,
    tbody {
      th,
      td {
        @include themify($themes) {
          border: 1px solid themed("primary-font-color");
        }
      }
    }

    tbody {
      tr:nth-of-type(odd) {
        @include themify($themes) {
          background-color: themed("tableOddColor") !important;
        }
      }
    }

    border-collapse: collapse;
  }
}

.pf_key_indicators {
  position: relative;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 0.75em;

    span {
      font-size: var(--smaller_regular_font_size);
      text-align: left;
      font-weight: 300;
      margin-left: 0.5em;

      @include themify($themes) {
        color: themed("primary-font-color");
      }
    }
  }
}

.pf_key_indicators_section {
  display: grid;
  gap: 0.75rem;

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.pf_key_indicator_container {
  padding: 0.85em 0.5em;

  @include themify($themes) {
    border: themed("thinBorder");
    color: themed("primary-font-color");
  }

  .pf_key_indicator_header {
    font-weight: 300;
    font-size: var(--regular_font_size);
    margin: auto 0;
    display: flex;
  }

  .values {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.75rem;
    padding: 1rem 0.5rem 0;
  }

  .main_value {
    display: flex;
    flex-flow: column;
  }

  .main_value_number {
    font-size: 1.75rem;
  }

  .main_value_label {
    font-size: var(--smaller_regular_font_size);
  }

  .period_comparison {
    display: flex;
    align-items: center;
    font-size: var(--regular_font_size);

    @media screen and (min-width: 1280px) {
      margin: 0.5rem;
      white-space: nowrap;
    }
  }

  .main_target_comparison {
    font-size: var(--menu_size);
  }

  .latest_month_values {
    display: grid;

    @media screen and (min-width: 1280px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .current_values {
    display: grid;

    @include themify($themes) {
      border-right: themed("thinBorder");
    }

    @media screen and (min-width: 1280px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.pf_row {
  display: grid;
  gap: 0.75rem;

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.pf_chart_container {
  // height: max-content;
  min-height: 25rem;

  h3 {
    margin-bottom: 0;
  }

  .purchase_funnel_toggle {
    margin-left: auto;
  }

  .chartWrapper {
    height: 43vh;
  }
}

.pf_duration_header {
  display: grid;
  margin-bottom: 1rem;

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.variance-table {
  border-collapse: separate;
  width: 100%;
}

.sales_by_car_model {
  @include themify($themes) {
    color: themed("primary-font-color");
  }

  h3 {
    font-size: var(--header_font);
    font-weight: 300;
    margin-bottom: 0.2em;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }

    text-align: left;
  }

  .charts {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;

    .title {
      display: flex;
      padding-bottom: 10px;
    }

    .chart_content {
      position: relative;
      min-height: 350px;
      height: 100%;

      .chart,
      .graph {
        min-height: 350px !important;
      }

      .no_data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .model_performance {
      .car_model_charts {
        padding: 1.25em 1.5em;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .model_performance_variance {
        padding: 1.25em 1.5em;
        padding-top: 5em;
      }
    }
  }

  .dis_volume_breakdown {
    padding: 1.25em 1.5em;
    display: flex;
    flex-direction: column;
  }
}
