@import "../colors";
@import "../effects";
@import "../themes";

.info_page_dashboard {
  display: grid;
  grid-template-areas: "dashboard_options" "blank" "main_content";
  grid-gap: 1em;
  grid-template-rows: auto;
  padding: 1.5em 2em;
}
.info_dashboard_options {
  display: flex;
  justify-content: center;
  grid-area: dashboard_options;
  @include themify($themes) {
    color: themed("keyIndicator_accentColor");
    border-bottom: 1px solid themed("secondary-font-color");
  }

  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  flex-flow: column;
  a {
    padding: 0.5em;
    margin: 0 1em;
    text-decoration: none;
    @include themify($themes) {
      color: themed("keyIndicator_accentColor");
    }
    text-align: center;
    text-transform: uppercase;
    font-size: calc(var(--page_title) + 1em);
    font-family: "nissan", "Open Sans", sans-serif;

    &.active {
      @include themify($themes) {
        border-bottom: themed("keyIndicator_accentColor") 3px solid;
        color: themed("keyIndicator_accentColor");
      }
      font-family: "nissan_bold", "Open Sans", sans-serif;
    }
  }
}

.info_container {
  grid-area: main_content;
  display: grid;
  gap: 2em;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "info_options"
    "info_content";
  margin: 0;
  position: relative;
  padding: 0;
}

.info_section {
  max-height: 85vh;
  border-radius: 0.25em;
  @include themify($themes) {
    background-color: themed("infoSectionBackgroundColor");
    color: themed("primary-font-color");
  }

  .input_field {
    width: 75%;
    position: relative;
    margin-left: auto;

    @media screen and (min-width: 1024px) {
      width: 30%;
    }
  }

  .dropdown {
    width: 100%;
    position: relative;
    @include themify($themes) {
      border: 1.5px solid themed("keyIndicator_accentColor");
    }
    border-radius: 5em;
    background-color: transparent;
    cursor: pointer;
    input {
      width: 90%; /* WebKit-based browsers will ignore this. */
      cursor: pointer;
      border: none;
      border-radius: none !important;
      @include themify($themes) {
        color: themed("primary-font-color");
      }
    }
  }
  .dropdown-options {
    @include themify($themes) {
      background: themed("primary-tile-background");
    }
    padding: 0.25em 0;
    position: absolute;
    z-index: 999;
    text-align: left;
    box-shadow: 0 0 0.5em rgba(164, 164, 164, 0.5);
    max-height: 19em;
    color: rgba(44, 57, 78, 0.8);
    left: 0;
    right: 0;
    top: 100%;
    text-transform: initial;
    margin: 0.5em 0 0;
    overflow-y: auto;
    @include scroll-bar;
    li {
      list-style: none;
      padding: 0.5em;
      cursor: pointer;
      font-size: var(--form_font);
      letter-spacing: 0;
      font-family: "nissan", "Open Sans", sans-serif;
      display: inline-block;
      width: 100%;
      word-break: break-word;
      color: rgba(44, 57, 78, 0.8);
      &::before {
        content: none !important;
      }
    }
  }
}

.info_options {
  overflow: auto;
  width: 100%;
  grid-area: info_options;
  height: fit-content;
  text-align: left;
  font-size: var(--header_font);
  @include scroll-bar;

  li {
    list-style: none;
    margin: 0;
    padding: 1.25em 2em;
    cursor: pointer;
    @include themify($themes) {
      border-left: 3px themed("infoOptionAccent") solid;
    }
    transition: ease-in all 0.2s;
    border-bottom: 1px solid rgba(164, 164, 164, 0.1);
    border-spacing: 15px;

    &:last-of-type {
      border-bottom: none;
    }

    &.active,
    &:hover {
      @include themify($themes) {
        border-left-color: themed("keyIndicator_accentColor");
      }
    }

    &:hover {
      @include themify($themes) {
        background-color: themed("infoOptionAccent");
      }
    }
  }
}

.info_content {
  background: rgba(44, 57, 78, 0.8);
  font-size: var(--header_font);
  overflow-y: auto;
  padding: 2.5em 2em;
  min-height: 30vh;
  overflow-y: auto;
  @include scroll-bar;

  h3 {
    font-family: "nissan-bold", "Open Sans", sans-serif;
    font-weight: 300;
    margin-bottom: 0.5em;
    display: inline-block;
    color: $cyan;
    text-transform: uppercase;
  }

  h4 {
    text-decoration: underline;
  }

  ul {
    @include themify($themes) {
      color: themed("primary-font-color");
    }
    padding-left: 1rem;
    margin-bottom: 1em;
  }
}

.release_notes_container {
  h3 {
    text-transform: initial;
  }
  h4 {
    margin: 1.5em 0 0.5em 0;
  }
}

@media screen and (min-width: 768px) {
  .info_dashboard_options {
    flex-flow: row;
  }
}

@media screen and (min-width: 1024px) {
  .info_dashboard_options {
    a {
      font-size: var(--page_title);
    }
  }
  .info_container {
    grid-template-columns: 1.5fr 5fr;
    grid-template-areas: "info_options info_content";
    width: 100%;
  }

  .info_content {
    padding: 2.5em 4em;
    h3 {
      font-size: var(--page_title);
    }
  }
}
