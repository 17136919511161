@import "../colors";
@import "../effects";
@import "../themes";

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1005;
  background: rgba($dark_blue, 0.75);
  place-items: center;
  display: none;
  top: 0;

  &.show {
    display: grid;
  }

  .popupModal {
    position: relative;
    width: 80%;
    height: 80%;
    @include themify($themes) {
      background: themed("documentationBackgroundColor");
    }
    background: rgba(44, 57, 78, 0.8);
    padding: 2em;
    border-radius: 0.25em;
    font-size: var(--popup_font);
    overflow-y: auto;
    @include scroll-bar;

    &.small {
      width: auto;
      height: auto;

      .data_message {
        p {
          padding: 2em 0 0;
          @include themify($themes) {
            color: themed("primary-font-color");
          }
        }
      }
    }

    &.withForm {
      width: auto;
      background: transparent;

      .form_body {
        margin: auto;
        @include themify($themes) {
          border: 1px solid themed("primary-font-color");
        }
      }
    }
  }

  .closePopup {
    position: absolute;
    right: 1em;
    top: 1em;
    background: none;
    border: none;

    @include themify($themes) {
      color: themed("primary-font-color");
    }

    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .popupBtns {
    display: flex;
    justify-content: space-evenly;
    padding: 1rem 0 0;

    button {
      cursor: pointer;
      padding: 0.5rem 1rem;
      background: $bright_white;
      border: none;
      border-radius: 0.25em;
      color: black;
      font-size: 0.9rem;

      &:focus {
        outline: none;
      }
    }
  }
}

.data_documentation {
  h3,
  h4 {
    font-weight: 300;
  }

  h3 {
    display: inline-block;
    font-size: 1.1rem;
    text-transform: uppercase;
    margin-bottom: 1em;
    padding: 0 0.5em 0.125em 0;
    @include themify($themes) {
      color: themed("secondary-font-color");
      border-bottom: 1px solid themed("secondary-font-color");
    }
  }

  h4 {
    @include themify($themes) {
      color: themed("documentationHeaderColor");
      font-weight: themed("documentationHeaderWeight");
    }
    margin-bottom: 0.35em;
    margin-top: 1em;
  }

  p {
    @include themify($themes) {
      color: themed("primary-font-color");
    }
    margin-bottom: 1em;
  }

  a {
    color: $cyan;
    text-decoration: none;

    &:hover {
      color: $cyan_bright;
    }
  }
}

.documentation_popup {
  margin: auto;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;

  .popupModal {
    width: 100%;
    height: 100%;
  }
  .closePopup {
    position: fixed;
    right: 11%;
    top: 13%;
  }
}
