:root {
  // General values

  --legend_box-size: 1.25em;
  --filter_font: 0.65rem;
  --form_font: 0.7rem;
  --menu_size: 0.6rem;
  --logo_padding: 1.25em;
  --page_title: 1rem;
  --menu_spacing: 2em;

  --regular_font_size: 0.75rem;
  --smaller_regular_font_size: calc(var(--regular_font_size) - 1.2px);
  --vs_input_font_size: 0.9rem;
  --vs_btn_size: 1.65rem;

  --tooltip_font: 0.8rem;

  --header_font: 0.9rem;

  --big_number: 3.75rem;
  --big_number_oto: 2.5rem;
  --big_number_drilldown: 2.5rem;
  --small_number_drilldown: 1.25rem;
  --rating_small: 0.55em;
  --chart_small: 0.75em;

  --small_number: 1.25rem;
  --small_number_oce: 1.5rem;
  --pts_font: 1rem;
  --small_number_oto: 1.1rem;
  --small_number_metric: 1rem;

  --conversion_rate: 1rem;

  // Online to Physical
  --rating_height: 1.5em;
  --rating_width: 3em;

  //popup
  --popup_font: 0.9rem;

  // Safari
  --safari_big: 3.25rem;
  --safari_small: 1.5rem;

  //geography arrows
  --arrow_border_width: 1px;
  --arrow_padding: 3px;
  //sizes for the toggle button
  --toggle_button_width: 1.6rem;
  --toggle_button_height: 0.8rem;
  --toggle_after_circle: 0.7rem;

  --menu_width: 3.75rem;

  // pdf menu image size
  --pdf_menu_img: 2.2rem;

  //filter and menu arrow size
  --span_arrow_border_left_right: 5px;
  --span_arrow_border_top: 7px;
}

@media screen and (min-width: 1250px) {
  :root {
    --filter_font: 0.825rem;
    --form_font: 0.7rem;
    --menu_size: 0.8rem;
    --page_title: 1.3rem;

    --header_font: 0.9rem;
    --big_number: 4.5rem;
    --big_number_oto: 3.25rem;
    --big_number_drilldown: 3.5rem;
    --small_number: 1.7rem;
    --small_number_oce: 2rem;
    --small_number_oto: 1.6rem;
    --small_number_metric: 1.5rem;
    --conversion_rate: 1.25rem;

    --menu_spacing: 1em 0;
    --logo_padding: 1.25em;

    --regular_font_size: 1rem;
    --vs_input_font_size: 1rem;
    --tooltip_font: 1rem;

    --menu_width: 5rem;

    // Online to Physical
    --rating_height: 2em;
    --rating_width: 4em;

    --info-header: 1.2rem;
    --info-content-header: 1rem;
    --info-content: 1rem;

    --pdf_menu_img: 2rem;
  }
}

@media screen and (min-width: 1350px) {
  :root {
    --regular_font_size: 0.8rem;
    --tooltip_font: 0.8rem;

    --info-header: 1.25rem;
    --form_font: 0.75rem;

    --big_number_oto: 3rem;
    --big_number_drilldown: 3.5rem;

    --small_number: 2rem;
    --small_number_oce: 2.2rem;

    --header_font: 1rem;

    --pdf_menu_img: 2rem;
    --menu_width: 4rem;
  }
}

@media screen and (min-width: 1400px) {
  :root {
    --filter_font: 0.75rem;
    --form_font: 0.75rem;
    --menu_size: 0.75rem;
    --big_number: 3.75rem;
    --big_number_oto: 3.75rem;
    --big_number_drilldown: 3.75rem;
    --regular_font_size: 0.85rem;
    --tooltip_font: 0.85rem;

    --info-header: 1rem;
    --info-content-header: 0.85rem;
    --info-content: 0.85rem;
    --rating_small: 0.8em;
    --chart_small: 0.5em;
  }
}

@media screen and (min-width: 1600px) {
  :root {
    --filter_font: 1rem;
    --form_font: 1rem;
    --menu_size: 0.8rem;
    --page_title: 1.5rem;

    --header_font: 1.2rem;
    --big_number: 5.5rem;
    --big_number_oto: 3.75rem;
    --big_number_drilldown: 4.5rem;
    --small_number: 2.4rem;
    --small_number_oce: 2.8rem;
    --small_number_oto: 2rem;
    --small_number_metric: 1.75rem;
    --conversion_rate: 1.75rem;
    --rating_small: 0.55em;
    --chart_small: 0.5em;

    --menu_spacing: 1.5em 0;
    --menu_width: 5rem;
    // Online to Physical
    --rating_height: 2.25em;

    --regular_font_size: 1rem;
    --tooltip_font: 1rem;
    --vs_input_font_size: 1.2rem;
    --vs_btn_size: 1.9rem;

    //Info & Release notes section
    --info-header: 1rem;
    --info-content-header: 1.1rem;
    --info-content: 1.1rem;

    //Geography arrows
    --arrow_border_width: 1.5px;
    --arrow_padding: 3.5px;
    //sizes for the toggle button
    //--toggle_button_width: 3rem;
    //--toggle_button_height: 1.5rem;
    //--toggle_after_circle: 1.35rem;
    --pdf_menu_img: 2.2rem;
    --span_arrow_border_left_right: 0.4rem;
    --span_arrow_border_top: 0.6rem;
  }
}

@media screen and (min-width: 1650px) {
  :root {
    --big_number: 5.5rem;
    --big_number_oto: 3.75rem;
    --small_number: 2.5rem;
    --small_number_oto: 2.25rem;
    --small_number_metric: 1.75rem;
    --header_font: 1.25rem;
    --page_title: 1.75rem;
    --regular_font_size: 1.1rem;
    --info-header: 1.5rem;
    --rating_small: 0.55em;
    --chart_small: 0.65em;
  }
}

@media screen and (min-width: 1900px) {
  :root {
    --filter_font: 1rem;
    --form_font: 1rem;
    --menu_size: 0.9rem;

    --page_title: 2rem;
    --header_font: 1.25rem;
    --big_number: 5rem;
    --big_number_oto: 4rem;
    --big_number_drilldown: 5rem;
    --small_number: 2.75rem;
    --small_number_oce: 3.5rem;
    --small_number_metric: 1.8rem;
    --rating_small: 0.75em;
    --chart_small: 0.65em;

    --small_number_oto: 2.5rem;
    --menu_spacing: 2em 0;
    --logo_padding: 3em;

    --regular_font_size: 1.2rem;
    --tooltip_font: 1.2rem;
    --vs_input_font_size: 1.25rem;

    --rating_height: 3.25em;
    --rating_width: 3.25em;
    --menu_width: 5.75rem;

    //sizes for the toggle button
    //--toggle_button_width: 3rem;
    //--toggle_button_height: 1.5rem;
    //--toggle_after_circle: 1.35rem;
    --span_arrow_border_left_right: 0.5rem;
    --span_arrow_border_top: 0.7rem;
  }
}

@media screen and (min-width: 2300px) {
  :root {
    --form_font: 1.5rem;
    --filter_font: 1.5rem;
    --menu_size: 1.4rem;
    --page_title: 2.5rem;
    --header_font: 1.8rem;

    --big_number: 8.5rem;
    --big_number_oto: 5.25rem;
    --big_number_drilldown: 6.75rem;
    --small_number: 3.5rem;
    --small_number_oce: 4.25rem;
    --small_number_oto: 3.5rem;
    --small_number_metric: 2.5rem;

    --conversion_rate: 2.25rem;

    --menu_spacing: 5em 0;
    --menu_width: 7.5rem;
    --logo_padding: 5em;
    // Online to Physical
    --rating_height: 3.5em;
    --rating_width: 5em;
    //Info & Release notes section
    --info-header: 2.5rem;
    --info-content-header: 1.75rem;
    --info-content: 1.75rem;

    --regular_font_size: 1.5rem;
    --tooltip_font: 1.75rem;
    --vs_input_font_size: 1.75rem;
    --vs_btn_size: 2.75rem;

    --rating_height: 5em;
    --rating_width: 5em;

    //Geography arrows
    --arrow_border_width: 2px;
    --arrow_padding: 6px;

    //sizes for the toggle button
    //--toggle_button_width: 4rem;
    //--toggle_button_height: 2rem;
    //--toggle_after_circle: 1.85rem;

    --pdf_menu_img: 3rem;
    --span_arrow_border_left_right: 0.7rem;
    --span_arrow_border_top: 0.9rem;
  }
}

@media screen and (min-width: 2500px) {
  :root {
    --page_title: 3rem;
    --header_font: 1.75rem;
    --regular_font_size: 1.7rem;
    --tooltip_font: 1.55rem;
    --vs_input_font_size: 1.55rem;

    --big_number: 7.5rem;
    --small_number: 3.75rem;
    --small_number_oce: 5rem;
    --small_number_metric: 3rem;

    --big_number_oto: 5.75rem;
    --big_number_drilldown: 7rem;
    --small_number_oto: 3.75rem;
    --conversion_rate: 2.25rem;

    --rating_height: 6em;
    --rating_width: 6em;
  }
}

@media screen and (min-width: 3150px) {
  :root {
    --form_font: 2rem;
    --filter_font: 2rem;
    --menu_size: 1.6rem;

    --page_title: 3.5rem;
    --header_font: 2.75rem;
    --big_number: 11.5rem;
    --big_number_oto: 7rem;
    --big_number_drilldown: 8.75rem;
    --small_number: 4.75rem;
    --small_number_oce: 6rem;
    --small_number_oto: 4.5rem;
    --small_number_metric: 3.5rem;
    --conversion_rate: 3rem;

    --menu_spacing: 5.5em 0;
    --menu_width: 10rem;
    --logo_padding: 5.5em;
    // Online to Physical
    --rating_height: 4em;
    --rating_width: 6em;

    --regular_font_size: 2.1rem;
    --tooltip_font: 2rem;
    --vs_input_font_size: 2.2rem;
    --vs_btn_size: 3.5rem;

    --rating_height: 7.5em;
    --rating_width: 7.5em;
    //Geography arrows
    --arrow_border_width: 2.5px;
    --arrow_padding: 8px;

    //sizes for the toggle button
    //--toggle_button_width: 6rem;
    //--toggle_button_height: 3rem;
    //--toggle_after_circle: 2.7rem;
    --span_arrow_border_left_right: 1rem;
    --span_arrow_border_top: 1.2rem;
  }
}

@media screen and (min-width: 3800px) {
  :root {
    --filter_font: 2.3rem;
    --form_font: 2.3rem;
    --menu_size: 1.8rem;

    --page_title: 4.5rem;
    --header_font: 3rem;
    --big_number: 10.5rem;
    --big_number_oto: 8.5rem;
    --big_number_drilldown: 9.75rem;
    --small_number: 5.75rem;
    --small_number_oce: 5rem;
    --small_number_oto: 5.5rem;
    --small_number_metric: 4rem;
    --conversion_rate: 3.75rem;

    --menu_spacing: 5em 0;
    --menu_width: 12rem;
    --logo_padding: 6em;
    // Online to Physical
    --rating_width: 10em;
    --rating_height: 6.5em;

    // Info section
    --info-header: 2.5rem;
    --info-content-header: 2.5rem;
    --info-content: 2.5rem;

    --vs_input_font_size: 2.5rem;
    --regular_font_size: 2.6rem;
    --tooltip_font: 2.5rem;
    --vs_btn_size: 3.75rem;

    --rating_height: 8.5em;
    --rating_width: 8.5em;
  }
}

// Media query support for ipads
@media screen and (min-width: 1250px) {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      :root {
        --safari_big: 3.25rem;
        --safari_small: 1.5rem;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      :root {
        --safari_big: 3.5rem;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      :root {
        --safari_big: 4.25rem;
        --safari_small: 1.8rem;
      }
    }
  }
}

@media screen and (min-width: 1900px) {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      :root {
        --safari_big: 4.25rem;
        --safari_small: 1.8rem;
      }
    }
  }
}

@media screen and (min-width: 2300px) {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      :root {
        --safari_big: 7.5rem;
        --safari_small: 3.5rem;
      }
    }
  }
}

@media screen and (min-width: 3150px) {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      :root {
        --safari_big: 8rem;
        --safari_small: 3rem;
      }
    }
  }
}

@media screen and (min-width: 3800px) {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      :root {
        --safari_big: 10rem;
        --safari_small: 5rem;
      }
    }
  }
}

@media screen and (max-width: 666px) {
  $button_height: 30px;
  $canvas_size: 200px;

  :root {
    // General values
    --filter_font: 0.65rem;
    --menu_size: 0.6rem;
    --logo_padding: 1.25em;
    --page_title: 0.8rem;
    --menu_spacing: 1em 0;

    --regular_font_size: 0.75rem;
    --vs_input_font_size: 0.9rem;

    --tooltip_font: 0.8rem;
    --header_font: 1rem;
    --close_icon_font: 1rem;
    --big_number: 3rem;
    --big_number_oto: 3rem;
    --small_number: 1.3rem;
    --small_number_oto: 1.1rem;

    // Online to Physical
    --rating_height: 1.5em;
    --rating_width: 3em;

    //popup
    --popup_font: 0.9rem;

    // Safari
    --safari_big: 3.25rem;
    --safari_small: 1.5rem;
  }
}

@media screen and (max-width: 360px) {
  :root {
    --page_title: 0.7rem;
    --close_icon_font: 1rem;
  }
}
