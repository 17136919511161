@import "./scroll_bar";

.titleContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (min-width: 750px) {
    margin-bottom: 2.5em;
  }
}

.title {
  font-size: 1.25rem;
  margin-top: 1.5em;
  text-align: center;
  font-weight: bold;

  @media screen and (min-width: 750px) {
    margin-top: 0;
    text-align: left;
    position: absolute;
    bottom: 35%;
    left: 2rem;
  }
}

.titleImg {
  width: 100%;
}

.content {
  padding: 0 1.5rem 2rem;
}

.main {
  overflow-y: auto;
  overflow-x: hidden;

  @include scroll-bar;

  @media screen and (min-width: 750px) {
    padding-top: 5em;
    grid-area: body;
  }
}

.hide_on_mobile {
  @media screen and (max-width: 749px) {
    display: none !important;
  }
}

.hide_on_desktop {
  @media screen and (min-width: 750px) {
    display: none !important;
  }
}
