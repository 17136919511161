@import "../colors";

.error_msg {
  // color: $grey_transparent;

  &.no_data {
    margin: auto;
  }

  &.formError {
    color: $bright_white;
  }
}
