@import "../colors";
@import "../effects";
@import "../themes";

.main_dashboard {
  padding: 5em;
  height: 100vh;

  @include themify($themes) {
    color: themed("sectionFontColor");
  }
  .title {
    font-size: var(--page_title);
    font-weight: 300;
  }

  .main-cedar-dashboard {
    margin-top: 1em;
    height: 90%;
    display: grid;
    grid-column-gap: 2em;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .section {
      margin-top: 2em;
      @include themify($themes) {
        background-color: themed("sectionBackgroundColor");
      }
      .group_title_header,
      h2 {
        font-size: var(--header_font);
        @include themify($themes) {
          color: themed("headerFontColor");
        }
      }
      padding: 2em;

      .dashboard_group {
        display: flex;
        flex-flow: column;

        .group_title {
          position: relative;
          display: flex;
          flex-flow: row;
          justify-content: flex-start;
          align-items: flex-start;
          padding-bottom: 2em;

          .title_description {
            @include themify($themes) {
              color: themed("headerFontColor");
            }
            p {
              margin-top: 0.3rem;
              font-size: 1.4em;
            }
          }

          .group_icon {
            max-width: 70px;
            margin-right: 0.5em;
          }
          .arrow_border {
            padding: 0;
            margin-right: -15%;
            margin-left: 5em;
            max-height: 10em;
          }
        }
      }
    }
    .right_pane {
      display: grid;
      grid-template-rows: auto 3fr;
      grid-row-gap: 0.4em;
    }
  }

  .dashboard_button {
    display: flex;
    flex-flow: column;

    &.disabled {
      color: $white;
    }
    .main_section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.2em;
      margin-top: 1em;
      transition: all 100ms ease-in;
      font-size: var(--regular_font_size);
      font-weight: 300;

      @include themify($themes) {
        border: themed("thinBorder");
      }

      &:hover,
      &.touched {
        background-color: $blue-highlight;
      }
      &.untouched {
        background-color: transparent !important;
      }

      .icon {
        max-height: 1em;
        color: $bright_white;
        margin-left: 1em;
      }
    }
  }

  .info_board {
    padding: 1.2em;
    margin-top: 0.3em;
    background-color: $blue-light;
    color: $bright_white;
    font-size: var(--smaller_regular_font_size);

    ul {
      margin-left: 1em;
      li {
        list-style-position: inside;
        text-indent: -1.3em;

        span {
          position: relative;
          left: 0.5em;
        }
      }
    }

    .access_link {
      color: $bright_white;
    }
  }
  .overview_board {
    img {
      margin-left: 0;
      width: 100%;
      max-height: 20em;
    }
  }
  .warning_alert {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      max-width: 2em;
      margin-right: 1em;
    }
  }
}

@media screen and (max-width: 1800px) {
  .main_dashboard {
    height: 100vh;
    padding: 2em;

    .main-cedar-dashboard {
      .section {
        .dashboard_groups {
          .dashboard_group {
            .group_title {
              min-height: 10em;

              .arrow_border {
                margin-right: -22%;
                margin-left: 1em;
                max-height: 10em;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .main_dashboard {
    overflow-y: auto;
    @include scroll-bar;
    .main-cedar-dashboard {
      grid-template-columns: 2fr 1fr;
      height: auto;
      overflow-y: auto;
      @include scroll-bar;

      .section {
        .dashboard_groups {
          grid-template-columns: 1fr 1fr;
          .dashboard_group {
            .group_title {
              min-height: 10em;

              .arrow_border {
                margin-right: -17%;
                margin-left: 2em;
                max-height: 10em;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .main_dashboard {
    .main-cedar-dashboard {
      grid-template-columns: 1fr;

      .section {
        .dashboard_groups {
          grid-template-columns: 1fr 1fr;
          .dashboard_group {
            .group_title {
              min-height: 5em;
            }
            .arrow_border {
              display: none;
            }
          }
        }
      }
      .knowledge_hub,
      .open_cedar {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .dashboard_button {
          grid-column: 1;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main_dashboard {
    .title {
      font-size: calc(var(--page_title) + 1em);
    }
    .main-cedar-dashboard {
      .section {
        .dashboard_groups {
          .dashboard_group {
            grid-column: 1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .main_dashboard {
    .main-cedar-dashboard {
      .section {
        .dashboard_groups {
          .dashboard_group {
            grid-column: span 2;
          }
        }
      }
      .knowledge_hub,
      .open_cedar {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .dashboard_button {
          grid-column: span 2;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .main_dashboard {
    .main-cedar-dashboard {
      .section {
        overflow-y: auto;
        @include scroll-bar;
      }
    }
  }
}
