@import "./colors";
@import "./themes";
@import "../../../../assets/styles/fonts";

* {
  font-family: "nissan", "Open Sans", sans-serif;
  font-weight: normal;
}

.ariyaWrapper {
  @include themify($themes) {
    background: themed("backgroundColor");
    color: themed("primaryFontColor");
  }

  .container {
    padding-top: 0.25em;
  }
}
