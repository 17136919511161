@import "../colors";
@import "../effects";
@import "../themes";

.executive-summary {
  font-size: var(--regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color") !important;
  }

  .grid-row-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    min-height: 30rem;
    margin-bottom: 10px;
  }

  .grid-row-1 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    min-height: 10rem;
    margin-bottom: 10px;
  }

  .tile {
    display: block;

    .tile-header {
      display: flex;
      font-size: var(--header_font);
      margin-bottom: 1rem;
      align-items: center;
    }

    .filters-container {
      display: flex;
    }

    .sub-title {
      font-size: 0.7em;
      margin-bottom: 1rem;
    }

    @include themify($themes) {
      border: none;
      background: themed("primary-tile-background");
    }
  }

  .aggregated-purchase-funnel {
    width: 100%;

    .funnel-header {
      width: 68%;
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;
      margin-right: 15%;

      .funnel-title {
        background-color: black;
        width: 100%;
        padding: 0.3rem 0;
        text-align: center;
        color: #fff;
      }

      .market-flags {
        display: flex;
        margin-bottom: 1rem;

        .flagIcon {
          height: 35px;
          width: 35px;
          background-size: contain;
          background-repeat: no-repeat;
          display: block;
          margin-right: 0.5rem;

          &.China {
            background-image: url(../../images/Flags/flag-china.png);
          }

          &.USA {
            background-image: url(../../images/Flags/flag-us-round.png);
          }

          &.Canada {
            background-image: url(../../images/Flags/flag-canada.png);
          }

          &.Brazil {
            background-image: url(../../images/Flags/flag-brazil-round.png);
          }

          &.Japan {
            background-image: url(../../images/Flags/flag-jp-round.png);
          }

          &.UK {
            background-image: url(../../images/Flags/flag-gb-round.png);
          }

          &.Mexico {
            background-image: url(../../images/Flags/flag-mexico-round.png);
          }

          &.Philippines {
            background-image: url(../../images/Flags/flag-philippines-round.png);
          }
        }
      }

      .info {
        width: 100%;
        display: flex;
        justify-content: end;
        margin-bottom: 0.5rem;
        font-size: var(--rating_small);
      }
    }

    .funnel-item {
      display: flex;
      position: relative;
      padding-right: 15%;

      .ly-container {
        position: absolute;
        bottom: -15%;
        display: flex;

        .arrow-icon {
          rotate: 90deg;
          margin-right: 5px;
        }
      }

      .ly-one {
        right: 0;
      }

      .ly-two {
        right: 4%;
      }

      // .ly-three {
      //   right: 10%;
      // }
    }

    .funnel-item-description {
      display: flex;
      align-items: center;
      width: 20%;
      font-size: var(--rating_small);
    }

    .description-one {
      transform: translateX(10%);
    }

    .description-two {
      transform: translateX(30%);
    }

    .description-three {
      transform: translateX(60%);
    }

    .description-four {
      transform: translateX(85%);
    }

    .pyramid-section-one,
    .pyramid-section-two,
    .pyramid-section-three,
    .pyramid-section-four {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 1rem 0;
      color: white;

      .value {
        font-size: var(--header_font);
      }

      .yoy-value {
        margin-top: 1rem;
      }
    }

    .pyramid-section-one {
      background: #002060;
      clip-path: polygon(0 0, 100% 0, 93% 100%, 7% 100%);
    }

    .pyramid-section-two {
      background: #2f5496;
      clip-path: polygon(7% 0, 93% 0, 86% 100%, 14% 100%);
    }

    .pyramid-section-three {
      background: #00b0f0;
      clip-path: polygon(14% 0, 86% 0, 79% 100%, 21% 100%);
    }

    .pyramid-section-four {
      background: #aeabab;
      clip-path: polygon(21% 0, 79% 0, 76% 100%, 24% 100%);
    }

    .total-private-sales {
      display: flex;
      flex-direction: row;

      .section-title {
        margin-right: 0.5rem;
      }

      .yoy-value {
        margin-top: 0;
        margin-left: 0.5rem;
      }
    }
  }

  .aggregated-purchase-funnel-table-container {
    margin: auto 0;
  }

  .aggregated-purchase-funnel-table {
    thead {
      tr {
        th:not(:nth-of-type(1)) {
          color: #fff;
        }
      }
    }

    .parent-row {
      background: #00a0dd;
    }
  }

  .positive {
    color: #7ed321;
  }

  .negative {
    color: #d94e3d;
  }

  .title {
    font-size: var(--header_font);
  }

  .chart-tile {
    display: flex;
  }

  .breakdown-chart {
    height: 25rem;
    margin-bottom: 1rem;
    display: flex;
  }

  .map-tile {
    display: flex;

    @media screen and (min-width: 1920px) {
      height: 43rem;
    }

    height: 31.5rem;
  }

  .mapWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;

    .map {
      height: 100%;
      width: 85%;
    }

    #legenddiv {
      width: 15%;
    }
  }

  .tile:last-of-type {
    .tile-header {
      margin: 0;
    }
  }
}
