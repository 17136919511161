@import "../../../components/Layouts/Ariya/styles/colors";

.table {
  font-size: 0.85rem !important;

  th:nth-of-type(3),
  th:nth-of-type(4) {
    vertical-align: top;
  }
}
