@import "./scroll_bar";

.titleContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}

.backBtn {
  margin-left: 1.5rem;
  font-size: 0.625rem;
}

.backText {
  text-decoration: underline;
  cursor: pointer;
}

.titleLastDataRefresh {
  display: flex;
  margin: 20px 0;

  border-bottom: 2px solid $accent_brown;
  padding-bottom: 20px;
  justify-content: space-between;
}

.headerContainer {
  padding-left: 20px;
}

.title {
  font-size: 1.25rem;
  text-align: left;
  font-weight: normal;
  display: flex;
}

.titleDescription {
  font-size: 0.625rem;
  color: $lightGrey;
}

.content {
  padding: 0 1.5rem 2rem;
}

.main {
  overflow-y: auto;
  overflow-x: hidden;

  @include scroll-bar;

  @media screen and (min-width: 750px) {
    height: 100vh;
    padding-top: 5em;
  }
}

.flagDiv {
  height: 35px;
  width: 35px;
  margin: auto;
  margin-bottom: 1.5em;
  cursor: unset;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 0.5rem;
}

.GB {
  background-image: url("../../../../assets/images/Flags/flag-gb-round.png");
}

.ITA {
  background-image: url("../../../../assets/images/Flags/flag-ita-round.png");
}

.DE {
  background-image: url("../../../../assets/images/Flags/flag-de-round.png");
}

.NO {
  background-image: url("../../../../assets/images/Flags/flag-no-round.png");
}

.FR {
  background-image: url("../../../../assets/images/Flags/flag-fr-round.png");
}

.JP {
  background-image: url("../../../../assets/images/Flags/flag-jp-round.png");
}

.USA {
  background-image: url("../../../../assets/images/Flags/flag-us-round.png");
}

.SWE {
  background-image: url("../../../../assets/images/Flags/flag-swe-round.png");
}

.BE {
  background-image: url("../../../../assets/images/Flags/flag-be-round.png");
}

.NE {
  background-image: url("../../../../assets/images/Ariya/flags/flag-netherlands-round.png");
}

.NE {
  background-image: url("../../../../assets/images/Ariya/flags/flag-netherlands-round.png");
}

.CA {
  background-image: url("../../../../assets/images/Flags/flag-canada.png");
}

.hide_on_mobile {
  @media screen and (max-width: 749px) {
    display: none !important;
  }
}

.hide_on_desktop {
  @media screen and (min-width: 750px) {
    display: none !important;
  }
}
