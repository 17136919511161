@import "../colors";
@import "../effects";
@import "../themes";

.grey_info {
  @include themify($themes) {
    color: themed("secondary-font-color");
  }
}

.white_info {
  @include themify($themes) {
    color: themed("primary-font-color");
  }
}

.cockpit_layout {
  font-size: var(--regular_font_size);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "content";

  @include themify($themes) {
    color: themed("primary-font-color") !important;
  }

  * {
    &.hide_on_desktop {
      display: flex !important;

      @media screen and (min-width: 1280px) {
        display: none !important;
      }
    }

    &.hide_on_mobile {
      display: none;

      @media screen and (min-width: 1280px) {
        display: flex;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: auto 1fr;
    grid-template-areas: "title content";
    grid-gap: 10px;
    margin-bottom: 2em;
  }
}

.title_menu {
  @media screen and (min-width: 1280px) {
    display: grid !important;
    grid-gap: 10px;
    grid-template-rows: 1fr 1fr 1fr 2fr;
  }
}

.cockpit_row {
  display: flex;
  flex-flow: column;
  margin-bottom: 2.5em;

  @media screen and (min-width: 1280px) {
    flex-flow: row;
    margin-bottom: 0;
  }
}

.section_title {
  border-radius: 5px;

  @include themify($themes) {
    background: themed("cssTitleBackground");
  }

  display: flex;
  margin-bottom: 0.75em;
  cursor: pointer;
  padding: 0.5em 0;

  @media screen and (min-width: 1280px) {
    margin-bottom: 0;
    width: 8.5em;
    padding: 2.25em 0.5em;

    &:hover,
    &.active {
      background-color: $cyan;
    }
  }

  .title {
    margin: auto;
    cursor: pointer;

    @include themify($themes) {
      color: themed("ccsTitleFontColor") !important;
    }

    @media screen and (min-width: 1280px) {
      text-align: center;
    }
  }
}

.section_content {
  border-radius: 5px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;

  &#operational_content {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;

    @media screen and (min-width: 1280px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
    }
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    width: 100%;
  }

  #services_rating {
    .tile_header {
      width: 55%;
    }
  }

  &.isMetric {
    grid-template-columns: 1fr 1fr;
    // grid-template-rows: minmax(15px, 0.75fr) minmax(15px, 0.75fr);
    margin-bottom: 0.75em;

    @media screen and (min-width: 1280px) {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 0;
    }

    .tile {
      cursor: default;

      .metric_content {
        @media screen and (min-width: 1280px) {
          width: 175%;
        }
      }

      .tile_content {
        position: relative;

        #services_rating {
          .tile_header {
            width: 80%;
          }
        }

        .child_content {
          .tile_header {
            height: unset;
            width: 80%;
          }
        }

        .comparison {
          margin: 0;
        }
      }
    }

    #callCenterChartArea {
      @media screen and (min-width: 1280px) {
        flex-flow: column;
      }
    }
  }
}

#top_used_services,
#akv_activation_ratio {
  button {
    margin: auto;
    margin-bottom: 0;
    margin-top: 2em;
    width: 72.5%;
  }
}

.CCS_main_content {
  display: flex;
  flex-flow: column;
  height: 100%;

  @media screen and (min-width: 1280px) {
    flex-flow: row;
  }

  .asterisk {
    position: absolute;
    bottom: 0;

    p {
      margin-bottom: auto;
      font-size: 0.7em;
    }
  }
}

.metricChart {
  height: 100%;
  padding: 0.5em;

  @media screen and (min-width: 1280px) {
    padding: 0.5em 0;
  }
}

.cockpit_content {
  @media screen and (min-width: 1280px) {
    display: grid;
    grid-gap: 10px;
    grid-template-rows: 1fr 1fr 1fr 2fr;
  }

  &.cockpit_metric {
    grid-template-rows: 1fr;
  }
}

.tile {
  border: 1px solid $bright_white;

  @include themify($themes) {
    border: 1px solid themed("primary-font-color");
    background: themed("ccsContentBackground");
  }

  padding: 0.5em;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-flow: column;
  position: relative;

  .cockpit_toggle {
    position: relative;
    margin-left: auto;
    margin-right: 2em;
    z-index: 5;
  }

  .dashboard-link {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
    z-index: 1004;
  }

  &.disabled {
    @include themify($themes) {
      border-color: themed("disabledTileColor");
      background: themed("ccsDisabledTile");
    }

    * {
      @include themify($themes) {
        color: themed("disabledFontColor") !important;
      }

      text-align: center;
    }

    .tile_content {
      margin: auto;
      align-items: center;
    }
  }

  &.isBlue {
    border-color: $cyan;
    border-width: 2.5px;
    border-radius: 5px;
  }
}

.child_content {
  &.isBlue {
    border: solid;
    border-color: $cyan;
    border-width: 2.5px;
    border-radius: 5px;
  }
}

.ratings_select {
  margin-bottom: 0.5em;

  .tile_header {
    justify-self: end;
    margin-right: 5em;
  }

  .appstore_selection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 0;
    position: relative;

    @media screen and (min-width: 1920px) {
      margin-right: 0.5em;
    }

    .store_selection {
      margin-right: 0;

      label {
        margin-left: 0.7em;
      }

      input[type="checkbox"]:checked + label::after {
        content: "";
        position: absolute;
        width: 1.5ex;
        height: 0.7ex;
        background: rgba(0, 0, 0, 0);
        top: 45%;
        left: 3%;
        border: 3px solid $black;
        border-top: none;
        border-right: none;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      input[type="checkbox"] {
        line-height: 2.1ex;
      }

      input[type="radio"],
      input[type="checkbox"] {
        position: absolute;
        left: 10px;
        top: 11px;
        z-index: -1;
      }

      input[type="checkbox"] + label {
        position: relative;
        overflow: hidden;
        cursor: pointer;
      }

      input[type="checkbox"] + label::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 2.75ex;
        width: 2.75ex;
        background-color: white;
        border: 1px solid rgb(166, 166, 166);
        border-radius: 4px;
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
        margin-right: 0.5em;
        position: relative;
      }
    }
  }
}

.tile_header {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 1em;
}

.revenue_tile {
  .filter {
    width: 400px;
  }
}

@media screen and (max-width: 768px) {
  .revenue_tile {
    .tile_header {
      margin-left: 1.2em;
      text-align: left;
    }

    .tile_content {
      .currencyRow {
        margin-left: 0.45em;
        margin-right: 0.45em;
      }

      .main {
        justify-content: flex-start;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .engagement_tile {
    .tile_content {
      display: grid;
      grid-template-rows: 1fr 1fr;
    }
  }
}

.tile_content {
  width: 100%;

  @media screen and (max-width: 768px) {
    .child_content:nth-of-type(2) {
      margin-top: 0.5em;
    }
  }

  .split_value {
    width: 100%;
    display: flex;
    flex-flow: column;

    .value {
      width: 100%;
      flex-flow: column;
      justify-content: center;
    }

    .comparison {
      width: 100%;
    }
  }

  .value {
    display: flex;
    flex-flow: column;
    font-size: var(--small_number_oce);
    text-align: center;

    @media screen and (min-width: 1280px) {
      font-size: var(--page_title);
    }

    .value_label {
      font-size: var(--regular_font_size);
    }
  }

  .value,
  .comparison {
    @media screen and (min-width: 1280px) {
      display: grid !important;
      grid-template-rows: 1fr minmax(15px, 0.25fr);
      margin: auto;
    }
    .comparison.conversion {
      @media screen and (min-width: 1280px) {
        grid-template-rows: none;
      }
    }
  }

  .main {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .value_note {
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rating_text_value {
    font-size: var(--page_title);

    @media only screen and (max-width: 768px) {
      font-size: var(--small_number_oce);
    }
  }

  .comparison {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 1280px) {
      width: 50%;
    }
  }

  p {
    width: 100%;
  }

  @media screen and (min-width: 1280px) {
    display: flex;
    flex-flow: row;
    margin: 0;
    height: 100%;

    .child_content {
      width: 100%;
      display: flex;
      flex-flow: column;

      &.isBlue {
        border-color: $cyan;
        border-width: 2.5px;
        border-radius: 5px;
      }

      &:first-of-type {
        @include themify($themes) {
          border-right: 1px solid themed("primary-font-color");
        }
      }

      .tile_header {
        padding-left: 0.25em;
        padding-top: 0.75em;

        @media screen and (min-width: 1280px) {
          text-align: center;
          height: calc(4 * var(--regular_font_size));
        }
      }

      .value {
        margin: auto;
      }

      .comparison {
        width: 100%;
      }
    }
  }
}

.active_users {
  .tile_content {
    margin-top: -8px;

    .value,
    .comparison {
      @media screen and (min-width: 1280px) {
        display: flex !important;
        flex-flow: column;
      }
    }
  }
}

.isMetric {
  .active_users {
    .tile_content {
      margin-top: -35px;
    }
  }
}

.container_footer {
  display: flex;
  justify-content: space-evenly;
  flex-flow: column;
  gap: 15px;
}

.star_divs {
  .tile_content {
    margin: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;

    @media screen and (min-width: 1380px) {
      flex-flow: column;
      height: 100%;
    }

    .child_content {
      flex-flow: row;

      &:first-of-type {
        border-right: none;
      }
    }
  }

  .child_content {
    height: 100%;

    .tile_header {
      justify-content: center;

      @media screen and (min-width: 1280px) {
        justify-content: center;
      }
    }
  }
}

.multiple-fy-info {
  display: flex;
  text-align: center;
  align-items: center;
}

.currencyContent {
  @media screen and (min-width: 1280px) {
    width: 100%;
    align-self: center;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-content: flex-start;
  }
}

.currencyRow {
  display: flex;
  justify-content: flex-start;
  margin-left: 0.4em;

  .value {
    font-size: var(--regular_font_size);
    flex-flow: row !important;
    width: 100%;

    @media screen and (min-width: 1280px) {
      margin: 0 !important;
      width: fit-content;
    }

    .valueNumber {
      margin-left: 5px;
      font-size: 1.6rem;
    }
  }
}

#EV_rating,
#services_rating {
  .tile_header {
    text-align: left;
    justify-content: left;

    @media screen and (min-width: 1280px) {
      text-align: left;
      height: 45%;
    }
  }

  .tile_content {
    @media screen and (min-width: 1280px) {
      width: 50%;
      margin: auto;
      align-items: center;
      justify-content: center;
      flex-flow: column;
    }

    .ratings {
      font-size: var(--regular_font_size);

      @media screen and (min-width: 1380px) {
        margin: 0;
      }
    }
  }
}

.set_max_height {
  overflow-y: auto;
}

.unset_max_height {
  overflow-y: hidden;
}

#top_used_services_wrapper {
  margin: 15px 0;
  max-height: calc(23.5vh - 20px);
  width: 100%;
  @include scroll-bar();
  overflow-y: auto;
}

#operational_content {
  p {
    text-align: center;
  }

  .tile_content {
    @media screen and (min-width: 1280px) {
      flex-flow: column;

      .data_points {
        height: 100%;
        text-align: center;
        display: grid;
        place-items: flex-end;
        justify-content: center;
      }

      #data_out_definition {
        margin: auto;

        &.raiseTop {
          margin-top: 0;
          height: 100%;
        }
      }
    }
  }
}

#subscriptionPackagesContent {
  .value {
    display: flex;
    flex-flow: column;
    padding: 0.75em 0;
  }
}

.isMetric {
  #top_used_services_wrapper {
    max-height: calc(0.4 * 62.5vh + 250px);

    @media screen and (min-width: 1920px) {
      max-height: calc(0.4 * 62.5vh + 400px);
    }
  }

  .tile_content {
    flex-flow: column;
    justify-content: space-evenly;

    @media screen and (min-width: 1280px) {
      justify-content: center;
      align-items: center;
    }

    .value {
      display: flex;
      justify-content: center;
      flex-flow: column;

      @media screen and (min-width: 1280px) {
        margin: 0 auto;
        align-items: center;
      }
    }
  }

  .child_content {
    &:first-of-type {
      border-right: none !important;
    }
  }

  .currencyRow {
    flex-flow: column;
    align-items: center;
  }

  .star_divs {
    .tile_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px !important;
    }

    .child_content {
      height: fit-content;
      // margin-bottom: 25px;
      flex-flow: column;
    }
  }

  #top_used_services {
    .CCS_main_content {
      @media screen and (min-width: 1280px) {
        flex-flow: column;
      }
    }

    .tile_content {
      @media screen and (min-width: 1280px) {
        max-height: unset;
        justify-content: flex-start;
        padding-bottom: 1em;
      }

      border-bottom: 1.5px solid $white;
      margin-bottom: 1em;
    }

    .metric_content {
      @media screen and (min-width: 1280px) {
        width: 100%;
        height: 100%;
        padding-top: 1.5em;
      }
    }
  }

  #akv_activation_ratio {
    .tile_content {
      .chart_area {
        height: 100%;
      }

      .warranty_start {
        height: auto;
        padding-bottom: 10%;
      }
    }
  }
}

#akv_activation_ratio {
  .isOperational {
    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;

    .tile_content {
      justify-content: center;
      align-items: center;
      justify-content: center;
    }

    .chart_area {
      height: 100%;
    }
  }

  .isMetric {
    display: flex;
    flex-flow: column;
    position: relative;

    .tile_content {
      display: grid;
      grid-template-columns: 0.3fr 0.7fr;
    }

    .activation_overview_values {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      padding: 0.5em;

      .value_container {
        margin-top: 1em;
        display: flex;
        flex-flow: column;
        justify-content: space-evenly;
        align-items: center;
      }
    }
  }

  .chart_area {
    padding: 0.5em;
    width: 100%;
  }

  .btn_area {
    margin: 1em auto 0;
  }
}

#akv_activation_ratio {
  .tile_content {
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    text-align: center;

    .comparisons {
      display: flex;
      flex-direction: row;
      margin-bottom: 0;
    }

    @media screen and (min-width: 1024px) {
      gap: 0.75em;
    }
  }

  .warranty_start {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 0.5em;
    width: 100%;

    @media screen and (min-width: 1024px) {
      gap: 0.5em;
    }
  }

  .activation_overview_values {
    margin-top: 0.5em;
    width: 100%;
    padding: 0.5em;

    .value_container {
      margin-top: 2.2em;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      @media screen and (min-width: 1920px) {
        margin-top: 0.2em;
      }

      .comparisons {
        margin-top: 3.2em;
      }
    }
  }

  .chart_area {
    padding: 0.5em;
    width: 100%;
  }

  .btn_area {
    margin: 1em auto 0;
  }

  .warranty_progress {
    width: 4.5em;
    height: 7.5em;
    position: relative;
    display: flex;

    @include themify($themes) {
      background: themed("cssTitleBackground");
    }

    @media screen and (min-width: 1024px) {
      height: 100%;
    }

    .warranty_progress_bar {
      position: absolute;
      width: 100%;
      height: 30%;
      z-index: 2;
      background-color: $cyan;
      bottom: 0;
      display: flex;
    }

    .warranty_progress_label {
      margin: auto;
      z-index: 3;
    }
  }
}

.space-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
  align-items: center;
}
