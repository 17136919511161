@import "../colors";
@import "../effects";
@import "../themes";

.ccs_journey_container {
  display: grid;
  row-gap: 0.75rem;
  padding-bottom: 1rem;

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.75rem;
  }
}

.ccs_journey_section {
  padding: 0.5em 1.5em 1.25em;

  @include themify($themes) {
    padding: 1.25em 1.5em;
    color: themed("primary-font-color");
    background: themed("primary-tile-background");
  }

  font-size: var(--regular_font_size);

  // @media screen and (min-width: 1280px) {
  //   &:first-of-type {
  //     width: calc(4 / 7 * 100%);
  //   }

  //   &:nth-of-type(2) {
  //     width: calc(3 / 7 * 100%);
  //   }
  // }
}

.journey_pyramid_container {
  text-align: center;
  display: grid;
  row-gap: 0.5rem;
}

.journey_pyramid_section {
  width: 100%;

  @media screen and (min-width: 992px) {
    display: flex;
  }

  .module_definition {
    width: 20%;
    position: relative;

    p {
      position: absolute;
      text-align: right;
      right: 0;
      top: 0.5rem;
      font-size: 0.7rem;

      @media screen and (min-width: 2300px) {
        font-size: 1rem;
      }

      @include themify($themes) {
        color: themed("secondary-font-color") !important;
      }

      &.definition_two {
        width: 8rem;

        @media screen and (min-width: 1600px) {
          width: 10rem;
        }
      }

      &.definition_three {
        width: 10rem;

        @media screen and (min-width: 1600px) {
          width: 13rem;
        }

        @media screen and (min-width: 2300px) {
          width: 16rem;
        }
      }

      &.definition_four {
        width: 12rem;

        @media screen and (min-width: 1600px) {
          width: 15rem;
        }

        @media screen and (min-width: 2300px) {
          width: 20rem;
        }
      }

      @media screen and (min-width: 2500px) {
        font-size: 1rem;
      }
    }
  }
}

.journey_pyramid {
  min-height: 130px;
  width: 100%;
  padding: 0.5rem;
  background-color: $cyan;
  position: relative;

  @media screen and (min-width: 1280px) {
    width: 80%;
  }

  @media screen and (min-width: 1920px) {
    min-height: 140px;
  }

  @media screen and (min-width: 2300px) {
    min-height: 200px;
  }

  .title {
    font-size: var(--regular_font_size);

    @media screen and (width: 1280px) {
      font-size: 0.8rem;
    }
  }

  .small_text {
    font-size: 0.7rem;

    @media screen and (min-width: 2300px) {
      font-size: 1rem;
    }
  }

  .main_value {
    font-size: 2rem;
    margin: 0.25em 0;

    @media screen and (min-width: 1280px) {
      font-size: 2.2rem;
    }

    @media screen and (min-width: 2300px) {
      font-size: 3rem;
    }
  }
}

#journey_pyramid_one {
  -webkit-clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
  clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);

  @media screen and (min-width: 666px) {
    -webkit-clip-path: polygon(0 0, 100% 0, 93% 100%, 7% 100%);
    clip-path: polygon(0 0, 100% 0, 93% 100%, 7% 100%);
  }
}

#journey_pyramid_two {
  -webkit-clip-path: polygon(5% 0, 95% 0, 90% 100%, 10% 100%);
  clip-path: polygon(5% 0, 95% 0, 90% 100%, 10% 100%);

  @media screen and (min-width: 666px) {
    -webkit-clip-path: polygon(7% 0, 93% 0, 86% 100%, 14% 100%);
    clip-path: polygon(7% 0, 93% 0, 86% 100%, 14% 100%);
  }
}

#journey_pyramid_three {
  -webkit-clip-path: polygon(10% 0, 90% 0, 85% 100%, 15% 100%);
  clip-path: polygon(10% 0, 90% 0, 85% 100%, 15% 100%);

  .small_text {
    margin: 0 auto;
  }

  @media screen and (min-width: 666px) {
    -webkit-clip-path: polygon(14% 0, 86% 0, 79% 100%, 21% 100%);
    clip-path: polygon(14% 0, 86% 0, 79% 100%, 21% 100%);
  }
}

#journey_pyramid_four {
  -webkit-clip-path: polygon(15% 0, 85% 0, 80% 100%, 20% 100%);
  clip-path: polygon(15% 0, 85% 0, 80% 100%, 20% 100%);

  .small_text {
    // width: 10rem;
    margin: 0 auto;
  }

  @media screen and (min-width: 666px) {
    -webkit-clip-path: polygon(21% 0, 79% 0, 60% 100%, 40% 100%);
    clip-path: polygon(21% 0, 79% 0, 72% 100%, 28% 100%);
  }
}

#post_sales_pyramid {
  height: 140px;
  -webkit-clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
  clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);

  @media screen and (min-width: 666px) {
    -webkit-clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
    clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
  }

  @media screen and (min-width: 1920px) {
    height: 150px;
  }
}

.triangle_section {
  display: flex;

  .module_definition {
    width: 29%;

    p {
      padding-top: 4rem;
    }

    .warranty_start_date_definition {
      margin-top: 7rem;
    }
  }
}

.journey_triangle {
  background-color: $cyan;
  height: 419px;

  @media screen and (min-width: 1350px) {
    height: 407px;
  }

  @media screen and (min-width: 1600px) {
    height: 430px;
  }

  @media screen and (min-width: 1920px) {
    height: 454px;
  }

  @media screen and (min-width: 2300px) {
    height: 615px;
  }

  .values {
    margin-top: 3rem;
  }

  .small_text {
    margin-top: 3rem;
  }
}

#journey_triangle_left {
  -webkit-clip-path: polygon(5% 0, 100% 0, 100% 100%, 70% 100%);
  clip-path: polygon(5% 0, 100% 0, 100% 100%, 70% 100%);
  margin-left: 3%;

  @media screen and (min-width: 666px) {
    width: 40%;
    margin-left: 8%;
  }

  @media screen and (min-width: 1024px) {
    width: 37%;
    margin-left: 7%;
  }

  @media screen and (min-width: 1280px) {
    width: 32%;
  }

  .title {
    text-align: right;
    margin-right: 1rem;
  }

  .main_value,
  .percentage_value {
    margin-left: 3rem;
  }

  .small_text {
    margin-left: 40%;
    text-align: right;
    margin-right: 1rem;

    @media screen and (min-width: 2300px) {
      margin-left: 33%;
    }
  }
}

#journey_triangle_right {
  -webkit-clip-path: polygon(0 0, 100% 0, 30% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 30% 100%, 0 100%);
  margin-left: 0.5rem;
  margin-right: 5%;

  @media screen and (min-width: 666px) {
    width: 40%;
    margin-right: 0;
  }

  @media screen and (min-width: 1024px) {
    width: 37%;
    margin-right: 0;
  }

  @media screen and (min-width: 1280px) {
    width: 32%;
  }

  .title {
    text-align: left;
    margin-left: 1rem;
  }

  .main_value,
  .percentage_value {
    margin-right: 3rem;
  }

  .small_text {
    margin-right: 40%;
    text-align: left;
    margin-left: 1rem;

    @media screen and (min-width: 2300px) {
      margin-right: 33%;
    }
  }
}

.journey_header {
  font-size: var(--regular_font_size);
  padding-top: 0.25em;
  padding-bottom: 1em;

  @include themify($themes) {
    color: themed("primary-font-color") !important;
    // border-bottom: 2px solid themed("primary-font-color");
  }

  display: flex;
  justify-content: space-between;
}

.journey_content {
  padding: 1em 0;

  @media screen and (min-width: 1280px) {
    display: flex;
    gap: 15px;
  }
}

.journey_card {
  border-radius: 10px !important;
  display: flex;
  flex-flow: column;
  padding: 1.2em 0.5em;
  gap: 10px;
  margin-bottom: 1.5em;

  @include themify($themes) {
    border: 1px solid themed("primary-font-color");
    background: themed("ccsContentBackground");
    color: themed("primary-font-color");
  }

  @media screen and (min-width: 1280px) {
    width: 100%;
    text-align: center;
    display: grid;
    grid-template-rows: 4em 4.5em 6.5em auto;
    grid-gap: 10px;
    height: 72vh;
  }

  .title {
    white-space: nowrap;

    @media screen and (min-width: 1280px) {
      white-space: normal;
      // text-align: center;
    }
  }

  .value {
    font-size: 1.75rem;
  }

  .message {
    grid-row: 2 / span 2;
    display: flex;
    align-items: center;
  }

  .progress_content {
    display: flex;
    gap: 10px;

    @media screen and (min-width: 1280px) {
      flex-flow: column;
    }
  }

  .progress_container {
    width: 50%;
    position: relative;

    @media screen and (min-width: 1280px) {
      width: 100%;
    }

    .MuiLinearProgress-root,
    .MuiLinearProgress-bar {
      border-radius: 25px;
    }

    .MuiLinearProgress-root {
      height: 1rem;
      background: $lightKeyIndicatorColor;

      @media screen and (min-width: 1024px) {
        height: 1.5rem;
      }

      @media screen and (min-width: 2300px) {
        height: 2.5rem;
      }
    }

    .MuiLinearProgress-bar {
      background-color: $green;
    }

    &.blue {
      .MuiLinearProgress-bar {
        background-color: $cyan;
      }
    }

    &.grey,
    &.blue,
    &.green {
      .MuiLinearProgress-root {
        background-color: $white;
      }

      .progress_label {
        @include themify($themes) {
          color: themed("primary-font-color");
        }
      }
    }

    .progress_label {
      text-align: center;
      position: absolute;
      top: -0.05rem;
      width: 100%;
      color: $bright_white;
    }
  }

  .target_text {
    width: 50%;
    text-align: center;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }

    @media screen and (min-width: 1280px) {
      width: 100%;
    }

    &.active_text {
      @include themify($themes) {
        color: themed("primary-font-color");
      }
    }
  }

  .module_definition {
    font-size: var(--smaller_regular_font_size);

    @include themify($themes) {
      color: $white;
    }

    @media screen and (min-width: 1280px) {
      text-align: left;
    }
  }

  .ccs_tile_btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiButton-outlined {
      padding: 5px 15px !important;
    }

    @media screen and (min-width: 1280px) {
      align-items: flex-end;
    }
  }
}

.disabled {
  background-color: gray;
}
