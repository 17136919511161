#callCenterChartArea {
  display: flex;
  align-items: center;
  flex-flow: column;

  @media screen and (min-width: 1024px) {
    flex-flow: row;
  }

  .label {
    width: 100%;
    text-align: center;
  }
}

#callCenterChart {
  max-height: 90px;
  @media screen and (min-width: 1280px) {
    max-height: 48px;
  }
}

.no_chart {
  width: 100% !important;
  text-align: center;
}
