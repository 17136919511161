@import "../colors";
@import "../effects";
@import "../themes";

.mtm_dashboard {
  height: auto;
  display: grid;
  grid-template-columns: 1fr;

  h4 {
    font-weight: 300;
  }

  .text_center {
    text-align: center;
  }
}

.mtm_row {
  height: 115vh;
  display: grid;
  grid-template-areas: "title" "container";
  grid-template-rows: 1fr 6fr;
  row-gap: 1em;
  @include themify($themes) {
    color: themed("primary-font-color");
  }
  @media screen and (min-width: 1024px) {
    min-height: 40vh;
  }
}

.mtm_title {
  grid-area: title;
  display: flex;
  flex-direction: column;
  padding: 1.25em 1.5em;
  margin-bottom: 0;
  @include themify($themes) {
    background: themed("primary-tile-background");
    color: themed("secondary-font-color");
  }
  h4 {
    color: $bright_white;
    color: $disabled_white;
    font-weight: normal;
    font-size: var(--header_font);
    margin-bottom: 2%;
  }
}

.mtm_container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 2em;

  @media screen and (min-width: 1024px) {
    grid-template-rows: repeat(2, 1fr);
    gap: 1em;
  }
}

.mtm_metric {
  display: grid;
  grid-template-rows: auto 1fr;
  @include themify($themes) {
    background: themed("primary-tile-background");
    color: themed("secondary-font-color");
  }
  border-radius: 1px;
  font-size: var(--regular_font_size);
  position: relative;
  padding: 0.75em 1em;

  .text_center {
    color: $disabled_white;
    font-weight: normal;
    font-size: var(--header_font);
  }

  .tile_header {
    justify-content: space-between;
  }

  &:hover {
    z-index: 10;
  }
}

.title_descr {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media screen and (min-width: 1024px) {
    div {
      width: 50%;
    }
  }

  @media screen and (max-width: 600px) {
    margin-top: 5%;
  }
}

.investment_share,
.investment_value,
.investment_share_title {
  color: $bright_white;
  @include themify($themes) {
    color: themed("primary-font-color");
  }
}

.investment_value {
  font-size: 1.75rem;
  text-align: start;
}

.investment_share {
  font-size: var(--smaller_regular_font_size);
  text-align: center;
}
.investment_share_title {
  font-size: var(--smaller_regular_font_size);
}
