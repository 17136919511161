@import "../colors";
@import "../effects";
@import "../themes";

.msrp-page-container {
  .row {
    display: grid;
    grid-template-columns: calc(50% - 0.375rem) calc(50% - 0.375rem);
    min-height: 19rem;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .summary-row {
    display: grid;
    grid-template-columns: calc(34% - 0.375rem) calc(66% - 0.375rem);
    min-height: 19rem;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;

    .summary-tiles {
      display: grid;
    }
  }

  .full-width-row {
    display: grid;
    grid-template-columns: 100%;
    min-height: 19rem;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .metric-tiles-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
  }

  .msrp-tile {
    padding: 0.8em;
    font-size: var(--regular_font_size);
    display: flex;
    flex-direction: column;
    position: relative;
    @include themify($themes) {
      background: themed("primary-tile-background");
      color: themed("primary-font-color");
    }
  }

  .tile-header {
    font-size: var(--header_font);
    display: flex;
  }

  .over-time-content {
    display: flex;
    height: 100%;
  }

  .line-indicators-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;

    .line-indicator {
      display: flex;
    }
  }

  .metric-tile {
    display: flex;
    justify-content: space-between;

    .yoy-value {
      align-self: center;
      display: flex;
      align-items: center;
      font-size: var(--rating_small);
    }

    .value {
      align-self: center;
      font-size: var(--info-header);
    }

    .label {
      margin-left: 0.3rem;
    }
  }

  .mrsp-model-comparison {
    display: flex;
    gap: 1rem;
    .model-comparison-charts {
      flex: 1;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2rem;
      font-size: var(--filter_font);
      .chart-tile {
        display: flex;
        flex-direction: column;
        position: relative;
      }
    }
  }

  .trend-legend {
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    font-size: var(--chart_small);
    flex-wrap: wrap;
    justify-content: space-around;

    .legend-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      .legend-box {
        width: 0.8rem;
        height: 0.8rem;
        margin-right: 0.5em;
        border-radius: 0.2em;
      }
    }
  }

  .LegendMarker {
    @include themify($themes) {
      border-color: $cyan;
    }
  }

  #msrpModelPerformance {
    margin-top: 1rem;
    th {
      background-color: unset;
    }
  }

  .model-performance-cell {
    display: flex;

    .arrow-icon {
      width: 22px;
      height: 22px;
    }

    .arrow-icon.green {
      @include themify($themes) {
        color: $green_highlight;
      }
    }

    .arrow-icon.amber {
      @include themify($themes) {
        color: $amber;
      }
    }

    .arrow-icon.red {
      @include themify($themes) {
        color: $charts_red;
      }
    }

    .value {
      margin: auto;
    }
  }

  .in-app-filter {
    margin-left: auto;
    font-size: var(--regular_font_size);
  }

  .total-added-value {
    margin-bottom: 4px;
    padding: 0.4em 0.8em;
    display: flex;
    flex-direction: row;
    gap: 50px;

    .value {
      font-size: var(--small_number_metric);
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .note {
    font-size: var(--form_font);
  }
}
