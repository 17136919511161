@import "../colors";
@import "../effects";
@import "../themes";

.ccs-monetization-layout {
  @include themify($themes) {
    color: themed("primary-font-color") !important;
  }

  .row-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .row-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .row-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-bottom: 2rem;
  }

  .column-2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .metric-tile-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .metric-tile {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    padding: 0.5rem;

    @include themify($themes) {
      border: 1px solid themed("primary-font-color");
      background: themed("ccsContentBackground");
    }

    .tile-header {
      font-size: var(--header_font);
      display: flex;
      text-align: center;
    }

    .tile-content {
      padding: 0.8rem 0;
      font-size: var(--small_number);
      text-align: center;
      margin: auto;
    }
  }

  .tile {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
    }

    padding: 0.5em;
    border-radius: 0;
    border: none;
    cursor: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;

    .tile-header {
      font-size: var(--header_font);
      display: flex;
      width: 100%;
      justify-content: space-between;

      .title {
        display: flex;
      }

      .toggle_container {
        margin-left: auto;
      }
    }

    .tile-content {
      display: flex;
      flex-flow: column;
      align-items: center;
      width: 100%;
      height: 100%;
      min-height: 300px;
    }
  }

  .breakdown-tile {
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 0.5rem;
    min-height: 25rem;
    margin-bottom: 2rem;

    @include themify($themes) {
      border: 1px solid themed("primary-font-color");
      background: themed("ccsContentBackground");
    }

    .tile-header {
      font-size: var(--header_font);
      text-align: center;
      writing-mode: vertical-lr;
      transform: scale(-1, -1);
    }

    .tile-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
    }
  }

  .chart-tile {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: 100%;

    .tile-header {
      font-size: var(--header_font);
      display: flex;
      justify-content: center;
      writing-mode: unset;
      transform: unset;
      width: 100%;
    }

    .tile-content {
      display: flex;
      padding: 0.8rem 0;
      height: 100%;
    }
  }

  .previous-period {
    display: flex;
    font-size: var(--rating_small);
    justify-content: center;
    align-items: center;
  }

  .cust-service-pie-chart {
    grid-column: span 1;
  }

  .cust-service-table-container {
    grid-column: span 2;
  }

  .table-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
