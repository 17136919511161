@import "../../../../components/Layouts/Ariya/styles/colors";
@import "../../../../components/Layouts/Ariya/styles/scroll_bar";

.filterContainer {
  position: relative;
  display: flex;
  gap: 2em;
}

.filterItem {
  font-size: 0.875rem;
  padding: 0.85em 4.5em 0.85em 0;
  border-right: 1px solid $grey;
  cursor: pointer;
  font-weight: normal;

  &:last-of-type {
    border-right: none;
  }
}

.filterItemActive {
  composes: filterItem;
  color: $accent_brown;
}
