@import "../colors";
@import "../themes";

.MuiButtonBase-root {
  &.hide_on_mobile {
    display: none;

    @media screen and (min-width: 1024px) {
      display: block;
    }
  }
}

.MuiButton-outlined {
  border: 2.5px solid $cyan !important;
  border-radius: 7.5px !important;
  padding: 5px 2em !important;

  &:hover {
    border: 2.5px solid $cyan_bright !important;
  }
}

.MuiButton-root {
  color: $cyan !important;
  border-radius: 7.5px !important;
  text-transform: initial !important;

  &:hover {
    color: $cyan !important;
    border: 2.5px solid $cyan !important;
    background-color: $cyan !important;
    @include themify($themes) {
      color: themed("primary-font-color") !important;
    }
  }
}

.MuiCircularProgress-colorPrimary {
  color: $cyan !important;
}

input[type="submit"] {
  background-color: $cyan;
  border-color: $cyan;
  padding: 0.5em 2.5em;

  &:hover {
    background-color: $cyan_bright;
  }
}

input[disabled] {
  pointer-events: none;
  background-color: $white !important;
  border-color: $white !important;
}

.button_area {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  .submit_btn {
    cursor: pointer;
    margin-bottom: 4em;
    &.disabled {
      cursor: none;
      pointer-events: none;
      background-color: $grey;
      color: $white;
      border: 1.5px solid $white;
    }
  }
}
.geographyOptionBtn,
.colorOptionBtn {
  padding: 1px 2.5px !important;
  @include themify($themes) {
    color: themed("grey_transparent") !important;
    border: 1px solid themed("grey_transparent") !important;
  }

  border-radius: 0 !important;
  z-index: 1;
  font-size: 0.75rem !important;
  margin: auto 0 !important;
  height: fit-content;
  font-family: "nissan", "Open Sans", sans-serif !important;

  @media screen and (min-width: 1024px) {
    min-width: 50px !important;
  }

  &:hover {
    background-color: transparentize($grey_transparent, 0.65) !important;
    border: 1px solid $grey_transparent !important;
  }

  .MuiButton-label {
    line-height: 1.2rem;
  }
}
