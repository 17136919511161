@import "themes";

@mixin scroll-bar {
  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    @include themify($themes) {
      background-color: themed("scrollBackground");
    }
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @include themify($themes) {
      background: themed("scrollThumbColor");
    }
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    @include themify($themes) {
      background: themed("scrollThumbHover");
    }
  }
}

@mixin filter-scroll-bar {
  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.25em;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    @include themify($themes) {
      background-color: themed("filterScrollBackground");
    }
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @include themify($themes) {
      background: themed("scrollThumbColor");
    }
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    @include themify($themes) {
      background: themed("scrollThumbHover");
    }
  }
}

@mixin no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@mixin table_sort {
  outline: none !important;

  span {
    text-transform: initial;
  }

  &:focus {
    outline: none;
  }

  &.sorting,
  &.sorting_desc,
  &.sorting_asc {
    cursor: pointer;
  }

  &.sorting_asc::after {
    content: "";
    @include themify($themes) {
      background-image: themed("tableSortAsc");
    }
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: 0.5em;
    width: 5em;
    height: 100%;
    margin-left: 0.25em;
  }

  &.sorting::after {
    content: "";
    @include themify($themes) {
      background-image: themed("tableSortUnsorted");
    }
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: 0.5em;
    width: 5em;
    height: 100%;
    margin-left: 0.25em;
  }

  &.sorting_desc::after {
    content: "";
    @include themify($themes) {
      background-image: themed("tableSortDesc");
    }
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: 0.5em;
    width: 5em;
    height: 100%;
    margin-left: 0.25em;
  }
}

@mixin bounce-animation {
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes bounce {
    0% {
      -webkit-transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(-15px);
    }
    100% {
      -webkit-transform: translateY(0);
    }
  }

  @keyframes bounce-hover {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-12.5px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes bounce-hover {
    0% {
      -webkit-transform: translateY(0);
    }
    50% {
      -webkit-transform: translateY(-12.5px);
    }
    100% {
      -webkit-transform: translateY(0);
    }
  }
}
