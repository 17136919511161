@import "../colors";

.filter_btn {
  &.hide_on_mobile {
    display: none;

    @media screen and (min-width: 1024px) {
      display: flex;
      // justify-content: center;
      padding: 0;
      border-right: 0;
      margin-left: 1em;
    }
  }
  border: none !important;
}

.filter_btn_element {
  border: 2.5px solid $cyan;
  padding: 0.5em 1em;
  color: $cyan;
  border-radius: 7.5px;
  margin: auto;
  font-size: var(--form_font);
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: $cyan_bright;
    border-color: $cyan_bright;
  }

  @media screen and (max-width: 1500px) {
    font-size: 0.7rem !important;
  }
}

.filter_btn_element.active {
  color: $bright_white !important;
  border-color: $cyan !important;
  background-color: $cyan !important;
}

.back_filter_arrow {
  background: url("../../images/arrows/left-arrow.svg");
  width: 1rem;
  height: 1rem;
  display: inline-block;
  background-repeat: no-repeat;
  margin-right: 0.5rem;
}
