@import "./colors";

/*
 * Theme definitions
 */
$themes: (
  dark: (
    backgroundColor: #0f0f0f,
    primaryFontColor: $white,
    secondaryFontColor: rgba($white, 0.75),
    tableOddBackground: rgba(128, 128, 128, 0.2),
    tableSortAsc: url("../img/dark-theme/asc.svg"),
    tableSortDesc: url("../img/dark-theme/desc.svg"),
    tableSortUnsorted: url("../img/dark-theme/unsorted.svg"),
    primary-font-color: $white,
    menu-background: $dark_blue,
    filter_font_color: $cyan,
    accent_color_hover: $cyan_bright,
    borderColor: $borderGrey,
  ),
  light: (
    backgroundColor: $white,
    primaryFontColor: rgb(0, 0, 0),
    secondaryFontColor: #626262,
    tableOddBackground: $lightBackgroundGrey,
    tableSortAsc: url("../img/light-theme/asc.svg"),
    tableSortDesc: url("../img/light-theme/desc.svg"),
    tableSortUnsorted: url("../img/light-theme/unsorted.svg"),
    primary-font-color: $lightKeyIndicatorColor,
    menu-background: $white,
    filter_font_color: $white,
    accent_color_hover: $lightThemeAccentColor,
    borderColor: rgba($black, 0.75),
  ),
);
/*
  * Implementation of themes
 */
@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
