@import "../colors";
@import "../effects";
@import "../themes";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

body {
  @media screen and (min-width: 1024px) {
    overflow: hidden;
    background-position: center;
  }
}

.pageWrapper#loginPage {
  background-image: url("../../images/ARIYA-bg.webp");
  height: var(--app-height);
  background-position-x: 12.5%;

  @media not all and (hover: hover) {
    height: var(--app-height);
  }
}

h3 {
  font-size: calc(var(--header_font) + 2px);
  text-align: center;
}

.center {
  text-align: center;
}

.form_body {
  display: flex;
  flex-flow: column;
  padding: 1.5em 2em;

  border: 1px solid rgba($grey, 0.95);
  border-radius: 10px;

  background-color: rgba($grey, 0.95);
  box-shadow: 3px 3px 12px 1px rgba($grey, 0.95);
  color: $bright_white;
  width: 100%;
  margin: auto;
  font-size: var(--regular_font_size);

  @media screen and (min-width: 650px) {
    max-width: 520px;
  }

  @media screen and (min-width: 1024px) {
    width: 100%;
    padding: 0.5em 2em;
    margin-right: 5em;
    max-width: 350px;
  }

  @media screen and (min-width: 1280px) {
    max-width: 520px;
    padding: 1.5em 2.5em;
  }

  @media screen and (min-width: 1350px) {
    max-width: 470px;
  }

  @media screen and (min-width: 1600px) {
    max-width: 27.8em;
  }
}

.header_section {
  display: flex;
  justify-content: center;
  border-bottom: 1.5px solid $bright_white;
  padding: 1.5em 0;

  @media screen and (min-width: 1024px) {
    padding: 1em 0;
  }

  @media screen and (min-width: 1280px) {
    padding: 1.5em 0 2em;
  }
}

.error {
  color: red;
  text-align: center;
  margin-top: 0;
}

.login_form {
  padding: 1.5em 0;
  width: auto;

  @media screen and (min-width: 1024px) {
    padding: 0.5em 0;
  }

  @media screen and (min-width: 1280px) {
    padding: 1.5em 0;
  }
}

.error_form {
  display: flex;
  flex-flow: column;
  padding: 1.5em 0 1em;

  a {
    color: $cyan;
    text-decoration: none;

    &:hover {
      color: $cyan_bright;
    }
  }

  h3 {
    margin-bottom: 1em;
  }
}

.text_area {
  display: flex;
  flex-flow: column;
}

.form_header_text {
  margin-bottom: 2.5em;
  @media screen and (min-width: 1024px) {
    margin-bottom: 2em;
    margin-top: 1.5em;
  }
}

.input_field {
  display: flex;
  flex-flow: column;
  margin-bottom: 2em;

  @media screen and (min-width: 1024px) {
    margin-bottom: 1.5em;
  }
}

.field {
  display: flex;

  input {
    width: 100%;
  }
  .dropdown {
    border: solid 1.5px $cyan;
  }
}
.theme-button {
  @include themify($themes) {
    color: themed("themeButtonColor");
  }
}

label {
  margin-bottom: 0.5em;
}

input {
  border: 1.5px solid $cyan;
  border-radius: 5em;
  background-color: transparent;
  padding: 0.5em;
  color: $bright_white;

  font-size: var(--regular_font_size);

  &:focus,
  &:active {
    outline: none;
    border-color: $cyan_bright;
  }
}

input[type="submit"] {
  background-color: $cyan;
  padding: 0.5em 2.5em;
  cursor: pointer;

  &:hover {
    background-color: $cyan_bright;
  }
}
.sign_up_input {
  border-color: $cyan;
}

.button_area {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;

  .submit_btn {
    cursor: pointer;
    margin-bottom: 4em;
    &.disabled {
      cursor: none;
      pointer-events: none;
      background-color: $grey;
      color: $white;
      border: 1.5px solid $white;
    }

    @media screen and (min-width: 1024px) {
      margin-bottom: 1.5em;
    }
  }
}

.links {
  color: $cyan;
  text-decoration: none;
  font-size: var(--regular_font_size); //font-size: calc(var(--regular_font_size) - 0.15rem);
  text-align: center;
  cursor: pointer;

  &:hover {
    color: $cyan_bright;
  }

  &:active,
  &:focus {
    color: $cyan;
  }

  &.back_button {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    span {
      background-image: url("../../images/arrows/left-arrow.svg");
      width: 1rem;
      height: 1rem;
      display: inline-block;
      background-repeat: no-repeat;
      margin-right: 0.5rem;
    }
  }
}

#IESupportMessage {
  position: fixed;
  top: 1%;
}
.pageWrapper#loginPage {
  #container {
    height: 100%;
    display: flex;
    padding: 0.5em;
  }
}

#login_dashboard {
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "body";
  height: 100vh;

  #container {
    display: grid;
  }
}

.sign_up_form {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-style: italic;
    color: #a4a4a4;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-style: italic;
    color: #a4a4a4;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-style: italic;
    color: #a4a4a4;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-style: italic;
    color: #a4a4a4;
  }
  .row {
    display: flex;
    justify-content: space-between;

    .input_field {
      width: 47%;
      position: relative;
    }

    .model_region_list {
      margin: 0.2em 0 0;
      padding: 0 0 0 1rem;
      display: none;
      &.show-regions {
        display: block;
      }
    }
    .region_country_filter {
      margin: 0.2em 0 0;
      padding: 0 0 0 1rem;
      display: none;
      &.show-markets {
        display: block;
      }
    }

    #geography_options {
      overflow-y: auto;
      @include scroll-bar;
    }

    #businessFunction_options {
      max-height: 10em;
      overflow-y: auto;
      overflow-x: hidden;
      @include scroll-bar;
    }
  }
}

.success_container {
  margin-bottom: 4em;

  @media screen and (min-width: 1024px) {
    margin-bottom: 1.5em;
  }

  p {
    text-align: center;
    span {
      color: $cyan;
    }
  }
}

#set_password_form_body {
  @media screen and (min-width: 1024px) {
    max-width: 415px;
  }

  @media screen and (min-width: 1280px) {
    max-width: 600px;
  }

  @media screen and (min-width: 1350px) {
    max-width: 490px;
  }

  @media screen and (min-width: 1600px) {
    max-width: 35em;
  }
}
