@import "../colors";
@import "../effects";
@import "../themes";

.dmeo-layout {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  font-size: var(--regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color") !important;
  }

  .title-menu {
    min-height: 80vh;
    display: grid;
    gap: 10px;
    grid-template-rows: 1.5fr 4fr;

    .section-title {
      height: 100%;

      @include themify($themes) {
        background: themed("cssTitleBackground");
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 5px;
      width: 100%;
      height: 100%;

      @media screen and (min-width: 1280px) {
        margin-bottom: 0;
        width: 8.5em;
        padding: 2.25em 0.5em;

        &:hover,
        &.active {
          background-color: $cyan;
        }
      }

      .title {
        @include themify($themes) {
          color: themed("ccsTitleFontColor") !important;
        }
      }
    }
  }

  .dmeo-row {
    display: flex;
    flex-flow: row;
  }

  .dmeo-main-content {
    display: grid;
    gap: 10px;
    grid-template-rows: auto;

    .tile {
      border: 1px solid $bright_white;

      @include themify($themes) {
        border: 1px solid themed("primary-font-color");
        background: themed("ccsContentBackground");
      }

      padding: 0.5em;
      border-radius: 5px;
      cursor: auto;
      display: flex;
      flex-flow: column;
      align-items: center;

      .tile-header {
        display: flex;
        margin-bottom: 0.5rem;
      }

      .tile-content {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 100%;
        height: 100%;

        .main-value {
          font-size: var(--small_number_metric);
        }

        .sub-text {
          display: flex;
          align-items: center;
          font-size: var(--header_font);
        }
      }
    }

    .digital-marketing-spend-tiles {
      display: grid;
      grid-template-columns: 1fr 2fr 2fr;
      min-height: 50vh;
      gap: 10px;

      .digital-spend-tile {
        .tile-content {
          height: 100%;
          justify-content: center;

          .main-value {
            margin-top: auto;
          }

          .first-sub-text {
            margin-top: auto !important;
          }
        }
      }
    }

    .campaign-performance-tiles {
      display: grid;
      grid-template-rows: auto;
      gap: 10px;

      .leads-kbas-row {
        display: grid;
        grid-template-columns: 1.2fr 0.8fr 1.33fr;
        min-height: 50vh;
        gap: 10px;

        .visits-tile {
          align-items: start;

          .tile-header {
            font-weight: 900;
            font-size: var(--header_font);
          }

          .tile-content {
            display: grid;
            grid-template-columns: 0.8fr 1.2fr;
            height: 100%;

            .chart-column {
              height: 100%;
            }
          }
        }

        .kbas-leads-container {
          display: grid;
          grid-template-rows: 1fr 1fr;
          gap: 10px;

          .kbas-leads-tile {
            align-items: start;

            .tile-header {
              font-weight: 900;
              font-size: var(--header_font);
            }

            .tile-content {
              flex-direction: row;
              justify-content: space-around;
            }
          }
        }

        .cost-per-aquisition-tile {
          .tile-header {
            width: 100%;
            justify-content: space-between;

            .title {
              margin-left: 32%;
              display: flex;
            }

            .toggle_container {
              margin-left: auto;
            }
          }

          .tile-content {
            height: 100%;
          }
        }
      }

      .conversion-rate-row {
        display: grid;
        grid-template-columns: 1.53fr 1fr;
        gap: 10px;
        min-height: 50vh;

        .conversion-rate-tile {
          .tile-header {
            width: 100%;
            justify-content: space-between;

            .title {
              margin-left: 42%;
            }

            .toggle_container {
              margin-left: auto;
            }
          }

          .tile-content {
            display: grid;
            grid-template-columns: 1fr 3fr;
            height: 100%;

            .metric-container {
              display: flex;
              flex-direction: column;
              height: 100%;
              width: 100%;
              align-items: center;
              justify-content: center;

              .title {
                display: flex;
                align-items: center;
              }

              .metric-value {
                font-size: var(--small_number_metric);
              }
            }

            .chart-containter {
              height: 100%;
            }
          }
        }

        td {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .table-cell {
          display: flex;
          align-items: center;
          word-break: break-all;
        }
      }
    }
  }

  .digital-marketing-spend-content {
    display: grid;
    grid-template-rows: auto;
    gap: 10px;

    .digital-arketing-spend-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      min-height: 50vh;

      .tile {
        border: 1px solid $bright_white;

        @include themify($themes) {
          border: 1px solid themed("primary-font-color");
          background: themed("ccsContentBackground");
        }

        padding: 0.5em;
        border-radius: 5px;
        cursor: auto;
        display: flex;
        flex-flow: column;
        align-items: center;

        .tile-header {
          display: flex;
          margin-bottom: 0.5rem;
        }

        .tile-content {
          display: flex;
          flex-flow: column;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
    }

    .top-10-campain-table {
      border-collapse: collapse;

      th,
      td {
        border: 1px solid;
      }
    }
  }

  .campaign-performance-content {
    .row-2 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      // temporarily removing this height as it causes the graph to stretch when the table below it has too much data
      // height: 50%;
      min-height: 60vh;
      gap: 10px;
      margin-bottom: 10px;
    }

    .row-2.campaign-performance-grid {
      grid-template-columns: 1fr 3fr;
    }

    .tile {
      @include themify($themes) {
        border: 1px solid themed("primary-font-color");
        background: themed("ccsContentBackground");
      }

      padding: 0.5em;
      border-radius: 5px;
      cursor: auto;
      display: flex;
      flex-flow: column;
      align-items: center;

      .tile-header {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .title {
          margin-left: 38%;
        }

        .toggle_container {
          margin-left: auto;
        }
      }

      .tile-content {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 100%;
        height: 100%;
      }
    }

    .performance-as-per-objective-table {
      // width: 80%;
      table-layout: fixed;

      tr {
        td:nth-of-type(1) {
          width: 30%;
        }

        td:nth-of-type(2) {
          width: 40%;
        }

        td:nth-of-type(3) {
          width: 30%;
        }

        .comparison {
          display: flex;
          width: 100%;
          align-items: center;
        }
      }

      td {
        word-wrap: break-word;
      }
    }

    .campaign-performance-details-table {
      border-collapse: collapse;

      td,
      th {
        @include themify($themes) {
          border: 1px solid themed("primary-font-color");
        }

        padding: 0.5em;
      }

      .cell-data {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
      }

      .cell-data {
        .cell-data-campaign-name {
          word-break: break-all;
          // max-width: 12rem;
        }
      }
    }
  }
}

.dmeo-landing-page {
  display: grid;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  gap: 10px;
  font-size: var(--regular_font_size);
  min-height: 82vh;

  @include themify($themes) {
    color: themed("primary-font-color") !important;
  }

  .tile {
    border: 1px solid $bright_white;

    @include themify($themes) {
      border: 1px solid themed("primary-font-color");
      background: themed("ccsContentBackground");
    }

    padding: 0.5em;
    border-radius: 5px;
    cursor: auto;
    display: flex;
    flex-flow: column;

    .tile-header {
      display: flex;
      margin-bottom: 0.5rem;
      justify-content: space-between;
    }

    .tile-content {
      display: flex;
      flex-flow: column;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }

  .landing-page-performance-tile {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    align-items: unset !important;

    .metric-chart-title-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .metric-chart-tile {
        display: grid;
        grid-template-columns: 1fr 3fr;
      }

      .metric-chart-tile.conversion-rate-tile {
        margin-bottom: 1.5rem;
      }

      .metric-chart-tile.bounce-rate-tile {
        margin-top: 1.5rem;
      }

      .metric {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .rating {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .chartWrapper,
    .graph {
      min-height: unset;
    }
  }

  .search_container {
    align-self: baseline;
  }

  .landing-page-table {
    border-collapse: collapse;

    td,
    th {
      @include themify($themes) {
        border: 1px solid themed("primary-font-color");
      }

      padding: 0.5em;
    }

    tr {
      td:nth-of-type(1) {
        div:nth-of-type(1) {
          display: flex;
          gap: 5px;
          align-items: center;
        }
      }
    }
  }
}
