@import "../colors";
@import "../effects";
@import "../themes";

.top_flop_sotu {
  margin-bottom: 10rem;

  @media screen and (min-width: 1280px) {
    margin-bottom: 0;
  }

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  max-width: calc(100vw - 4em);

  .all_services {
    padding-top: 0;
    .header_div {
      height: 1.25em;
      width: 100%;
    }
  }
  .top_flop_header {
    position: sticky;
    top: 25px;
    @include themify($themes) {
      background-color: themed("primary-tile-background") !important;
    }
  }
  .car_services_usage_overview {
    @include themify($themes) {
      background-color: themed("primary-tile-background") !important;
    }
  }

  h3 {
    font-size: var(--header_font);
    font-weight: 300;
    margin-bottom: 0.25em;
    @include themify($themes) {
      color: themed("secondary-font-color");
    }
    text-align: left;
  }
}

.top_flop_chart_container {
  background-color: $lightKeyIndicatorColor;
  width: 100%;
}

.services_tile {
  display: grid;
  column-gap: 0.75rem;
  grid-template-areas:
    "all_services "
    "services_charts";
  grid-template-rows: max-content max-content;
}

.service_group_region_tile {
  display: grid;
  grid-gap: 0.75rem;
  grid-template-areas:
    "service_groups_chart"
    "regions_chart";
  grid-template-rows: max-content max-content;
  margin-top: 1.29vh;
}

.all_services,
.top_services,
.flop_services,
.service_groups_chart,
.regions_chart,
.top_models {
  padding: 1.25em 1.5em;
  @include themify($themes) {
    background: themed("primary-tile-background");
  }
  position: relative;
}

.all_services {
  @media screen and (min-width: 1250px) {
    min-height: 120vh;
  }
  max-height: 120vh;
  overflow: auto;
  @include scroll-bar;
}

.top_services,
.flop_services {
  height: 32.62%;
  margin-bottom: 1.29vh;
  .main_content {
    height: 90%;
  }
}

.top_models {
  height: 32.62%;
  .main_content {
    height: 90%;
  }
}

.service_groups_chart,
.regions_chart {
  .main_content {
    height: 90%;
  }
  padding-bottom: 3em !important;
  min-height: 40vh;
}

th.sortable.Service.name,
th.sortable.On\/Off.board,
td.Service.name,
td.On\/Off.board {
  text-align: left;
}

th.sortable.Total.events,
td.Total.events {
  text-align: right;
}

@media screen and (min-width: 1250px) {
  .services_tile {
    grid-template-columns: calc(42% - 0.375rem) calc(58% - 0.375rem);
    grid-template-areas: "all_services services_charts";
  }

  .service_group_region_tile {
    grid-template-columns: calc(42% - 0.375rem) calc(58% - 0.375rem);
    grid-template-areas: "service_groups_chart regions_chart";
  }
}

.inactive {
  color: $header-dark-grey !important;
  cursor: alias;
}
