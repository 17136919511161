@import "../colors";
@import "../effects";
@import "../themes";

.dp_sotu {
  display: grid;
  grid-gap: 0.75rem;
  grid-template-areas:
    "key_indicator"
    "baseline_trend"
    "visits_trend"
    "metric_tiles";
  grid-template-rows: max-content max-content max-content max-content;
  margin-bottom: 10rem;

  @media screen and (min-width: 1280px) {
    margin-bottom: 0;
  }

  @include themify($themes) {
    color: themed("primary-font-color");
  }
  .breakdown_header {
    position: sticky;
    top: 25px;
    @include themify($themes) {
      background-color: themed("primary-tile-background") !important;
    }
  }
  .metric_title {
    position: sticky;
    top: 0;
    @include themify($themes) {
      background-color: themed("primary-tile-background") !important;
    }
    z-index: 999;
  }
  max-width: calc(100vw - 4em);

  h3 {
    font-size: var(--header_font);
    font-weight: 300;
    margin-bottom: 0.25em;
    @include themify($themes) {
      color: themed("secondary-font-color");
    }
    text-align: left;
  }

  .period_updatable {
    white-space: nowrap;
  }
}

.baseline_trend,
.key_indicators,
.visits_trend,
.model_trend,
.metric_tiles {
  padding: 1.25em 1.5em;
  @include themify($themes) {
    background: themed("primary-tile-background");
  }
}

.baseline_trend {
  grid-area: baseline_trend;
  .header {
    display: flex;
    justify-content: space-between;
  }
  z-index: 100;
}

.insight_btn_element {
  border: 2.5px solid $cyan;
  color: $cyan;
  border-radius: 7.5px;
  margin: auto;
  padding: 0px;
  font-size: var(--form_font);
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: $cyan_bright;
    border-color: $cyan_bright;
  }
}

.key_indicators {
  grid-area: key_indicator;
  position: relative;
}

.visits_trend {
  grid-area: visits_trend;
}

.model_trend {
  grid-area: metric_tiles;
}

.metric_tiles {
  display: none;
  grid-area: metric_tiles;

  .metricsActive {
    display: flex;
  }
}

.show_metric {
  .metric_tiles {
    display: flex;
    flex-flow: column;
  }
}

.visits_trend,
.model_trend,
.metric_tiles {
  position: relative;

  .graphArea,
  .tableArea {
    display: flex;
    flex-flow: column;

    .main_content {
      display: flex;
    }
  }

  .graphArea {
    margin-bottom: 2.5em;

    .main_content {
      padding-bottom: 0.5em;
    }
  }
}
.chartWrapper,
.graph {
  height: 100%;
  width: 100%;
  min-height: 250px;
}

.chartWrapper {
  display: flex;
  flex-flow: column;
}

.graph {
  width: 100%;
}

.graphArea,
.tableArea {
  position: relative;

  .main_content {
    position: relative;
  }
}

#visits_trend_graph {
  .chartWrapper,
  .graph {
    min-height: 350px;
  }
}

@media screen and (min-width: 1250px) {
  .dp_sotu {
    grid-template-columns: calc(60% - 0.375rem) calc(40% - 0.375rem);
    grid-template-areas:
      "baseline_trend key_indicator"
      "visits_trend visits_trend"
      "metric_tiles metric_tiles";
    grid-template-rows: minmax(auto, 37vh) minmax(auto, 37vh) minmax(auto, 37vh);
    margin-bottom: 5em;

    &.show_metric {
      grid-template-rows: minmax(auto, 37vh) minmax(auto, 37vh) minmax(auto, 42.5vh);
      margin-bottom: 5em;
    }
  }

  .baseline_trend,
  .key_indicators,
  .visits_trend,
  .model_trend,
  .metric_tiles {
    padding: 1.25em 1.5em;
    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;
  }

  .visits_trend,
  .model_trend,
  .metric_tiles {
    flex-flow: row !important;

    .graphArea,
    .tableArea {
      display: flex;
      flex-flow: column;
    }

    .graphArea {
      width: 60%;
      @include themify($themes) {
        border-right: themed("thinBorder");
      }
      // border-right: $thin_border;
      margin-bottom: unset;
    }

    .tableArea {
      width: 40%;
      padding: 0 1.75em;
      overflow-y: auto;
      display: grid;
      grid-template-areas: "header" "body";
      grid-template-rows: auto 1fr;

      @include scroll-bar;
      h3,
      .info_tooltip {
        display: block;
        margin-right: 0.1rem;
      }

      .header {
        display: flex;
        align-items: center;
      }
    }
  }

  .chartWrapper,
  .graph {
    min-height: unset !important;
  }

  #visits_trend_graph {
    .main_content {
      height: 100%;
    }
    z-index: 1;
  }
}

#model_trend_table {
  height: 100%;
  width: 40%;
  border: none;
  .main_content {
    display: grid;
    grid-template-rows: auto 2fr 1fr;
    grid-template-areas: "headr" "main" "comparison";
    position: relative;

    width: 33%;
    padding: 0.85em 0.5em;
    @include themify($themes) {
      border: themed("thinBorder");
    }
    h4 {
      grid-area: headr;
    }

    .main {
      font-size: 1.75rem;
      margin: 0.25em;
      align-self: center;
      grid-area: main;
    }

    .comparison_values {
      display: flex;
      flex-flow: column;
      margin: 0.5em;
      text-align: left;
      justify-content: flex-end;
      grid-area: comparison;

      p {
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        font-size: var(--regular_font_size);
      }
    }
  }
}
