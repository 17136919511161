@import "../colors";
@import "../themes";

.model_performance_variancet {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.by_car_model_table {
  @include themify($themes) {
    color: themed("primary-font-color");
  }

  td {
    @include themify($themes) {
      border: 1px solid themed("primary-font-color");
    }
  }
  width: 100%;
  border-collapse: collapse;
  .top_inc_yoy_tb1 {
    width: inherit !important;

    .top_inc_yoy_tr1 {
      width: inherit !important;

      .top_inc_yoy_td {
        padding: 0 !important;

        .top_inc_yoy {
          width: 100% !important;
          display: flex;
          flex-flow: column;
          .top_inc_yoy_tr {
            width: inherit !important;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            td {
              border-right: none;
              border-top: none;
            }

            td:first-of-type {
              border-left: none;
            }
          }
          .top_inc_yoy_tr:last-of-type {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
  .inc_titles {
    background-color: green;
  }
  .dec_titles {
    background-color: red;
  }
  .sec_title {
    @include themify($themes) {
      border: 1px solid themed("primary-font-color");
    }
    .col_heads {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .col_head {
        @include themify($themes) {
          border-top: 1px solid themed("primary-font-color");
          border-left: 1px solid themed("primary-font-color");
        }
      }
      .col_head:first-of-type {
        border-left: none;
      }
    }
  }

  .rank_head {
    @include themify($themes) {
      border-left: 1px solid themed("primary-font-color");
    }
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .ranks {
    border: none;
    td {
      display: flex;
      width: 100%;
      border-right: none;
      border-top: none;
      border-left: none;
      justify-content: center;
    }
  }

  .ranks:last-of-type {
    td {
      border-bottom: none;
    }
  }

  //   tr,
  //   td,
  //   th {
  //     font-size: var(--popup_font);
  //     @include themify($themes) {
  //       border: 1px solid themed("primary-font-color");
  //     }
  //     border-collapse: collapse;
  //   }

  //   td,
  //   th {
  //     padding: 0.25em 1em !important;
  //     text-align: left;
  //   }

  //   th {
  //     font-weight: bold;
  //   }
}

.by_car_model_table {
  thead:first-of-type {
    @include themify($themes) {
      border-top: 1px solid themed("primary-font-color");
    }
  }
}

.table_container:nth-of-type(2) {
  margin-left: 10px;
}
