@import "../colors";
@import "../effects";
@import "../themes";

.filterContainer {
  position: relative;
  @media screen and (min-width: 1024px) {
    max-width: 17.5pc;
  }
}

.filterInputContainer {
  display: flex;
  flex-flow: column;
  padding: 0.5em;
  border: 1px solid $lowlight_grey;
}

.filterTitle {
  color: $lightGrey;
  font-size: 0.8rem;
}

.inputWithArrow {
  width: 100%;
  background: none;
  border: none;
  padding: 2px 25px;
  padding-bottom: 0;
  cursor: pointer;
  padding-left: 0.15em;
  margin-top: 0.25em;
}

.filterOptions {
  display: none;
  list-style-type: none;
  position: absolute;
  width: 100%;
  z-index: 2;
  max-height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
  @include scroll-bar;
  @include themify($themes) {
    background-color: themed("primary-font-color");
    color: themed("filter_font_color");
  }
}

.filterDisplay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.showFilters {
  display: block;
}

.filterItem {
  font-size: var(--form_font);
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: baseline;
  overflow-wrap: anywhere;

  &:hover,
  &.selected {
    @include themify($themes) {
      background-color: themed("filter_background_color");
    }
  }
}

.withBorderBottom {
  border-bottom: 1px solid $grey;
}

.arrowDown {
  bottom: 25%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  position: absolute;
  right: 10%;
  @include themify($themes) {
    border-top: var(--span_arrow_border_top) solid themed("accent_color");
  }

  @media screen and (min-width: 1024px) {
    border-left: var(--span_arrow_border_left_right) solid transparent;
    border-right: var(--span_arrow_border_left_right) solid transparent;
    position: relative;
    bottom: unset;
    right: unset;
  }
}

.dropdownCheckbox {
  margin-right: 0.5em;
}

.applyBtn {
  font-size: 0.6em;
}
