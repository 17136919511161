@import "colors";

/*
 * Theme definitions
 */
$themes: (
  dark: (
    backgroundImage: (
      linear-gradient(0deg, $new_overlay, $new_overlay),
      url("../images/Artboard.png"),
    ),
    ccsDashboardImage: $background_grey,
    primary-font-color: $bright_white,
    secondary-font-color: $white,
    primary-tile-background: $grey,
    dark_color: $background_grey,
    accent_color: $cyan,
    keyIndicator_accentColor: $cyan,
    accent_color_hover: $cyan_bright,
    bg_overlay_color: $new_overlay,
    link_color: $cyan,
    overlayBackground: $overlay_tile,
    overlayDotsColor: $cyan,
    grey_transparent: $grey_transparent,
    // Filters
    filter_font_color: #000000,
    filterMarketArrowColor: #000000,
    filter_background_color: $lightBackgroundGrey,
    messageColor: rgba(44, 57, 78, 0.8),
    // Border
    thinBorder: $thin_border,
    borderColor: $white,
    // Toggle
    toggle_background_color: $dark_blue,
    toggle_indicator_color: $cyan,
    // Menu
    menuFontColor: $white,
    menu-background: $dark_blue,
    menu-hover-background: $lightBackgroundGrey,
    menu-shadow: 0 0 0 rgba(0, 0, 0, 0),
    active_menu_background: $background_grey,
    seedsIcon: url("../../images/Menu/dark-theme/sesame.svg"),
    treesIcon: url("../../images/Menu/dark-theme/forest.svg"),
    fruitsIcon: url("../../images/Menu/dark-theme/pine-cone.svg"),
    knowledgeHubIcon: url("../../images/Menu/dark-theme/book.svg"),
    contactIcon: url("../../images/Menu/dark-theme/chat-bubbles-with-ellipsis.svg"),
    infoIcon: url("../../images/Menu/dark-theme/info.svg"),
    betaIcon: url("../../images/Menu/dark-theme/sprout.svg"),
    logoutIcon: url("../../images/Menu/dark-theme/arrow-left.svg"),
    cedarTree: url("../../images/Menu/dark-theme/cedar_tree_logo.svg"),
    cedarText: url("../../images/Menu/dark-theme/cedar_logo_text.svg"),
    // Tooltip
    infoTooltipIcon: url("../../images/Tooltip/dark-theme/information.svg"),
    // Profile
    profileIcon: url("../../images/User/dark-theme/user.svg"),
    preferencesIcon: url("../../images/User/dark-theme/settings.svg"),
    profileFontColor: $grey,
    profileBgColor: $bright_white,
    // Scroll
    scrollThumbColor: $cyan,
    scrollThumbColorHover: $cyan_bright,
    scrollBackground: $dark_blue,
    filterScrollBackground: $lightBackgroundGrey,
    //Documentation
    documentationSubHeaderColor: $cyan,
    documentationSubHeaderWeight: normal,
    documentationBackgroundColor: rgba(44, 57, 78, 0.8),
    documentationHeaderColor: $bright_white,
    documentationHeaderWeight: bold,
    // Info section
    infoSectionBackgroundColor: $grey,
    infoOptionAccent: rgba(44, 57, 78, 0.8),
    //Pill
    pillBackgroundColor: $grey,
    activePill_fontColor: $bright_white,
    //CCS Section
    disabledTileColor: $disabled_white,
    disabledFontColor: $disabled_white,
    //Table
    tableOddColor: rgba(236, 236, 236, 0.2),
    tableSortAsc: url("../../images/Table/dark-theme/asc.svg"),
    tableSortDesc: url("../../images/Table/dark-theme/desc.svg"),
    tableSortUnsorted: url("../../images/Table/dark-theme/unsorted.svg"),
    gameTableOddColor: rgba(236, 236, 236, 0.2),
    summaryRowColor: rgba(236, 236, 236, 0.2),
    // CCS page
    cssTitleBackground: $grey,
    ccsContentBackground: transparent,
    ccsTitleFontColor: $bright_white,
    ccsDisabledTile: transparent,
    topUsedServicesOffboard: $comparison_cyan,
    //Star
    starBackground: $bright_white,
    //banner
    bannerBackgroundColor: $bright_white,
    bannerFontColor: $black,
    //CEDAR HOME
    sectionBackgroundColor: $main-blue,
    sectionFontColor: $bright_white,
    headerFontColor: $header-dark-grey,
    //theme button background
    themeButtonColor: $bright_white,
    //midas
    totalHighlight: rgba(236, 236, 236, 0.2),
    varHighlight: rgba(236, 236, 236, 0.2),
  ),
  light: (
    backgroundImage: $lightBackgroundGrey,
    ccsDashboardImage: $lightBackgroundGrey,
    primary-font-color: $lightKeyIndicatorColor,
    secondary-font-color: #626262,
    primary-tile-background: $bright_white,
    active_menu_background: $lightBackgroundGrey,
    dark_color: $lightKeyIndicatorColor,
    bg_overlay_color: rgba($lightBackgroundGrey, 0.84),
    accent_color: $lightThemeAccentColor,
    keyIndicator_accentColor: $lightKeyIndicatorColor,
    accent_color_hover: $lightThemeAccentColor,
    thin_border: 1px solid rgba(black, 0.2),
    grey_transparent: $bright_white,
    link_color: #01a0dd,
    overlayBackground: rgba($lightBackgroundGrey, 0.75),
    overlayDotsColor: $lightThemeAccentColor,
    // Filters
    filter_font_color: $bright_white,
    filterMarketArrowColor: black,
    filter_background_color: $lightThemeAccentColor,
    messageColor: $white,
    // Border
    thinBorder: $black_thin_border,
    borderColor: $black,
    // Toggle
    toggle_background_color: darken($lightBackgroundGrey, 10%),
    toggle_indicator_color: $cyan,
    // Menu
    menu-background: $bright_white,
    menu-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06),
    menuFontColor: $lightKeyIndicatorColor,
    menu-hover-background: $cyan,
    seedsIcon: url("../../images/Menu/light-theme/sesame.svg"),
    treesIcon: url("../../images/Menu/light-theme/forest.svg"),
    fruitsIcon: url("../../images/Menu/light-theme/pine-cone.svg"),
    knowledgeHubIcon: url("../../images/Menu/light-theme/book.svg"),
    contactIcon: url("../../images/Menu/light-theme/chat-bubbles-with-ellipsis.svg"),
    infoIcon: url("../../images/Menu/light-theme/info.svg"),
    logoutIcon: url("../../images/Menu/light-theme/arrow-left.svg"),
    betaIcon: url("../../images/Menu/light-theme/sprout.svg"),
    cedarTree: url("../../images/Menu/light-theme/cedar_logo_blue-tree.png"),
    cedarText: url("../../images/Menu/light-theme/cedar_logo_text.svg"),
    // Tooltip
    infoTooltipIcon: url("../../images/Tooltip/light-theme/information.svg"),
    // Profile
    profileIcon: url("../../images/User/light-theme/user.svg"),
    profileFontColor: #ffffff,
    preferencesIcon: url("../../images/User/light-theme/settings.svg"),
    profileBgColor: $lightKeyIndicatorColor,
    // Scroll
    scrollThumbColor: $cyan,
    scrollThumbColorHover: $cyan_bright,
    scrollBackground: #626262,
    filterScrollBackground: $lightBackgroundGrey,
    //Documentation
    documentationSubHeaderColor: $lightKeyIndicatorColor,
    documentationSubHeaderWeight: bold,
    documentationBackgroundColor: $bright_white,
    documentationHeaderColor: $lightKeyIndicatorColor,
    documentationHeaderWeight: bold,
    // Info section
    infoSectionBackgroundColor: $bright_white,
    infoOptionAccent: rgba($lightBackgroundGrey, 0.7),
    //Pill
    pillBackgroundColor: $lightBackgroundGrey,
    activePill_fontColor: $bright_white,
    // Table
    tableOddColor: $lightBackgroundGrey,
    tableSortAsc: url("../../images/Table/light-theme/asc.svg"),
    tableSortDesc: url("../../images/Table/light-theme/desc.svg"),
    tableSortUnsorted: url("../../images/Table/light-theme/unsorted.svg"),
    gameTableOddColor: #999999,
    summaryRowColor: #002060,
    //CCS Section
    disabledTileColor: rgba(0, 0, 0, 0.25),
    disabledFontColor: #616161,
    //CCS page
    cssTitleBackground: $lightKeyIndicatorColor,
    ccsContentBackground: $bright_white,
    ccsTitleFontColor: $bright_white,
    ccsDisabledTile: rgba($bright_white, 0.25),
    topUsedServicesOffboard: $purple,
    //Star
    starBackground: #626262,
    //banner
    bannerBackgroundColor: $cyan,
    bannerFontColor: $bright_white,
    //CEDAR HOME
    sectionBackgroundColor: $bright_white,
    sectionFontColor: $black,
    headerFontColor: $header-light-grey,
    //theme button background
    themeButtonColor: $lightKeyIndicatorColor,
    //midas
    totalHighlight: #cae4f3,
    varHighlight: #fdfdda,
  ),
);

/*
    * Implementation of themes
    */
@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
