.cardContainer {
  margin-bottom: 35px;
  display: grid;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-template-areas: "major major major" "total city city";
  }
}

.majorCard {
  @media screen and (min-width: 1024px) {
    grid-area: major;
  }
}

.totalHandraisers {
  @media screen and (min-width: 1024px) {
    grid-area: total;
  }
}

.totalHandraisersValue {
  font-size: 2.25rem;
  font-family: "nissan_bold", "Open Sans", sans-serif;
}

.handraisersCity {
  @media screen and (min-width: 1024px) {
    grid-area: city;
  }
}

.monitorCardReset {
  border: none;
  padding: 0;
  padding-right: 2em;
  cursor: unset;
}
