@import "../colors";
@import "../effects";
@import "../themes";

.pmo_sotu {
  display: grid;
  grid-gap: 0.75rem;
  grid-template-areas: "custome_intention_cluster";
  grid-template-rows: max-content;

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  max-width: calc(100vw - 4em);

  h3 {
    font-size: var(--header_font);
    font-weight: 300;
    margin-bottom: 0.25em;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }

    text-align: left;
  }

  .chartWrapper,
  .graph {
    height: 100%;
    width: 100%;
    min-height: 250px;
    min-height: 50vh;
  }
  #customer_intention_by_color_colours_filter_div,
  #sales_by_color_colours_filter_div {
    border-right-width: 0px;
  }

  .colorOptionBtn {
    cursor: pointer;
  }

  .header {
    display: block;
    height: 5em;

    @media screen and (max-width: 700px) {
      margin-bottom: 1em;
      position: relative;

      .model_filter {
        margin-bottom: 1em;
        width: 90%;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .toogle_container {
    font-size: var(--smaller_regular_font_size);
    display: flex;
    gap: 5px;
  }

  .title_container {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .trend-legend {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: var(--regular_font_size);
    padding-left: 5em;

    .legend-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 1em;

      .legend-box {
        width: 1rem;
        height: 1rem;
        margin-right: 1em;
        border-radius: 0.2em;
      }
    }
  }

  .trend-legend-color {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-size: var(--regular_font_size);
    min-height: 500px !important;

    .legend-item-color {
      display: flex;
      flex-direction: row;
      margin-bottom: 1em;
    }

    .legend-box-color {
      width: 1rem;
      height: 1rem;
      margin-right: 1em;
      border-radius: 0.2em;
    }
  }

  .sov-trend-legend {
    display: flex;
    flex-direction: column;
    font-size: var(--regular_font_size);
    padding: 1.25em 1.5em 1.25em;
    max-height: 50vh;
    overflow-y: auto;

    .legend-item {
      display: flex;
      flex-direction: row;
      padding-bottom: 0.5em;

      .legend-box {
        width: 1rem;
        height: 1rem;
        margin-right: 1em;
        border-radius: 0.2em;
      }
    }
  }
}

.primary_tile_background {
  @include themify($themes) {
    background: themed("primary-tile-background");
  }
}

.market_label {
  display: flex;
  flex-direction: row;
}

.customer_intention_trend {
  .header {
    margin-left: 2.2em;
  }
}

.customer_intention_cluster,
.search_interest_vs_fmi,
.price_sales_volume,
.sales_by_color,
.customer_intention_color,
.comparison_price_volume,
.sales_by_grade,
.customer_intention_trend,
.sov_trend,
.marketing_activity_calendar {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.25em 1.5em 1.25em;

  .main_content {
    min-height: 300px;
  }
  .shared_container {
    display: grid;
    grid-template-columns: 70% 30%;
  }

  .no_data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.tclass {
  font-size: var(--smaller_regular_font_size);
}

@media screen and (min-width: 1250px) {
  .pmo_sotu {
    grid-template-areas: "custome_intention_cluster" "nim_tech" "price_sales_volume" "custome_intention_comparison" "search_interest_vs_fmi" "marketing_activity_calendar";
    grid-gap: 1em;

    .price_sales_and_comparison,
    .regional_sales_colour {
      display: grid;
    }

    .customer_intention_and_comparison {
      grid-area: custome_intention_cluster;
    }
    .nim_tech {
      grid-area: nim_tech;
    }
    .customer_intention_and_comparison,
    .nim_tech {
      display: flex;
      flex-flow: column;
      padding: 1.25em 1.25em 1.25em;

      .chart_content {
        display: grid;
        grid-template-columns: calc(42% - 0.275rem) calc(58% - 0.375rem);

        .trend-legend {
          padding-left: 0;
        }

        .customer_intention_comparison {
          display: grid;
          grid-template-columns: 35% 35% 30%;
        }
      }
    }

    .specification_and_prices_sotu {
      grid-template-areas: "custome_intention_cluster" "nim_tech" "price_sales_volume" "custome_intention_comparison";
    }

    .marketing_activity_sotu {
      grid-template-areas: "search_interest_vs_fmi" "marketing_activity_calendar";
    }

    .ev_power {
      display: flex;
      grid-area: custome_intention_comparison;
      flex-flow: column;
      padding: 1.25em 1.25em 1.25em;

      .chart_content {
        .trend-legend {
          padding-left: 0;
        }

        .customer_intention_comparison {
          display: grid;
          //grid-template-columns: 50% 50%;
          grid-template-columns: 100%;
        }
      }
    }

    .price_sales_and_comparison {
      display: grid;
      grid-area: price_sales_volume;
      grid-template-columns: calc(42% - 0.275rem) calc(58% - 0.275rem);
      padding: 1.25em 1.25em 1.25em;
    }

    .marketing_activity_calendar {
      grid-area: marketing_activity_calendar;
      width: calc(42% - 0.275rem);
    }

    .regional_sales_colour {
      grid-template-columns: calc(50% - 0.275rem) calc(50% - 0.275rem);
      padding: 1.25em 1.25em 1.25em;
    }

    .marketing_activities_and_investment_analysis {
      display: grid;
      grid-area: search_interest_vs_fmi;
      grid-template-columns: calc(42% - 0.275rem) calc(58% - 0.375rem);
      padding: 1.25em 1.25em 1.25em;
    }

    .search_interest_vs_fmi,
    .ev_power,
    .customer_intention_cluster,
    .marketing_activity_calendar,
    .sales_by_grade {
      .chartWrapper,
      .graph {
        min-height: 500px !important;
      }
    }

    .ev_power {
      width: calc(42% - 0.275rem);
    }

    .search_interest_vs_fmi {
      @include themify($themes) {
        border-right: themed("thinBorder");
      }
    }

    .customer_intention_cluster {
      @include themify($themes) {
        border-right: themed("thinBorder");
      }

      .chartWrapper,
      .graph {
        height: 545px !important;
      }
      &.nim {
        height: 500px;

        .chartWrapper,
        .graph {
          height: 450px !important;
        }
        &.tall {
          overflow-y: auto;
          @include scroll-bar;
          .chartWrapper,
          .graph {
            height: 700px !important;
          }
        }
      }
    }

    .customer_intention_comparison {
      .chartWrapper,
      .graph {
        height: 550px !important;
      }
      &.nim {
        .chartWrapper,
        .graph {
          height: 450px !important;
        }
      }
    }

    .nim_tech_legend {
      height: 320px;
      overflow-y: auto;
      justify-content: unset;
      margin-top: 3.5rem;
      @include scroll-bar;
    }

    .comparison_price_volume {
      .chartWrapper,
      .graph {
        height: 513px !important;
      }
    }

    .price_sales_volume {
      @include themify($themes) {
        border-right: themed("thinBorder");
      }

      .chartWrapper,
      .graph {
        height: 500px !important;
      }
    }

    .sales_by_color {
      @include themify($themes) {
        border-right: themed("thinBorder");
      }

      .chartWrapper,
      .graph {
        height: 100% !important;
      }
    }

    .customer_intention_color {
      .chartWrapper,
      .graph {
        height: 100% !important;
      }
    }
    .sov_outer_container {
      padding: 1.25em 1.25em 1.25em 1.25em;
    }

    .sov {
      display: grid;
      grid-template-columns: 70% 30%;
      height: 94%;
    }

    .chartWrapper,
    .graph {
      padding-left: 5px;
      padding-right: 5px;
    }

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media screen and (min-width: 390px) {
  .pmo_sotu {
    .chartWrapper,
    .graph {
      min-height: 65vh;
    }
  }
}

.isHidden {
  display: none;
}

.tippy-tooltip {
  background-color: rgba(0, 0, 0, 0.9);
}

.hiddenPMO {
  display: none;
}

.cluster_text {
  font-size: var(--header_font);
  font-weight: 300;
  margin-bottom: 0.5em;
  cursor: pointer;

  @include themify($themes) {
    color: themed("secondary-font-color");
  }
}

.cluster_wrapper {
  margin-bottom: 4em;
}

.showOnMobileOnly {
  display: flex;
  @media screen and (min-width: 1250px) {
    display: none !important;
  }
}

.hideOnMobileOnly {
  display: none !important;
  @media screen and (min-width: 1250px) {
    display: flex !important;
  }
}
