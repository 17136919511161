@import "../colors";
@import "../effects";
@import "../themes";

.filter {
  margin-top: 1.5em;
  border-right: none;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include themify($themes) {
    border-bottom: 1px solid themed("borderColor");
  }

  &.no-border {
    border: none !important;
  }

  @media screen and (min-width: 666px) {
    margin-top: 3em;
  }

  @media screen and (min-width: 1024px) {
    margin: 0;
    border-bottom: 0 !important;

    @include themify($themes) {
      border-right: themed("thinBorder");
    }

    padding-left: 1em;
  }

  h3,
  .filter_header {
    font-size: 1em;
    margin-bottom: 0.8em;
    text-align: left;
    width: auto;
    white-space: nowrap;
    font-weight: bold;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }

    @media screen and (min-width: 1024px) {
      font-size: var(--form_font);
      letter-spacing: 0;
      margin: 0;
      font-family: "nissan_light", "Open Sans", sans-serif;
    }
  }

  .region_country_filter {
    display: none;
    color: $cyan;

    &.show-markets {
      display: block;
    }

    &.isSub {
      li,
      .multiselect_container {
        padding-left: calc(1.75rem + 2rem);
      }
    }

    &.show-market {
      display: block;
      padding-left: 2em;
    }

    li,
    .multiselect_container {
      padding-left: 2rem;

      &.sub {
        padding-left: 2em;
      }

      &:hover {
        @include themify($themes) {
          background-color: themed("menu-hover-background");
        }
      }
    }
  }

  &.filter_toggle_div {
    flex-flow: column;
    justify-content: space-around;
    align-items: flex-start;
  }
}

.input_arrow {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  padding: 0;
  margin-right: 1em;

  @media screen and (min-width: 1024px) {
    cursor: pointer;
  }
}

.arrow_down {
  bottom: 25%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  position: absolute;
  right: 10%;

  @include themify($themes) {
    border-top: var(--span_arrow_border_top) solid themed("accent_color");
  }

  @media screen and (min-width: 1024px) {
    border-left: var(--span_arrow_border_left_right) solid transparent;
    border-right: var(--span_arrow_border_left_right) solid transparent;
    position: relative;
    bottom: unset;
    right: unset;
  }
}

.filter_input {
  font-size: 0.9rem;
  cursor: pointer;
  background: none;
  display: inline-block;
  min-width: 100px;
  border: none;

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  @media screen and (min-width: 1024px) {
    font-size: var(--form_font);
    width: 90%;
    min-width: unset;
    margin-right: 0.5em;
    padding: 0;
    padding-left: 1.5px;
  }

  &:focus {
    outline: none;
  }
}

.filter_options {
  @include themify($themes) {
    background-color: themed("primary-tile-background");
    box-shadow: 0 0 0.5em rgba(themed("secondary-font-color"), 0.5);
    color: themed("primary-font-color");
  }

  padding-top: 0.25em;
  position: absolute;
  display: none;
  z-index: 9999;
  text-align: left;
  max-height: 19rem;
  overflow-y: auto;

  left: 0;
  right: 0;
  top: 100%;
  text-transform: initial;
  @include scroll-bar;

  &.showOptions {
    display: block;
  }

  li,
  .multiselect-label {
    list-style: none;
    cursor: pointer;
    font-size: 1.2em;
    letter-spacing: 0;
    font-family: "nissan", "Open Sans", sans-serif;
    display: inline-block;
    width: 100%;
    word-break: break-word;
    padding: 0.85em 0.5em;
    margin-bottom: 0;

    @include themify($themes) {
      color: themed("primary-font-color");
    }

    .disclaimer_text {
      font-size: 0.8em;
      //color: rgba(44, 57, 78, 0.8) !important;

      @include themify($themes) {
        color: themed("filter_font_color");
      }
    }

    @media screen and (min-width: 666px) {
      font-size: 1.5em;
      padding: 0.85em 0.5em;
    }

    @media screen and (min-width: 1024px) {
      font-size: var(--form_font);
      padding: 0.5em;
      @include no-select;
    }

    &:hover,
    &.selected {
      @include themify($themes) {
        background-color: themed("filter_background_color");
        color: themed("menu-background");
      }
    }

    &.custom {
      display: flex;

      @include themify($themes) {
        border-top: 1px solid themed("secondary-font-color");
      }
    }

    &.error_msg {
      pointer-events: none;
    }

    &.filter_divider {
      position: relative;
      padding-bottom: 0.75em;

      @include themify($themes) {
        border-bottom: 1px solid themed("secondary-font-color");
      }
    }

    &.filter_description {
      font-style: italic;

      @include themify($themes) {
        color: themed("secondary-font-color");
      }
    }

    &.model_divider {
      &:after {
        content: "Top models";
        font-style: italic;
        position: absolute;
        bottom: 0.1em;
        right: 7.5px;

        @include themify($themes) {
          color: themed("secondary-font-color");
        }
      }
    }

    &.market_group_divider {
      display: block;
      padding: 0;
    }

    &.no-result-option:hover {
      background-color: unset;
      color: unset;
      cursor: unset;
    }
  }

  &#region_ul,
  &.date_options {
    max-height: max-content;
  }

  .open_calendar_options {
    height: calc(175px + var(--form_font));
  }

  &#region_ul {
    &.market-open {
      max-height: 19rem;
    }
  }

  &#region_ul {
    &.cropped_region_ul {
      max-height: 19rem;
    }
  }

  .multiselect_container {
    width: 100%;
    padding: 0 1em;

    &:hover {
      @include themify($themes) {
        background-color: themed("menu-hover-background");
      }
    }

    .arrow-span,
    .market-arrow {
      display: inline-block;
      border: solid;
      border-width: 0 var(--arrow_border_width) var(--arrow_border_width) 0;
      padding: var(--arrow_padding);

      @include themify($themes) {
        color: themed("filterMarketArrowColor");
      }
    }

    .arrow-span {
      display: flex;
      align-items: center;
      border: none;
      margin-right: 0.75em;

      &.down {
        .market-arrow {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          margin-bottom: var(--arrow_padding);
        }
      }
    }

    .market-arrow {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }

  .region-list {
    display: flex;
    align-items: center;
    justify-content: left;

    &:hover {
      @include themify($themes) {
        background-color: themed("filter_background_color");
      }
    }

    .region-span {
      width: 1.5rem;
      height: 100%;
      padding: 0.5em;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.sub {
        padding-left: 1.5rem;
      }

      &.region0 {
        display: none;
      }

      &.region0 + li {
        padding-left: calc(0.5em + 1.5rem);
      }

      &.region0 + .multiselect_container {
        padding-left: calc(1.5rem - 0.25em);
      }

      span {
        display: inline-block;
        border: solid;
        border-width: 0 var(--arrow_border_width) var(--arrow_border_width) 0;
        padding: var(--arrow_padding);

        @include themify($themes) {
          color: themed("filterMarketArrowColor");
        }
      }

      .market-arrow {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);

        &.down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          margin-bottom: var(--arrow_padding);
        }
      }
    }
  }

  .sub_filter_options {
    color: $cyan;
    padding-left: 1rem;
  }

  .multicheck_sub_filter_options {
    display: none;

    &.show {
      display: block;
    }

    .multiselect_container {
      padding-left: 2.5rem;
    }
  }

  .market-filter {
    display: flex;
    align-items: center;
    padding: 0 0.5rem 0 0;
    justify-content: left;

    .market-arrow-container {
      width: 1.5rem;
      height: 100%;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      .market-arrow {
        display: inline-block;
        border: solid;
        border-width: 0 var(--arrow_border_width) var(--arrow_border_width) 0;
        padding: var(--arrow_padding);

        @include themify($themes) {
          color: themed("filterMarketArrowColor");
        }

        transform: rotate(-45deg);
      }

      &.down {
        .market-arrow {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          margin-bottom: var(--arrow_padding);
        }
      }
    }
  }
}

.filter_search_bar {
  margin: 0.5em;

  input {
    width: 100%;

    @include themify($themes) {
      color: themed("filter_font_color");
    }

    &::-webkit-search-cancel-button {
      cursor: pointer;

      @include themify($themes) {
        color: themed("accent_color");
      }
    }
  }
}

.filter_options__submit_area {
  display: flex;
  padding: 0.25rem 0.5rem;
  border-top: 1px solid $grey;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  background: $bright_white;

  @include themify($themes) {
    border-top: 1px solid themed("borderColor");
    background-color: themed("primary-tile-background");
  }

  .message {
    font-size: var(--filter_font);

    @include themify($themes) {
      color: themed("messageColor");
    }
  }
}

.filter_submit_btn {
  &:active,
  &:focus {
    outline: none;
  }

  background-color: $cyan;
  border-color: $cyan;
  color: $bright_white;
  cursor: pointer;
  padding: 0.75em;
  font-size: 1.2em;

  &:hover {
    border-color: $cyan_bright;
    background-color: $cyan_bright;
  }
}

.clean_input {
  background-color: transparent;
  border: none;
  padding: 1em;

  @include themify($themes) {
    color: themed("filter_font_color");
  }
}

.reset_filters {
  grid-area: reset;
  height: 8em;
  width: 5em;
  margin-left: auto;
  background-position: center left;
  background-image: url("../../images/Filter/reset_filter_hover.png");
  background-size: 65%;
  background-repeat: no-repeat;

  @media screen and (min-width: 1024px) {
    background-size: 65%;
    background-position: center right;
    height: unset;
    width: 3.5rem;
    background-image: url("../../images/Filter/reset_filter.png");
    cursor: pointer;

    &:hover {
      background-image: url("../../images/Filter/reset_filter_hover.png");
    }
  }

  @media screen and (min-width: 1250px) {
    background-size: 50%;
  }

  @media screen and (min-width: 1600px) {
    background-size: 60%;
  }

  @media screen and (min-width: 1900px) {
    background-size: 65%;
  }

  @media screen and (min-width: 2300px) {
    background-size: 75%;
  }
}

.dashboard-classification-buttons {
  grid-area: eight;
  display: flex;
  gap: 0.5rem;
  margin-left: 1rem;
}

.daterangepicker {
  font-family: "nissan", "Open Sans", sans-serif;
}

.multiselect_container {
  display: flex;

  &.hide_dealer {
    display: none;
  }
}

.multiselect-checkbox {
  align-self: center;
}

.filter_button {
  font-family: "nissan_bold", "Open Sans", sans-serif;

  transition: color 0.2s linear;
  background: none;
  color: $bright_white;
  font-size: 1rem;
  border: none;
  text-transform: uppercase;
  cursor: pointer;
  line-height: 2.5em;
  margin: 0 !important;
  left: 0;
  right: 0;
  width: 100%;
  background: $cyan;
  bottom: 0;
  z-index: 1000;
  padding: 0.5em 0;
  display: unset;

  &:focus,
  &:active {
    outline: none;
  }

  &.expanded {
    z-index: -1;
  }

  &:hover {
    background-color: $cyan_bright;
  }

  &::before {
    height: 1.25em;
    width: 1em;
    display: inline-block;
    background-size: 1.25em;
    content: "";
    background-repeat: no-repeat;
    background-image: url("../../images/Filter/mobile_filter.svg");
    position: absolute;
    margin-top: 0.7em;
    left: 10%;
  }

  &.no_icon {
    &::before {
      background-image: none;
    }
  }
}

.iframeFilters {
  .filter_btn {
    @media screen and (min-width: 1024px) {
      justify-content: left !important;
    }
  }
}

.filtersWrapper {
  @media screen and (min-width: 1024px) {
    display: flex;
  }
}

.search_div {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  margin-bottom: 1em;

  @media screen and (min-width: 1024px) {
    margin-bottom: unset;
    padding-bottom: 1em;
    border-bottom: 1px solid $lightBackgroundGrey;
  }
}

.selection_label {
  @media screen and (min-width: 1024px) {
    display: grid !important;
  }
}

.selection_value {
  white-space: nowrap;
  align-self: center;
}

.search_clear_div {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (min-width: 1024px) {
    flex-flow: row-reverse;
    justify-content: unset;
    gap: 1em;
  }
}

.clear_btn {
  align-self: center;
  cursor: pointer;
  padding: 1px 7.5px;
}

.breadcrumb_container {
  padding-bottom: 1em;
  border-bottom: 1px solid $lightBackgroundGrey;
}

#filters,
#filters.expanded {
  width: 100%;
  margin-right: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3001;
  padding: 5em 3.5em;

  @include themify($themes) {
    background-color: themed("menu-background");
  }

  @media screen and (min-width: 1024px) {
    grid-area: form;
    grid-template-areas: "one two three four five six seven eight reset";
    padding: 0.5em 2em;
    z-index: unset;
    position: relative;
    height: 100%;

    @include themify($themes) {
      background: themed("primary-tile-background");
    }

    &.iframeFilters {
      grid-template-columns: auto auto auto auto auto auto 1fr 1fr;

      .nav_btns {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto 1fr 1fr;
      }
    }

    &.iframeFilters-midas {
      position: relative;
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-areas: unset;
      // z-index: 99999999999999999999999;

      .nav_btns {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto auto 1fr 1fr;
        margin-bottom: 1.25em;
      }

      .filte {
        display: grid;
        grid-template-areas: "one two three four five six seven eight reset";
      }
    }
  }
}

#filters {
  display: none;

  @media screen and (min-width: 1024px) {
    display: grid;
  }
}

#filters.expanded {
  display: block;

  @media screen and (min-width: 1024px) {
    display: grid;
  }
}

#closeFilters {
  position: absolute;
  right: 1.5em;
  top: 1.5em;
  background: none;
  border: none;
  font-size: 0.9rem;
  color: $white;
  cursor: pointer;
}

#geography_selector,
#model_selector {
  padding: 0.75em;
  max-height: unset;

  @media screen and (min-width: 1024px) {
    &.showOptions {
      display: block;
      padding: 1.5em 2em;
      width: max-content;
    }
  }
}
#model_selector {
  @media screen and (min-width: 1024px) {
    left: -70.5em;
  }
}
.geography_list,
.geography_label,
.color_label,
.color_list {
  font-size: 0.9rem !important;

  @media screen and (min-width: 1024px) {
    padding: 0.25em 0.5em !important;
    word-break: normal !important;
  }
}

.geography_list,
.color_list {
  @media screen and (min-width: 1024px) {
    width: 100%;
  }
}

.geography_options {
  display: flex;
}

.geography_options_title {
  @media screen and (min-width: 1024px) {
    display: flex !important;
  }
}

.geography_type,
.marketList {
  width: 100%;

  @media screen and (min-width: 1024px) {
    min-width: 20.75em;
  }
}

.geography_type,
.marketList {
  &.isHidden {
    display: none;
  }
}

.geography_type {
  padding: 0.25em 0.5em;

  .title {
    font-size: 0.9rem;

    @include themify($themes) {
      color: themed("primary_font_color");
    }
  }
}

.marketList {
  max-height: 31em;
  overflow-y: auto;
  display: none;

  @include filter-scroll-bar();

  &.active {
    display: block;
  }

  @media screen and (min-width: 1024px) {
    display: block;
    max-height: 25em;
    min-width: 23.5em;
    border-right: 1px solid $lightBackgroundGrey;

    &:last-of-type {
      border-right: none;
    }
  }
}

.geography_options__submit_area {
  padding: 1em 0 0;

  .filter_button {
    padding: 0.2em 0;
  }
}

.react-datepicker__input-container {
  input {
    width: 90%;
    font-size: var(--form_font);
    border-radius: none;
    padding: 0.2em;

    @include themify($themes) {
      color: themed("filter_font_color");
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.width-full {
  width: 100%;
}
