@import "../colors";
@import "../themes";

.overlay {
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50;
  pointer-events: none;

  &.metric_table_overlay {
    height: 60%;
    top: unset;
    bottom: 0;
    @media screen and (min-width: 1250px) {
      height: 100%;
      width: 40%;
      left: unset;
      right: 0;
    }
  }

  &.metric_graph_overlay {
    height: 40%;

    @media screen and (min-width: 1250px) {
      height: 100%;
      width: 60%;
    }
  }
  &.joined_graph_overlay {
    height: 45%;
  }

  &.joined_table_overlay {
    height: 55%;
    bottom: 0;
    top: unset;
  }
  @include themify($themes) {
    background: themed("overlayBackground");
  }
  pointer-events: none;

  &.show {
    display: block;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: absolute;
  width: 64px;
  height: 64px;
  top: 42.5%;
  left: 42.5%;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  @include themify($themes) {
    background: themed("overlayDotsColor");
  }

  // background: $cyan;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}
