@import "../colors";
@import "../effects";
@import "../themes";

.ccs_analysis {
  font-size: var(--regular_font_size);
  display: flex;
  flex-direction: column;
  padding-bottom: 6em;
  gap: 10px;

  @media screen and (min-width: 1280px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "enrollment_rate active_user_rate"
      "feature_usage feature_usage"
      "web_page_views web_page_views";
    grid-gap: 10px;
    height: 100%;
  }

  @include themify($themes) {
    color: themed("secondary-font-color") !important;
  }

  .enrollment_rate {
    grid-area: enrollment_rate;
    display: block;

    @media screen and (min-width: 1280px) {
      height: 40vh !important;
    }
  }

  .feature_usage,
  .web_page_views {
    display: block;

    @media screen and (min-width: 1400px) {
      height: 45vh !important;
    }

    @media screen and (max-width: 1400px) {
      height: 55vh !important;
    }
  }

  .feature_usage {
    grid-area: feature_usage;
  }

  .web_page_views {
    grid-area: web_page_views;
  }

  .ratings_tile {
    padding: 1.25em;
    border-radius: 5px;
    position: relative;

    @include themify($themes) {
      background: themed("primary-tile-background");
      height: 100%;
    }
  }

  .ratings_tile_header {
    font-size: var(--header_font);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ratings_tile_header_text {
      display: flex;
      justify-content: space-between;

      .dashboard-link {
        margin-left: 1em;
      }
    }

    .ratings_tile_header_toggles {
      display: flex;
      flex-flow: row;

      .toggle_container {
        margin-left: 2em;
      }

      @media screen and (max-width: 667px) {
        flex-flow: column;
      }
    }
  }

  .message {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
