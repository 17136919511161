@import "../colors";
@import "../effects";
@import "../themes";

.quick-voc-sales {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  font-size: var(--regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color") !important;
  }

  .tile {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
    }

    height: 100%;
    border: none;
    border-radius: 0;
    display: flex;

    .tile-header {
      display: flex;
      font-size: var(--header_font);
      margin-bottom: 1rem;
      justify-content: space-between;
      align-items: center;

      .title-container {
        display: flex;

        .title {
          display: flex;
          flex-direction: column;
        }

        .sub-text {
          font-size: var(--smaller_regular_font_size);
        }
      }

      .filters-container {
        display: flex;
      }
    }
  }

  .performance-by-market {
    height: 45rem;
  }

  .mapWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;

    .map {
      height: 100%;
      width: 85%;
    }

    #legenddiv {
      width: 15%;
    }
  }

  .grid-row-2 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1rem;
    min-height: 25em;
  }

  .market-deep-dive-table-container {
    @include scroll-bar;
    max-height: 50rem;
    display: flex;
    overflow-y: auto;
    margin-bottom: 0px;
  }

  .market-deep-dive-table {
    border-collapse: collapse;
    table-layout: fixed;

    th:nth-of-type(1),
    th:nth-of-type(4) {
      width: 30%;
    }

    th:nth-of-type(2),
    th:nth-of-type(3) {
      width: 20%;
    }

    th,
    td {
      @include themify($themes) {
        border: 1px solid themed("primary-font-color");
      }

      padding: 0.5rem !important;
    }

    th:first-of-type {
      border-left: 0;
      border-top: 0;
    }

    .satisfaction-score-cell {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .model-table {
    th:nth-of-type(1),
    th:nth-of-type(3),
    th:nth-of-type(4) {
      width: 20%;
    }

    th:nth-of-type(2) {
      width: 15%;
    }
  }

  .deep-dive-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    min-height: 30rem;
  }
}
