@import "../colors";
@import "../themes";

.MuiRating-root {
  color: $cyan !important;
}

.MuiRating-iconEmpty {
  @include themify($themes) {
    color: themed("starBackground") !important;
  }
}
