@import "../colors";

.indicator {
  display: block;
  height: 0.75em;
  width: 0.75em;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  background-position: center;
  margin-right: 0.5em;

  &.na {
    background-image: url("../../images/Ratings/na_arrow.png");
  }

  &.up {
    background-image: url("../../images/Ratings/up_arrow.png");
  }

  &.down {
    background-image: url("../../images/Ratings/down_arrow.png");
  }

  &.down-red {
    background-image: url("../../images/Ratings/down_arrow_red.png");
  }
}
