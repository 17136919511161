@import "../colors";
@import "../themes";

.model_info_table {
  @include themify($themes) {
    color: themed("primary-font-color");
  }
  margin: auto;
  width: auto;

  tr,
  td,
  th {
    font-size: var(--popup_font);

    @include themify($themes) {
      border: 1px solid themed("primary-font-color");
    }
    border-collapse: collapse;
  }

  td,
  th {
    padding: 0.25em 1em !important;
    text-align: left;
  }

  th {
    font-weight: bold;
  }
}
