@import "../colors";
@import "../effects";
@import "../themes";

.ecommerce-page-container {
  @include themify($themes) {
    color: themed("primary-font-color") !important;
  }

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 37vh;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .order-summary-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;

    .metric-tile {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      padding: 0.5rem;

      @include themify($themes) {
        border: 1px solid themed("primary-font-color");
        background: themed("ccsContentBackground");
      }

      .tile-header {
        font-size: var(--header_font);
        display: flex;
        text-align: center;
      }

      .tile-content {
        padding: 0.8rem 0;
        font-size: var(--small_number);
      }
    }
  }

  .map-row {
    min-height: 33rem;

    .tile {
      @include themify($themes) {
        background-color: themed("primary-tile-background");
      }

      height: 100%;
      border: none;
      border-radius: 0;
      display: flex;

      .tile-header {
        display: flex;
        font-size: var(--header_font);
        margin-bottom: 1rem;
        align-items: center;

        .title-container {
          display: flex;

          .title {
            display: flex;
            flex-direction: row;
          }

          .sub-text {
            font-size: var(--smaller_regular_font_size);
          }
        }

        .filters-container {
          display: flex;
        }
      }
    }
  }

  .mapWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;

    .map {
      height: 100%;
      width: 85%;
    }

    #legenddiv {
      width: 15%;
    }
  }

  .row-2 {
    display: grid;
    grid-template-rows: 0.8fr 1fr;
    gap: 1rem;
  }

  .ecommerce-tile {
    padding: 0.8em;
    font-size: var(--regular_font_size);
    display: flex;
    flex-direction: column;
    position: relative;

    @include themify($themes) {
      background: themed("primary-tile-background");
      color: themed("primary-font-color");
    }

    .tile-header {
      font-size: var(--header_font);
      display: flex;
      justify-content: space-between;

      .header-title {
        display: flex;
        align-items: center;
      }
    }

    .tile-content {
      height: 100%;
    }

    .ecommerce_metric_selection {
      display: flex;
      gap: 1em;
    }
  }

  .leads-container {
    display: grid;
    gap: 1rem;
  }

  .metric-tile-container {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
    padding: 1rem;
    height: 100%;

    .metric-tile {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      border: 0.15rem solid;
    }
  }

  .cell-data {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
  }

  .tile {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
      color: themed("primary-font-color");
    }

    border: none !important;
    border-radius: 0 !important;
  }

  .h-30 {
    height: 30rem;
  }

  .verbatimTile {
    padding-top: unset;
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll-bar;

    @media screen and (max-width: 767px) {
      overflow-x: auto;
    }

    .filter {
      border-right: none;
      width: 250px;
    }

    .tileHeader {
      font-size: var(--header_font);
      padding: 0.5em;
      display: flex;
    }

    .verbatim-tile-header {
      padding-top: 0.5em;
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @include themify($themes) {
        background-color: themed("primary-tile-background");
      }

      z-index: 2;
    }

    .verbatim-count {
      font-size: var(--header_font);
      padding: 10px;
      margin-right: 4rem;
    }

    .flex-row {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        text-align: center;
      }
    }

    .verbatimTable {
      table-layout: fixed;

      thead {
        position: sticky;
        top: 44px;

        @include themify($themes) {
          background-color: themed("primary-tile-background");
        }

        z-index: 0;
      }

      .Market {
        width: 80px;
      }

      .Date {
        width: 80px;
      }

      .Experience {
        width: 120px;
      }

      .Topic {
        width: 150px;
        word-wrap: break-word;

        .link {
          color: $cyan;
        }
      }

      .Touchpoints {
        width: 150px;
      }

      .Subcategory {
        width: 150px;
      }

      .Device {
        width: 150px;
      }

      .URL {
        width: 250px;
        word-wrap: break-word;

        .link {
          color: $cyan;
        }
      }

      .Comment {
        word-wrap: break-word;

        @media screen and (max-width: 767px) {
          word-wrap: normal;
        }
      }
    }
  }

  .responses {
    font-size: 0.4em;
  }
}
