@import "../colors";
@import "../effects";
@import "../themes";

.market-performance-summary {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  font-size: var(--regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color") !important;
  }

  .tile {
    border: none;
    border-radius: 0;

    @include themify($themes) {
      background: themed("primary-tile-background");
    }

    .tile-header {
      height: 2em !important;

      .title {
        display: flex;
      }
    }

    .tile-content {
      font-size: var(--smaller_regular_font_size);
    }
  }

  .chart-and-table-tile {
    height: 25rem;
  }

  .summary {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1em 0;

    .column {
      padding: 0.2rem 0;

      @include themify($themes) {
        border: 1px solid themed("primary-font-color");
      }

      &:first-of-type {
        @include themify($themes) {
          border: 1px solid themed("primary-font-color");
          border-right: none;
        }
      }

      &:last-of-type {
        @include themify($themes) {
          border: 1px solid themed("primary-font-color");
          border-left: none;
        }
      }

      text-align: center;
    }
  }

  .chart-table-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 100%;
  }

  .chart-container {
    height: 100%;
  }

  .market-performance-summary-table {
    border-collapse: collapse;

    th,
    td {
      @include themify($themes) {
        border: 1px solid themed("primary-font-color");
        text-align: left !important;
      }

      padding: 0.2em !important;
      font-size: var(--smaller_regular_font_size);
    }

    th:first-of-type {
      border-top: 0;
      border-left: 0;
    }
  }

  .search-interest-summary-table,
  .qualified-visits-summary-table,
  .kba-and-leads-summary-table {
    height: 3.75rem;
    font-size: var(--regular_font_size);
  }

  .tile:nth-of-type(1),
  .tile:nth-of-type(2),
  .tile:nth-of-type(3) {
    height: 20rem;
    min-height: 20rem;
  }

  .legends {
    display: flex;
    flex-wrap: wrap;

    .legend-item {
      margin-right: 1em;
      display: flex;
      margin-bottom: 0.5rem;

      .line {
        border-bottom: 0.3rem solid black;
        width: 1rem;
        transform: translateY(-0.4rem);
        margin: 0 0.3rem;
      }
    }
  }
}
