@import "../colors";
@import "../themes";

.popup.release_popup {
  .popupModal {
    width: auto;
    height: auto;
    border: 1px solid $bright_white;
    max-width: 65em;
    @include themify($themes) {
      background: themed("documentationBackgroundColor");
    }
    border-radius: 15px;
  }

  .data_message {
    padding: 0.5em;
    font-size: var(--header_font);
    color: $bright_white;
    @include themify($themes) {
      color: themed("primary-font-color");
    }

    h4 {
      font-size: calc(var(--header_font) + 0.1em);
    }

    ul {
      @include themify($themes) {
        color: themed("secondary-font-color");
      }
      color: $white;
    }
  }
}
