@import "../colors";
@import "../themes";

.profile_picture {
  padding: 0.5em;
  border-radius: 50%;
  font-size: var(--header_font);
  font-weight: 500;
  min-width: 4ch;
  min-height: 4ch;
  display: flex;
  @include themify($themes) {
    background-color: themed("profileBgColor");
    color: themed("profileFontColor");
  }
  span {
    text-transform: uppercase;
    margin: auto;
    line-height: 1ch;
  }
}

@media screen and (min-width: 1024px) {
  .profile_picture {
    font-size: calc(var(--regular_font_size) + 0.75rem);
  }
}
