@import "../colors";
@import "../themes";

.toggle_container {
  font-size: 0.625rem;
  display: flex;
  flex-flow: column;

  .toggle_label {
    font-size: var(--smaller_regular_font_size);
  }

  &.dp_toggle {
    padding-bottom: 1.5em;
  }
  &.filter_toggle {
    padding-right: 1em;
    .toggle_label {
      font-size: var(--form_font);
    }
  }
}

.toggle_title {
  white-space: nowrap;
  padding-right: 1em;
  font-size: var(--smaller_regular_font_size);
  @include themify($themes) {
    color: themed("primary-font-color");
  }
}

.toggle_switch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.toggle_label {
  @include themify($themes) {
    color: themed("secondary-font-color");
  }
  &.active_label {
    @include themify($themes) {
      color: themed("primary-font-color");
    }
  }
}

.toggle_span {
  padding: 0 0.5em;
  margin: auto 0;
  span {
    align-items: center;
  }
}

.change_toggle_view {
  cursor: pointer;
  width: var(--toggle_button_width);
  height: var(--toggle_button_height);
  border-radius: 50px;
  position: relative;
  display: none;
  @include themify($themes) {
    background-color: themed("toggle_background_color");
  }

  &:after {
    content: "";
    position: absolute;
    left: 2%;
    width: var(--toggle_after_circle);
    height: var(--toggle_after_circle);
    border-radius: 50%;
    transition: 0.3s;
    @include themify($themes) {
      background: themed("toggle_indicator_color");
    }
  }

  &#inactive_toggle {
    &:after {
      left: unset;
      right: 2%;
    }
  }

  &.active_toggle {
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  .toggle_container {
    flex-flow: row;
  }
}

.toggle_buttons {
  .input_span {
    margin: 0 0.5em;

    border-radius: 0.4em;

    .toggle_label {
      margin-left: 0.5em;
    }
  }
}
