@import "../colors";
@import "../effects";
@import "../themes";

.midas_mtp_category_sotu {
  display: grid;
  grid-template-areas: "mtp_category";
  grid-template-rows: max-content;
  box-sizing: border-box;

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .primary_background {
    @include themify($themes) {
      background: themed("primary-tile-background");
    }
  }

  max-width: calc(100vw - 4em);

  h3 {
    font-size: var(--header_font);
    font-weight: 300;
    margin-bottom: 0.2em;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }

    text-align: left;
  }

  .quarter_title_monthly {
    display: grid;
    grid-template-columns: 32% 1fr 1fr 1fr;
    @include themify($themes) {
      border-top: solid 1px themed("primary-font-color");
    }
    .quarter_title_yrscnr {
      &:nth-of-type(1),
      &:nth-of-type(2) {
        @include themify($themes) {
          border-right: solid 1px themed("primary-font-color");
        }
      }
    }
  }
  .quarter_title {
    font-size: var(--regular_font_size);
  }

  div {
    &.quarter_title {
      border-right: solid 1px white;
    }
  }

  .blue {
    background-color: #3498db;
  }
  .dark_purple {
    background-color: #8e44ad;
  }
  .total_highlight {
    @include themify($themes) {
      background-color: themed("totalHighlight");
    }
  }

  .tables {
    overflow-y: auto;
    overflow-x: hidden;
    @include scroll-bar;
  }

  @media screen and (min-width: 1250px) {
    .tables {
      overflow-y: hidden;
      @include scroll-bar;

      &.quarter_period {
        &.pdf_download {
          max-width: unset;
          width: calc(19.5rem + calc(9 * 10rem));
          &.one_yr_scnr_no_var {
            width: calc(20rem + calc(9 * 10rem));
          }
        }
        &.one_yr_scnr_no_var {
          max-width: 92vw;
        }
      }
      &.month_period {
        &.pdf_download {
          max-width: unset;
          width: calc(19.5rem + calc(9 * 10rem));
        }
        &.one_yr_scnr_no_var {
          max-width: 92vw;
          &.pdf_download {
            max-width: unset;
            width: calc(18.8rem + calc(6 * 10rem));
          }
        }
      }
      .mtp_category_tables {
        padding: 0.5em 0em 0.5em;
        display: grid;

        &.combined {
          &.month_period {
            .quarter_titles {
              display: grid;
              grid-template-columns: repeat(4, 3fr) 1fr;

              .quarter_title_monthly {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                &.one_yr_scnr_no_var {
                  grid-template-columns: 1.01fr 1.01fr 1fr;
                }
              }
            }
          }
          &.quarter_period {
            &.pdf_download {
              width: calc(19.1rem + calc(6 * 10rem));
            }
            .quarter_titles {
              display: grid;
              grid-template-columns: repeat(5, 1fr);
            }
            &.one_yr_scnr_no_var {
              width: calc(19.1rem + calc(5 * 10rem));
            }
          }
        }

        min-height: 20vh;

        .quarter_breakdown {
          width: auto;
          text-align: center;

          @include themify($themes) {
            border: solid 1px themed("primary-font-color");
          }
          &.quarter_period {
            &.pdf_download {
              width: calc(19.1rem + calc(6 * 10rem));
            }
            &.one_yr_scnr_no_var {
              width: calc(19.1rem + calc(5 * 10rem));
            }
          }
          &.month_period {
            &.pdf_download {
              &.one_yr_scnr_no_var {
                width: calc(19.1rem + calc(6 * 10rem));
              }
            }
          }

          .no_data {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .combinedBreakdown {
      position: relative !important;
      .yr_scnr_rows {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        &.month_period {
          &.two_yr_scnr {
            grid-template-columns: 1.01fr 1.01fr 1fr;
          }
        }

        @include themify($themes) {
          border-bottom: solid 1px themed("primary-font-color");
        }
        div:nth-of-type(1),
        div:nth-of-type(2) {
          @include themify($themes) {
            border-right: solid 1px themed("primary-font-color");
          }
        }
      }

      .pdf_titles {
        margin-left: 18.05rem;
        display: grid;
        @include themify($themes) {
          border-right: solid 1px themed("primary-font-color");
        }
      }

      .pdf_tables {
        visibility: hidden;
        position: absolute;
        height: 2px;
        &.pdf_download {
          display: unset;
          visibility: unset;
          position: relative;
          height: unset;
        }
        .q1_to_q3 {
          margin-bottom: 5rem;
          &.quarter_period {
            width: calc(18rem + calc(6 * 10rem));
            .pdf_titles {
              .quarter_titles {
                grid-template-columns: 1fr 1fr;
                &.one_yr_scnr_no_var {
                  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                }
              }
            }
          }
          &.month_period {
            width: calc(19.5rem + calc(9 * 10rem));
            .pdf_titles {
              .quarter_titles {
                grid-template-columns: 1fr;
                &.one_yr_scnr_no_var {
                  grid-template-columns: 1fr 1fr;
                }
              }
            }

            &.one_yr_scnr_no_var {
              grid-template-columns: 1fr 1fr;
              width: calc(18.7rem + calc(3 * 10rem));
            }
          }
        }
        .q4_to_full {
          &.quarter_period {
            width: calc(18rem + calc(3 * 10rem));
            &.one_yr_scnr_no_var {
              width: calc(18rem + calc(1 * 10rem));
              .pdf_titles {
                grid-template-columns: repeat(2, calc(1 * 10rem));
              }
            }
          }
          &.month_period {
            width: calc(18rem + calc(3 * 10rem));
            .pdf_titles {
              .pdf_download {
                grid-template-columns: calc(3 * 10rem);
              }
            }
            &.one_yr_scnr_no_var {
              width: calc(18rem + calc(1 * 10rem));
              .pdf_titles {
                margin-left: 18.05rem;

                .pdf_download {
                  grid-template-columns: 10rem;
                }
              }
            }
          }
          .pdf_titles {
            .quarter_titles {
              grid-template-columns: 1fr;
            }
          }
        }
      }
      .titles {
        position: relative;
        z-index: 45;
        width: calc(92vw);

        &.verts {
          position: fixed;
          top: 5.6rem;
          padding-top: 3.5rem;
        }
        &.pdf_download {
          display: none;
        }

        &.quarter_period {
          &.one_yr_scnr_no_var {
            width: calc(18rem + calc(5 * 10rem));
          }
          &.pdf_download {
            width: calc(168rem + 2rem);
          }
        }
        &.month_period {
          &.pdf_download {
            width: calc(18rem + calc(39 * 10rem));
          }
          &.one_yr_scnr_no_var {
            &.pdf_download {
              width: calc(18rem + calc(13 * 10rem));
            }
          }
        }
        .titles1 {
          position: absolute;
          top: 0;
          left: 0;
          width: 18rem;
          z-index: 45;
          height: 100%;
          .page_title {
            margin-top: 5px;
          }
        }
        .flipped,
        .quarter_content {
          transform: rotateX(180deg);
        }

        .quarter_titles1 {
          overflow-x: auto;
          @include themify($themes) {
            border-left: solid 1px themed("primary-font-color");
          }
          margin-left: 18.05rem;
          @include scroll-bar;
          z-index: 2;
          &.quarter_period {
            &.one_yr_scnr_no_var {
              overflow-x: hidden;
            }
          }
        }
      }

      .tablescombined {
        position: relative;
        overflow-y: hidden;
        overflow-x: hidden;

        &.pdf_download {
          display: none;
        }

        &.quarter_period {
          margin-top: 0rem;
          &.verts {
            margin-top: 2.8rem;
          }
        }
        &.month_period {
          margin-top: 0rem;
          &.verts {
            margin-top: 6rem;
          }
        }

        @include scroll-bar;
        #mtpModels {
          position: relative;
          width: 18rem;
          z-index: 43;
        }

        .table_2 {
          position: absolute;
          top: 0;
          left: 18rem;
          width: calc(92vw - 18rem);
          &.quarter_period {
            &.pdf_download {
              width: 168rem;
            }
            &.one_yr_scnr_no_var {
              width: calc(calc(5 * 10rem));
            }
          }

          &.month_period {
            &.pdf_download {
              width: calc(18rem + calc(39 * 10rem));
            }
            &.pdf_download {
              width: calc(2rem + calc(13 * 10rem));
            }
          }

          overflow-y: hidden;
          overflow-x: auto;
          @include scroll-bar;
        }
        #mtp_category_breakdown {
          position: absolute;
          top: 0;
          left: -18rem;
          overflow: auto;
        }
      }
    }
  }
}
