$white_transparent: rgba(236, 236, 236, 0.2);
$black_transparent: rgba(0, 0, 0, 0.25);
$overlay_white: rgba(236, 236, 236, 0.7);
$dark_blue: rgb(13, 16, 26);
$dark_blue_transparent: rgba(0, 20, 35, 0.9);
$new_overlay: rgba(20, 38, 52, 0.84);
$very_dark_grey: rgb(55, 55, 55);
$bright_blue: rgb(0, 84, 180);
$white: rgb(164, 164, 164);
$disabled_white: rgb(150, 150, 150);
$bright_white: rgb(255, 255, 255);
$bright_white_transparent: rgba(255, 255, 255, 0.8);
$lighter_white: rgb(249, 249, 249);

//$cyan: rgb(110, 184, 228);
$cyan_light: rgb(233, 246, 252);
//$cyan_bright: rgb(51, 144, 206);
$cyan: #00a0dd;
$cyan_bright: lighten($cyan, 25%);
$cyan_light: lighten($cyan, 85%);

$grey: rgb(44, 57, 78);
$grey_transparent: rgba($grey, 0.8);
$overlay_grey: rgba($grey, 0.9);
$overlay_tile: rgba($grey, 0.75);
$grey_very_transparent: rgba($grey, 0.5);
$lowlight_grey: rgba(255, 255, 255, 0.6);
$lightGrey: rgba($white, 0.75);

$grey_hover: rgb(9, 64, 93);
$button_height: 65px;
$canvas_size: 95%;
$amber: rgb(245, 166, 35);
$icon_grey: rgb(108, 109, 111);
$dark_grey: rgb(30, 39, 58);
$active_border: 1.5px solid $bright_white;
$breadcrumbs: rgb(62, 75, 85);
$tooltip: #08486c;
$tooltip_light: #86c9ed;
$warning: rgba(237, 64, 58, 0.9);
$warning_light: rgba(229, 110, 105, 0.9);
$nmef_teal: rgb(30, 175, 180);
$slight_dark_blue: rgba(25, 43, 57, 1);
$background_grey: rgb(17, 32, 44);
$minato_tab: #95685b;
$black: #000000;
$green_highlight: rgb(29, 191, 22);

$thin_border: 1px solid $white_transparent;
$purple: #bb87fc;
$comparison_cyan: #98e2ec;
$green: #7ed321;

// Theme colors
$lightThemeAccentColor: #00a0dd;
$lightKeyIndicatorColor: #2c394e;
$lightBackgroundGrey: #e7ecf0;
$black_thin_border: 1px solid $black_transparent;
// Seo colors
$charts_yellow: #f2c043;
$charts_green: #03dac6;
$charts_purple: #bb86fc;
$charts_red: #d94e3d;
$charts_pink: #ff7597;
$charts_blue: #4f84ec;

//cedar home colors
$main-blue: #2c394e;
$blue-highlight: #009bea;
$blue_light: #006ea4;
$background-white: #e7ecf0;
$header-light-grey: #626262;
$header-dark-grey: #a4a4a4;
