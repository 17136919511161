@import "../colors";
@import "../themes";

.dp_radio,
.dp_label {
  font-size: var(--regular_font_size);
  cursor: pointer;
}

#media_spend_radio {
  margin-left: 1em;
  padding-left: 1em;
}

.dp_radio {
  margin-right: 0.25em;
}

.radio {
  display: flex;
  justify-content: space-evenly;
  z-index: 10;

  div {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    label {
      margin-bottom: 0;

      @include themify($themes) {
        color: themed("primary-font-color");
      }
    }
  }
}
