@import "../colors";
@import "../effects";
@import "../themes";

.midas_media_mix_sotu {
  display: grid;
  grid-gap: 0rem;
  grid-template-areas: "media_mix";
  grid-template-rows: max-content;

  .cluster_wrapper {
    margin-top: 2em;
    margin-bottom: 0;

    .cluster_text {
      margin-left: calc(100vw - 10em);

      &.down {
        margin-bottom: -2em;
      }

      z-index: 4;
      position: relative;
    }
  }

  .pmo_sotu {
    margin-bottom: 0;
  }

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .primary_background {
    @include themify($themes) {
      background: themed("primary-tile-background");
    }
  }

  max-width: calc(100vw - 4em);

  h3 {
    font-size: var(--header_font);
    font-weight: 300;
    margin-bottom: 0.2em;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }

    text-align: left;
  }

  .quarter_title {
    font-size: var(--regular_font_size);
  }

  .legend {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    font-size: var(--regular_font_size);
    padding-left: 0.2em;
    margin-bottom: 0.2em;

    .legend_item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 1em;

      .legend_box {
        width: 1rem;
        height: 1rem;
        margin-right: 1em;
        border-radius: 0.2em;
      }
    }
  }

  .quarter_titles {
    div {
      &.quarter_title {
        @include themify($themes) {
          border-right: solid 1px themed("primary-font-color");
        }
      }
    }
  }

  @media screen and (min-width: 1250px) {
    .media_mix_by_quarter {
      .media_mix_charts {
        padding: 0.5em 1.5em 0.5em;

        .charts {
          display: grid;
          grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
          min-height: 40vh;

          &.strategic {
            grid-template-columns: 1.5fr 1fr 1fr 1fr 1.5fr;
          }

          .quarter_breakdown {
            position: relative;
            display: flex;
            flex-flow: column;
            align-items: center;

            .no_data {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .chartWrapper {
            @include themify($themes) {
              border-right: themed("thinBorder");
            }
          }

          .chartWrapper,
          .graph {
            min-height: 40vh !important;
          }
        }
      }
    }

    .tables {
      overflow-x: auto;
      @include scroll-bar;

      .media_mix_tables {
        padding: 0em 0em 0.5em;
        display: grid;
        width: 116vw;
        grid-template-columns: 1.5fr 1fr 1fr 1fr 1.5fr;

        &.strategic_tables {
          width: 116vw;
          grid-template-columns: 1.5fr 1fr 1fr 1fr 1.5fr;
          padding: 0.5em 1.5em 0.5em;

          &.mediaMix {
            &.combined {
              .quarter_breakdown {
                .quarter_titles {
                  grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr;
                }
              }
            }
          }

          &.combined {
            width: 100%;
            grid-template-columns: 1fr;

            .quarter_breakdown {
              .quarter_titles {
                display: grid;
                width: 100%;
                grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1.5fr;

                .quarter_title {
                  &:first-of-type {
                    border: none;
                  }
                }
              }
            }
          }
        }

        min-height: 5vh;
        overflow-y: auto;
        @include scroll-bar;

        .quarter_breakdown {
          position: relative;

          .divider {
            position: absolute;
            top: 0;
            left: 41.5%;

            &.strategic {
              left: 54.4%;
              display: none;
            }

            width: 1px;
            height: 100%;

            @include themify($themes) {
              border-right: solid 1px themed("primary-font-color");
            }
          }

          @include themify($themes) {
            border: solid 1px themed("primary-font-color");
          }

          text-align: center;

          .no_data {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .media_mix_by_quarter {
      .media_mix_charts {
        .charts {
          min-height: 45vh;

          .chartWrapper,
          .graph {
            min-height: 45vh !important;
          }
        }
      }
    }

    .tables {
      .media_mix_tables {
        min-height: 5vh;
        height: unset;

        &.strategic_tables {
          width: 100%;
          grid-template-columns: 2.7fr 0.85fr 0.85fr 0.85fr 1.1fr;
          // &.combined {
          //   .quarter_breakdown {
          //     .quarter_titles {
          //       width: 91.8vw;
          //       grid-template-columns: 20.5em 15.6% 15.6% 15.7% 15.6% auto;
          //     }
          //   }
          // }
        }

        .quarter_breakdown {
          .divider {
            left: 18.8em;

            &.strategic {
              left: 31.3em;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1920px) {
    .media_mix_by_quarter {
      .media_mix_charts {
        .charts {
          min-height: 45vh;

          .chartWrapper,
          .graph {
            min-height: 45vh !important;
          }
        }
      }
    }

    .tables {
      .media_mix_tables {
        min-height: 5vh;
        height: unset;

        &.strategic_tables {
          width: unset;
          grid-template-columns: 4fr 0.85fr 0.85fr 0.85fr 1.1fr;
          // &.combined {
          //   .quarter_breakdown {
          //     .quarter_titles {
          //       width: 91.8vw;
          //       grid-template-columns: 10% 16% 16% 16% 16.65% 26%;
          //     }
          //   }
          // }
        }

        .quarter_breakdown {
          .divider {
            left: 20.8em;

            &.one_yr_scnr {
              left: 28.8em;
            }

            &.strategic {
              left: 43em;
            }
          }
        }
      }
    }
  }

  table.midas {
    td {
      @media screen and (min-width: 1600px) {
        font-size: 0.85rem;
      }
    }
  }
}
