@import "../colors";
@import "../effects";
@import "../themes";

.custom_progress_container {
  width: 100%;
  height: 6px;
  @include themify($themes) {
    background-color: themed("secondary-font-color");
  }
}

.custom_progress_bar {
  display: block;
  height: 100%;
  background-color: $cyan;
}
