@import "../colors";
@import "../effects";
@import "../themes";

.midas_spend_vs_traffic_sotu {
  .spend_sotu {
    margin-bottom: 2em;
  }
  .cluster_wrapper {
    margin-bottom: 2em;
  }
  .spend_vs_traffic_charts {
    display: grid;
    grid-gap: 0.75rem;
    grid-template-areas: "spend_vs_traffic";
    grid-template-rows: max-content;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 4em;
  }

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  max-width: calc(100vw - 4em);

  h3 {
    font-size: var(--header_font);
    font-weight: 300;
    margin-bottom: 0.15em;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }

    text-align: left;
  }

  .chartWrapper,
  .graph {
    height: 100%;
    width: 100%;
    min-height: 50vh;
  }

  .header {
    display: block;
    height: 5em;

    @media screen and (max-width: 700px) {
      margin-bottom: 1em;
      position: relative;
    }
  }

  .series {
    display: flex;
  }

  .title_container {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .primary_tile_background {
    @include themify($themes) {
      background: themed("primary-tile-background");
    }
  }

  @media screen and (min-width: 1250px) {
    .spend_vs_traffic_charts {
      display: grid;
      grid-gap: 0.75rem;
      grid-template-areas: "visits" "kbas" "leads";
      grid-template-rows: max-content max-content max-content;

      .metric_charts {
        .by_market {
          padding: 1.25em 1.5em 0em;
          .header {
            align-items: unset;
            flex-flow: column;
            height: 10em;
            .axis_titles {
              display: flex;
              flex-flow: row;
              justify-content: space-between;
              // margin-bottom: -3em;
              h3 {
                @include themify($themes) {
                  color: themed("primary-font-color");
                  font-size: 13px;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        .by_model {
          .header {
            padding-left: 1.5em;
            margin-right: 4em;
          }
        }
        .by_market {
          .module_chart {
            .chart_content {
              position: relative;
              height: 45vh !important;

              .no_data {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
        .by_model {
          position: relative;
          .model_charts {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 2em;

            .model_tile {
              margin-top: 1em;
              h3 {
                padding-left: 1.5em;
              }
            }
          }

          .no_data {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .module_chart {
            .chart_content {
              position: relative;
              // min-height: 30vh !important;
              .chartWrapper,
              .graph {
                height: 30vh !important;
              }

              .no_data {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }
      }
    }

    .chartWrapper,
    .graph {
      padding-left: 5px;
      padding-right: 5px;
    }

    .header {
      display: flex;
      align-items: center;
    }
  }

  @media screen and (min-width: 390px) {
    .chartWrapper,
    .graph {
      min-height: 65vh;
    }
  }
}
