@import "root";

@import "fonts";
@import "colors";
@import "effects";
@import "normalize";
@import "themes";

* {
  font-family: "nissan", "Open Sans", sans-serif;
}

body {
  font-family: "nissan", "Open Sans", sans-serif;
  font-size: 16px;

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
}

.pageWrapper {
  margin: 0;
  padding: 0;
  color: $very_dark_grey;

  @include themify($themes) {
    background: themed("backgroundImage");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }

  width: auto;
  min-height: 100vh;

  &.hide_background {
    @include themify($themes) {
      background: themed("ccsDashboardImage");
      background-size: cover;
      background-attachment: fixed;
      background-repeat: no-repeat;
    }
  }

  &.ariya_background {
    background: none;
  }
}

.hidden {
  display: none !important;
}

.hide_on_mobile {
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.hide_on_desktop {
  @media screen and (min-width: 1024px) {
    display: none !important;
  }
}

.dashboard {
  font-size: 9px;
  grid-template-areas:
    "header"
    "body";
  display: grid;
  height: auto;
  grid-gap: 0;
  grid-template-rows: 0.1fr auto;
  grid-template-columns: 1fr;
  width: auto;

  &.pdf_download {
    &.month_period {
      width: 130vw;
      &.one_yr_scnr_no_var {
        width: 100vw;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    display: grid;
    height: 100vh;
    grid-gap: 0;
    grid-template-columns: var(--menu_width) 1fr;
    grid-template-rows: calc(var(--menu_width) + 1.75rem) auto 1fr;
    grid-template-areas:
      "header form"
      "header banner"
      "header body";

    overflow: hidden;

    &.noPageFilter {
      grid-template-areas: "header body" "header body";

      @media screen and (min-width: 1024px) {
        grid-template-rows: var(--menu_width) 1fr;
      }
    }
  }

  #container {
    display: block;
    grid-area: body;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    @include scroll-bar;
  }

  &.iframe_dashboard {
    height: 100vh;

    header {
      height: 100%;
    }
  }

  &.ariya_dashboard {
    height: 100vh;
  }
}

// Material Override
.MuiInputBase-input {
  font-family: "nissan", "Open Sans", sans-serif !important;
  color: $grey_transparent !important;
}

.border {
  @include themify($themes) {
    border-color: themed("borderColor");
  }
}

.primaryFont {
  @include themify($themes) {
    color: themed("primary-font-color");
  }
}

.secondaryFont {
  @include themify($themes) {
    color: themed("secondary-font-color");
  }
}

// Override toastify font size
.Toastify__toast-body {
  font-size: 13px;

  .notification-extra-info {
    font-size: 11px;
  }
}
