@import "../colors";
@import "../themes";

.main_header {
  font-size: 8px;
  grid-template-areas: "cedar_logo profile";
  grid-template-columns: 0.95fr auto;
  grid-template-rows: none;
  display: grid;
  position: unset;
  overflow: auto;

  @include themify($themes) {
    background-color: themed("menu-background");
    box-shadow: themed("menu-shadow");
  }

  align-items: center;

  &:hover {
    overflow: visible;
  }

  &.expanded {
    grid-template-areas: "profile";
    grid-template-columns: 1fr;

    #cedar_logo,
    .burger_div {
      display: none;
    }
  }
}

.burger_div {
  padding: 2em 1em;

  img {
    height: 2em;
  }
}

#cedar_logo {
  padding: 2em 1em;

  img {
    height: 3em;
  }
}

#closeMenu {
  display: none;
  background: none;
  border: none;
  font-size: var(--close_icon_font);
  color: $white;
  right: 1em;
  position: absolute;
  cursor: pointer;
  height: auto !important;
  top: 0.5em;
}

#profile {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-top: none;
  grid-area: profile;

  &.expanded {
    display: flex;
    margin-right: 0;

    @include themify($themes) {
      background-color: themed("menu-background");
    }

    flex-flow: column;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3001;
    padding: 3em;
    justify-content: flex-start;
    padding-top: 25%;
    align-items: center;

    #closeMenu {
      display: inline-block;
    }
  }

  li {
    .icon {
      background-size: 3.5em;
      background-position: center;
      background-repeat: no-repeat;
      height: 34px;
      padding: 0;
      align-self: center;
      display: flex;

      .profile_picture {
        width: fit-content;
        display: flex;
        align-self: center;
        margin: 0 auto;
        gap: unset;
        min-width: 4ch;
        min-height: 4ch;
      }
    }
  }

  #try_button {
    min-width: 400px;
  }

  #buy_button {
    min-width: 400px;
  }

  #use_button {
    min-width: 400px;
  }

  #executive_reporting_button {
    min-width: 400px;
  }
}

#trees_button {
  .icon,
  .menu_icons {
    @include themify($themes) {
      background-image: themed("treesIcon");
    }
  }
}

#fruits_button {
  .icon,
  .menu_icons {
    @include themify($themes) {
      background-image: themed("fruitsIcon");
    }
  }
}

#test_button {
  .icon,
  .menu_icons {
    @include themify($themes) {
      background-image: themed("betaIcon");
    }
  }
}

#seeds_button {
  .icon,
  .menu_icons {
    @include themify($themes) {
      background-image: themed("seedsIcon");
    }
  }
}

#knowledge_hub_button {
  .icon,
  .menu_icons {
    @include themify($themes) {
      background-image: themed("knowledgeHubIcon");
    }
  }
}

#admin,
#contact {
  grid-area: contact;
}

#info {
  grid-area: info;
}

#profile_preferences {
  grid-area: profile_settings;
}

#logout {
  grid-area: logout;
}

#user_profile {
  grid-area: profile;
}

.profile_button {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  max-height: 20em;
  align-content: center;
  height: 57px;
  width: auto;

  &.active,
  &:hover {
    @include themify($themes) {
      background-color: themed("active_menu_background");
    }
  }

  .icon {
    width: 20%;
  }

  .text {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-flow: column;
    align-self: center;

    p {
      display: flex;
      align-items: center;
      white-space: break-spaces;
      text-align: left;
      font-size: var(--filter_font);

      @include themify($themes) {
        color: themed("menuFontColor");
      }

      margin: 0;
    }

    .menu_text_description {
      font-size: calc(var(--filter_font) - 3px);

      @include themify($themes) {
        color: themed("menuFontColor");
      }

      line-height: normal;
    }
  }

  a {
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.2em;
    text-transform: initial;
    width: auto;
    display: block;
    color: $bright_white;
    height: 2em;
    padding-left: 0.5em;
  }

  br {
    display: none;
  }

  .menu_arrow_down {
    width: 0;
    height: 0;
    border-left: var(--span_arrow_border_left_right) solid transparent;
    border-right: var(--span_arrow_border_left_right) solid transparent;

    @include themify($themes) {
      border-top: var(--span_arrow_border_top) solid themed("menuFontColor");
    }

    display: inline-block;
    margin-left: auto;
    margin-right: 2rem;
    align-self: center;
  }
}

.child_button {
  display: none !important;

  &.isShown:not(.hide_on_mobile) {
    display: flex !important;
  }

  @media screen and (min-width: 1024px) {
    &.isShown {
      display: flex !important;
    }
  }
}

@media screen and (min-width: 666px) {
  .burger_div {
    img {
      height: 2.5em;
    }
  }

  #cedar_logo {
    img {
      height: 4em;
    }
  }
}

@media screen and (min-width: 1024px) {
  header {
    grid-area: header;
    display: grid;
    z-index: 1000;
    position: sticky;
  }

  .main_header {
    display: grid;
    grid-template-areas:
      "cedar_logo"
      "profile";
    grid-template-rows: var(--menu_width) auto;
    height: 100%;
    top: 0;
    position: sticky;
    position: -webkit-sticky;
    max-height: 100vh;
    overflow: hidden;
    width: 100%;
    min-width: var(--menu_width);

    &:hover {
      min-width: unset;

      @include themify($themes) {
        background-color: themed("menu-background") !important;
      }
    }

    .cedar_logo {
      display: flex;
      padding: 0.75em 0;
      width: auto;
      height: var(--menu_width);

      img {
        position: relative;
        height: 100%;
        width: auto;
      }
    }

    .home_navigation {
      cursor: pointer;

      @include themify($themes) {
        background-color: themed("menu-background");
        box-shadow: themed("menu-shadow");
      }
    }
  }

  #tree {
    width: 3.75rem;

    @include themify($themes) {
      background-image: themed("cedarTree");
    }

    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
  }

  #text {
    width: 75%;

    @include themify($themes) {
      background-image: themed("cedarText");
    }

    background-size: 65%;
    background-position: center;
    background-position-x: left;
    background-repeat: no-repeat;
  }

  #theme_mode {
    grid-area: theme_mode;
  }

  #profile {
    grid-area: profile;

    @include themify($themes) {
      color: themed("menuFontColor");
    }

    font-size: 0.5em;
    display: grid;
    grid-template-rows: 0.065fr 0.065fr 0.065fr 0.065fr 0.065fr 0.065fr 0.065fr 0.065fr 0.065fr 0.065fr auto auto 0.05fr 0.05fr 0.05fr 0.05fr 0.065fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "icons-1" "icons-2" "icons-3" "icons-4" "icons-5" "icons-6" "icons-7" "icons-8" "icons-9" "gap-1" "gap-2"
      "contact" "info" "profile_settings" "logout" "theme_mode" "profile";
    // min-width: 300px;
    width: auto;
    margin: 0;
    height: 100%;

    @include themify($themes) {
      background-color: themed("menu-background");
      box-shadow: themed("menu-shadow");
    }

    li {
      align-items: center;

      .icon {
        width: var(--menu_width);
        // height: 100%;
        padding: 0;
        background-position: center;
        background-size: 30%;
        background-repeat: no-repeat;
        display: flex;

        .profile_picture {
          font-size: var(--regular_font_size);
          align-self: center;
        }
      }

      .text {
        width: auto;
        display: flex;
        flex-flow: column;
        align-items: flex-start;

        p {
          white-space: nowrap;
          text-transform: uppercase;
        }
      }
    }

    a {
      .text {
        padding-right: 25em;
        width: auto;
        display: flex;
        flex-flow: column;
        align-items: flex-start;

        p {
          white-space: nowrap;
          text-transform: uppercase;
        }
      }
    }
  }

  .profile_button {
    font-size: 0.25rem;
    letter-spacing: 0.2em;
    text-align: center;
    margin: auto 0;
    width: auto;
    height: 100%;
    padding: 0;
    cursor: pointer;
    font-family: "nissan", "Open Sans", sans-serif;
    flex-flow: row;

    &.hide_on_mobile {
      display: flex;
    }

    label {
      margin-right: 10px;
    }

    .react-toggle-track {
      width: 1.5em;
      height: 0.6em;
    }

    .react-toggle-thumb {
      top: 0;
      left: 0;
      width: 0.6em;
      height: 0.6em;
    }

    .react-toggle--checked .react-toggle-thumb {
      left: 0.9em;
    }

    .toggle-label {
      margin-top: 0.3em;
      font-size: 0.8em;
    }
  }
}

@media screen and (min-width: 1250px) {
  .main_header {
    .cedar_logo {
      #tree {
        width: 5rem;
        padding: 0.4em 0;
      }

      #text {
        padding: 0.4em 0;
      }
    }
  }

  #cedar_logo {
    padding: var(--logo_padding);
  }

  #profile {
    .profile_button {
      padding: var(--menu_spacing);

      a {
        font-size: var(--menu_size);
      }
    }

    li {
      .icon {
        width: 5rem;
      }
    }
  }
}

@media screen and (min-width: 1250px) and (max-height: 800px) {
  #profile {
    grid-template-rows: 0.085fr 0.085fr 0.085fr 0.085fr 0.085fr 0.085fr 0.085fr 0.085fr 0.085fr 0.085fr auto auto 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.085fr;

    li {
      .icon {
        width: 5rem;
      }
    }
  }
}

@media screen and (min-width: 1350px) {
  .main_header {
    .cedar_logo {
      #tree {
        width: 4rem;
        padding: 0.1em 0;
      }

      #text {
        padding: 0.1em 0;
      }
    }
  }

  #profile {
    grid-template-rows: 0.09fr 0.09fr 0.09fr 0.09fr 0.09fr 0.09fr 0.09fr 0.09fr 0.09fr 0.09fr auto auto 0.085fr 0.085fr 0.085fr 0.085fr 0.085fr 0.09fr;

    li {
      .icon {
        width: 4rem;
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .main_header {
    .cedar_logo {
      #tree {
        width: 5rem;
        padding: 0.25em 0;
      }

      #text {
        padding: 0.25em 0;
      }
    }
  }

  #profile {
    grid-template-rows: 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr auto auto 0.075fr 0.075fr 0.075fr 0.075fr 0.075fr 0.08fr;

    li {
      .icon {
        width: 5rem;
      }
    }
  }
}

@media screen and (min-width: 1900px) {
  .main_header {
    .cedar_logo {
      #tree {
        width: 5.75rem;
        padding: 0.5em 0;
      }

      #text {
        padding: 0.5em 0;
      }
    }

    #profile {
      .profile_button {
        .icon {
          width: 5.75rem;
        }
      }
    }
  }
}

@media screen and (min-width: 2300px) {
  .main_header {
    grid-template-rows: 7.5rem auto;
    width: 7.5rem;

    .cedar_logo {
      padding: 1em 0;

      #tree {
        width: 7.5rem;
        padding: 0.75em 0;
      }

      #text {
        padding: 0.75em 0;
      }
    }

    #profile {
      .profile_button {
        .icon {
          width: 7.5rem;
        }
      }
    }
  }
}

@media screen and (min-width: 2500px) {
  .main_header {
    #profile {
      .profile_button {
        .icon {
          width: 7.5rem;
          background-size: 37.5%;
        }
      }
    }
  }
}

@media screen and (min-width: 3150px) {
  .main_header {
    grid-template-rows: 10rem auto;
    width: 10rem;

    .cedar_logo {
      padding: 1.5em 0;

      #tree {
        width: 10rem;
        padding: 1em 0;
      }

      #text {
        padding: 1em 0;
      }
    }

    #profile {
      .profile_button {
        .icon {
          width: 10rem;
        }
      }
    }
  }

  #profile {
    grid-template-rows: 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr 0.08fr auto auto 0.075fr 0.075fr 0.075fr 0.075fr 0.075fr 0.08fr;
  }
}

@media screen and (min-width: 3800px) {
  .main_header {
    grid-template-rows: 12rem auto;
    width: 12rem;

    .cedar_logo {
      padding: 2em 0;

      #tree {
        width: 12rem;
      }
    }

    #profile {
      .profile_button {
        .icon {
          width: 12rem;
        }
      }
    }
  }
}
