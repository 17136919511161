@import "../../../../../components/Layouts/Ariya/styles/colors";

.momentsCardReset {
  padding: 0;
  padding-right: 1rem;
  border: none;
}

.momentsContentReset {
  max-height: unset;
}
