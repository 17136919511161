@import "../../../../components/Layouts/Ariya/styles/colors";
@import "../../../../components/Layouts/Ariya/styles/themes";

.card {
  @include themify($themes) {
    border: 1px solid themed("borderColor");
  }
  border: solid 1px $borderGrey;
  border-radius: 10px;
  padding: 2.5em 1.5em;
  margin-bottom: 2rem !important;
  height: 100%;

  display: flex;
  flex-flow: column;
}

.headerContainer {
  padding-bottom: 0.5em;
  margin-bottom: 2rem;
  @include themify($themes) {
    border-bottom: 2px solid themed("borderColor");
  }
  border-bottom: 2px solid $white;
}

.header {
  font-size: 0.875rem;
}

.headerDescription {
  @include themify($themes) {
    color: themed("secondaryFontColor");
  }
  font-size: 0.625rem;
  text-align: left;
}

.contentBox {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  height: 100%;

  @media screen and (min-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.content {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 5em;

  @media screen and (min-width: 990px) {
    margin-bottom: 0;
    padding: 2em;

    &:nth-child(odd) {
      border-right: 1px solid $borderGrey;
      padding-left: 0;
    }

    &:nth-child(odd),
    &:nth-child(even) {
      border-bottom: 2px solid $borderGrey;
    }

    &:last-of-type {
      border-right: none;
    }
  }
}

.monitorCardReset {
  border: none;
  padding: 0;
  padding-right: 2em;
}

.momentsCardReset {
  padding: 0;
  padding-right: 2rem;
  border: none;
}

.momentsContentReset {
  max-height: unset;
}

.noBottom {
  border-bottom: none !important;
}
