.cardContainer {
  margin-bottom: 35px;
  display: grid;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-template-areas: "major major major" "major2 major2 major2" "major3 major3 major3";
  }
}

.topCardContainer {
  max-width: 100%;
  margin-bottom: 5em;
  grid-template-columns: 1fr;

  @media screen and (min-width: 750px) {
    grid-template-columns: repeat(12, 1fr);
  }
}

.major {
  @media screen and (min-width: 1024px) {
    grid-area: major;
  }
}

.major2 {
  @media screen and (min-width: 1024px) {
    grid-area: major2;
  }
}

.major3 {
  @media screen and (min-width: 1024px) {
    grid-area: major3;
  }
}

.monitorCardReset {
  border: none;
  padding: 0;
  padding-right: 2em;
  cursor: unset;
  height: 100%;
}
