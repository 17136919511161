.cardContainer {
  margin-bottom: 35px;
  display: grid;

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-template-areas: "chart table table" "major2 major2 major2" "major3 major3 major3";
  }
}
.chartCard {
  grid-column: auto !important;
  @media screen and (min-width: 1024px) {
    grid-area: chart;
  }
}

.tablesCard {
  @media screen and (min-width: 1024px) {
    grid-area: table;
  }
}
