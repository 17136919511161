@import "../colors";
@import "../themes";
@import "../effects";

.dropdown {
  width: 100%;
  position: relative;
  border: solid 1.5px $white;
  border-radius: 5em;
  background-color: transparent;
  cursor: pointer;
  input {
    max-width: 85%; /* WebKit-based browsers will ignore this. */
    cursor: pointer;
    border: none;
    border-radius: none !important;
    color: $bright_white;
  }
  .arrow_down {
    width: 0;
    height: 0;
    border-left: var(--span_arrow_border_left_right) solid transparent;
    border-right: var(--span_arrow_border_left_right) solid transparent;
    border-top: var(--span_arrow_border_top) solid #00a0dd;
    position: absolute;
    right: 3%;
    top: 40%;
  }
}

.dropdown-options {
  background-color: #fff;
  padding: 0.25em 0;
  position: absolute;
  z-index: 999;
  text-align: left;
  box-shadow: 0 0 0.5em rgba(164, 164, 164, 0.5);
  max-height: 15em;
  color: rgba(44, 57, 78, 0.8);
  left: 0;
  right: 0;
  top: 100%;
  text-transform: initial;
  margin: 0.5em 0 0;
  li {
    list-style: none;
    padding: 0.5em;
    cursor: pointer;
    font-size: var(--form_font);
    letter-spacing: 0;
    font-family: "nissan", "Open Sans", sans-serif;
    display: inline-block;
    width: 100%;
    word-break: break-word;
  }
  .multiselectDiv {
    width: 100%;

    input {
      font-size: var(--form_font);
      font-family: "nissan", "Open Sans", sans-serif;
      cursor: pointer;
      background: none;
      display: inline-block;
      border: none;
      width: 2em;
      margin: auto 0;
    }

    label {
      padding: 0.5em 0;
      cursor: pointer;
      font-size: var(--form_font);
      letter-spacing: 0;
      font-family: "nissan", "Open Sans", sans-serif;
      display: inline-block;
      word-break: break-word;
      color: $grey_transparent;
      word-break: break-word;
      margin: 0;
      @include no-select;
    }
  }

  .nested_list {
    display: flex;
    align-items: center;
    padding: 0 0.5rem 0 0;
    justify-content: left;

    .nested_list_span {
      width: 0.5rem;
      height: 100%;
      padding: 0.5em;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        display: inline-block;
        border: solid;
        border-width: 0 var(--arrow_border_width) var(--arrow_border_width) 0;
        padding: var(--arrow_padding);
      }
      .market-arrow {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      &.hidden {
        display: none;
      }
    }
    &.down {
      .nested_list_span {
        .market-arrow {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }
    }
  }
}

.model_region_list {
  margin: 0.2em 0 0;
  padding: 0 0 0 1rem;
  display: none;
  &.show-regions {
    display: block;
  }
}
.region_country_filter {
  margin: 0.2em 0 0;
  padding: 0 0 0 1rem;
  display: none;
  &.show-markets {
    display: block;
  }
}

#geography_options {
  overflow-y: auto;
  @include scroll-bar;
}

#businessFunction_options,
#department_options {
  max-height: 10em;
  overflow-y: auto;
  overflow-x: hidden;
  @include scroll-bar;
}

#businessFunction_options,
#department_options > li {
  color: $black;
}
