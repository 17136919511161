.LegendMarker {
  display: flex;
  content: " ";
  width: 1.8em;
  height: 1em;
  border: 0.5em solid;
  align-self: center;
  margin-right: 1em;

  &.square {
    border-radius: 2px;
  }

  &.line {
    border-radius: 0;
    display: block;
    height: 4px;
    border: 0;
    border-top: 4px solid;
    margin: auto 0;
    padding: 0;
  }
}

.LegendMarkerMixed {
  display: flex;
  content: " ";
  width: 0.5em;
  height: 1em;
  border: 0.5em solid;
  align-self: center;
  margin-right: 1em;

  &.square {
    border-radius: 2px;
  }

  &.line {
    border-radius: 0;
    display: block;
    height: 4px;
    border: 0;
    border-top: 4px solid;
    margin: auto 0;
    padding: 0;
  }
}
