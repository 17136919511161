@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700");

@font-face {
  font-family: "nissan_light";
  src: url("/assets/fonts/nissan_brand_light-webfont.woff2") format("woff2"),
    url("/assets/fonts/nissan_brand_light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "nissan";
  src: url("/assets/fonts/nissan_brand_regular-webfont.woff2") format("woff2"),
    url("/assets/fonts/nissan_brand_regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "nissan_bold";
  src: url("/assets/fonts/NissanBrand-Bold.woff2") format("woff2"), url("/assets/fonts/NissanBrand-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
