@import "../colors";
@import "../effects";
@import "../themes";

.search_container {
  display: flex;
  padding: 0.5rem 0;

  .input_arrow {
    padding: 0.2rem 0;
  }

  .search_criteria {
    width: 40%;

    .arrow_down {
      left: 0;
      bottom: 20%;
    }

    .filter_input {
      margin-left: 1rem;
    }

    @include themify($themes) {
      border-bottom: 1px solid themed("borderColor");
    }

    .filter_header {
      padding: 0.5rem 0;
    }
  }
}

.search_bar {
  display: flex;
  align-items: flex-end;

  @include themify($themes) {
    border-bottom: 1px solid themed("borderColor");
  }

  input {
    font-size: var(--regular_font_size);
  }

  span {
    height: 1rem;
    width: 1rem;

    &.search {
      background-image: url(../../images/Filter/search-icon.svg);
    }

    &.cancel {
      background-image: url(../../images/Filter/cancel.svg);
    }
  }
}
