@import "../../../../../components/Layouts/Ariya/styles/colors";
@import "../../../../../components/Layouts/Ariya/styles/themes";

.headerContainer {
  margin-bottom: 5px;
}

.header {
  font-size: 1rem;
  font-weight: normal;
  text-transform: capitalize;
}
.headerDescription {
  font-size: 0.65rem;
  @include themify($themes) {
    color: themed("secondaryFontColor");
  }
}

.progress {
  height: 10px !important;
  border-radius: 3px !important;
}

.progressBackground {
  background-color: rgba($grey, 0.25) !important;
}

.barColor {
  background-color: $accent_brown !important;
}
