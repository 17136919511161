@import "../colors";
@import "../effects";

.tippy-popper {
  transition-duration: 0ms !important;

  * {
    transition-duration: 0ms !important;
  }
}

.normal_tippy {
  display: flex !important;
  margin-left: 0.5em;
}

.info_tooltip {
  background-color: transparent;
  @include themify($themes) {
    color: themed("secondary-font-color");
  }
  // color: $white;
  width: 1.275rem;
  height: 1.275rem;
  font-family: "nissan_light", "Open Sans", sans-serif;
  font-size: 1rem;
  // float: right;
  text-align: center;
  align-self: center;
  // justify-self: end;
  z-index: 1;
  line-height: 1.15rem;
  cursor: pointer;
  @include themify($themes) {
    background-image: themed("infoTooltipIcon");
  }
  background-size: 1.1em;
  background-position: center;
  background-repeat: no-repeat;
  grid-area: header;

  &.model_data_button {
    display: none;
  }
  margin-right: 0.5rem;
}

.key_indicator_header {
  display: flex;
  align-items: center;
}

.key_indicator_tooltip {
  margin-left: 0.2rem;
  grid-area: header;
}

.dark-theme {
  background-color: rgba(0, 0, 0, 0.8);
  color: $bright_white;
  border-radius: 0.5em;
  font-size: calc(var(--regular_font_size) - 0.15em);
  font-weight: 400;
  max-width: 650px;
  width: 280px;
  padding: 0.35em;
  // margin-left: -16rem;
  @include no-select();
  max-height: 60vh;
  overflow-y: auto;
  @include scroll-bar;

  @media screen and (min-width: 1600px) {
    width: 280px;
    // margin-left: -16rem;
  }

  @media screen and (max-width: 1600px) {
    width: 200px;
  }

  .tippy-tooltip-content {
    text-align: left;
    flex-flow: column;

    p {
      text-align: left;
    }

    li {
      text-align: left;
      padding-left: 2em;
      list-style-position: inside;
    }

    details {
      margin-top: 0.75em;

      &:focus,
      &:active {
        outline: none;
      }
    }

    summary {
      &:focus,
      &:active {
        outline: none;
      }

      cursor: pointer;
    }

    div {
      margin-top: 0.5em;
      flex-flow: column;
    }
  }
}
