@import "../colors";
@import "../themes";

.breadcrumb {
  text-align: center;
  display: flex;
  border-radius: 5px;
  padding-right: 18px;
}

.breadcrumb__step {
  text-decoration: none;
  outline: none;
  display: flex;
  font-size: 12px;
  line-height: 36px;
  padding: 0 10px 0 30px;
  position: relative;
  background: $cyan;
  color: $bright_white;
  transition: background 0.5s;
  cursor: pointer;
  justify-content: center;
  width: 100%;
  white-space: nowrap;

  // arrow
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: -18px;
    width: 36px;
    height: 36px;
    transform: scale(0.707) rotate(45deg);
    z-index: 1;
    border-radius: 0 5px 0 50px;
    background: $cyan;
    transition: background 0.5s;
    box-shadow: 2px -2px 0 2px $bright_white;
  }

  &:last-child {
    &::after {
      background: $cyan;
      box-shadow: 2px -2px 0 2px $bright_white;
      right: -17px;
    }
  }

  &:first-child {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -18px;
      width: 36px;
      height: 36px;
      transform: scale(0.707) rotate(45deg);
      z-index: 1;
      border-radius: 0 5px 0 50px;
      background: $bright_white;
      transition: background 0.5s;
      box-shadow: 2px -2px 0 2px $bright_white;
    }
  }

  &--active,
  &:hover {
    color: $bright_white;
    background: $dark_grey;

    &::after {
      background: $dark_grey !important;
    }
  }
}
