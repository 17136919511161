@import "../../../components/Layouts/Ariya/styles/colors";

.cardContainer {
  max-width: 100%;
  margin-bottom: 5em;
  grid-template-columns: 1fr;

  @media screen and (min-width: 750px) {
    grid-template-columns: repeat(12, 1fr);
  }
}
