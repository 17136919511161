@import "../colors";
@import "../effects";
@import "../themes";

.primary_background {
  @include themify($themes) {
    background: themed("primary-tile-background");
  }
}

.under_construction_content {
  min-height: 70vh;

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  max-width: calc(100vw - 4em);

  h3 {
    font-size: var(--header_font);
    font-weight: 300;
    margin-bottom: 0.2em;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }

    text-align: left;
  }

  .no_data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
