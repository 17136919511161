@import "../../../../components/Layouts/Ariya/styles/colors";
@import "../../../../components/Layouts/Ariya/styles/scroll_bar";

.filterContainer {
  position: relative;

  @media screen and (min-width: 1024px) {
    max-width: 17.5pc;
  }
}

.cityFilter {
  display: flex;
  flex-flow: column;
  padding: 0.5em;
  border: 1px solid $lowlight_grey;
}

.filterTitle {
  color: $lightGrey;
  font-size: 0.8rem;
}

.inputWithArrow {
  width: 100%;
  background: none;
  border: none;
  padding: 2px 25px;
  padding-bottom: 0;
  cursor: pointer;
  padding-left: 0.15em;
  margin-top: 0.25em;

  background-image: url("../../../../assets/images/Ariya/arrows/DownArrow.svg");
  background-size: 0.75em;
  background-repeat: no-repeat;
  background-position: 97% 65%;
}

.filterOptions {
  display: none;
  list-style-type: none;
  background-color: $white;
  color: $black;
  position: absolute;
  width: 100%;
  z-index: 2;
  max-height: 270px;
  overflow-y: auto;
  @include scroll-bar();
}

.showFilters {
  display: block;
}

.filterItem {
  font-size: 1rem;
  padding: 5px;
  cursor: pointer;

  &:hover {
    color: $white;
    background-color: $accent_brown;
  }
}

.withBorderBottom {
  border-bottom: 1px solid $grey;
}
