@import "../../../components/Layouts/Ariya/styles/colors";
@import "../../../components/Layouts/Ariya/styles/effects";
@import "../../../components/Layouts/Ariya/styles/themes";

.cardContainer {
  max-width: 100%;
  margin-bottom: 5em;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: "search" "visitors" "visits" "moments" "content" "views";

  @media screen and (min-width: 750px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-template-areas: "views search" "visitors visits" "moments content";
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas: "views search visitors" "visits moments content";
  }
}

.views {
  grid-area: views;
}

.search {
  grid-area: search;
}

.visitors {
  grid-area: visitors;
}

.visits {
  grid-area: visits;
}

.moments {
  grid-area: moments;
}

.content {
  grid-area: content;
}

.globalSection {
  margin-bottom: 2.5em;
  margin-top: 0.5em;
}

.sectionTitle {
  font-size: 0.75rem;
  color: $accent_brown;
  font-weight: bold;
  font-family: "nissan_bold", "Open Sans", sans-serif;
  text-align: left;
}

.sectionValue {
  font-size: 1.875rem;
  font-family: "nissan_bold", "Open Sans", sans-serif;
  font-weight: bold;
}

.sectionLastDayValue {
  font-size: 0.75rem;
  font-weight: normal;
}

.lastDayText {
  @include themify($themes) {
    color: themed("secondaryFontColor");
  }
  color: $lightGrey;
}

.viewsWrapper {
  display: flex;
  flex-flow: column;

  @media screen and (min-width: 600px) {
    flex-flow: row;
  }
}

.tableWrapper {
  display: flex;
  flex-flow: column;
  // height: 100%;
}

.showMore {
  color: $accent_brown;
  margin: auto;
  margin-bottom: 0;
  margin-right: 0;
  cursor: pointer;
  width: fit-content;
  font-size: 1rem;

  @include no-select();
}
