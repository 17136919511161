@import "../colors";
@import "../effects";
@import "../themes";

.customer-journey-voc {
  font-size: var(--regular_font_size);

  @include themify($themes) {
    color: themed("primary-font-color");
  }

  .grid-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .category-tile {
    @include themify($themes) {
      color: themed("primary-font-color") !important;
    }

    position: relative;

    .title {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: $cyan;
      padding: 1rem 0;
      color: $bright_white;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
    }

    .tile-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      .tile {
        display: flex;

        .title {
          all: unset;
        }

        .value-container {
          font-size: var(--small_number_metric);
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
        }

        .comparison-container,
        .response-volume {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .performance-by-market {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
    }

    height: 45rem;
    border: none;
    border-radius: 0;
    display: flex;

    .tile-header {
      display: flex;
      font-size: var(--header_font);
      margin-bottom: 1rem;
      justify-content: space-between;
      align-items: center;

      .title-container {
        display: flex;

        .title {
          display: flex;
          flex-direction: column;
        }

        .sub-text {
          font-size: var(--smaller_regular_font_size);
        }
      }

      .filters-container {
        display: flex;
      }
    }
  }

  .mapWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;

    .map {
      height: 100%;
      width: 85%;
    }

    #legenddiv {
      width: 15%;
    }
  }
}
