@import "../colors";
@import "../effects";
@import "../themes";

.vocPage {
  @media screen and (max-width: 767px) {
    width: 90vw;

    .average-star-rating-tile {
      flex-flow: column;
    }
  }

  .tableContainer {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
      color: themed("primary-font-color");
    }

    width: fit-content;

    @media screen and (max-width: 767px) {
      width: 100%;
      overflow-x: auto;
      @include scroll-bar;
    }

    table {
      border: 2px solid gray;
      border-collapse: collapse;
      table-layout: fixed;
      width: auto;

      thead th:not(:last-of-type),
      tbody td:not(:last-of-type) {
        border-right: 2px solid gray;
      }

      th {
        font-weight: bold;
      }

      td {
        font-size: var(--smaller_regular_font_size);
      }

      th:first-of-type {
        font-size: var(--header_font);
      }

      td,
      th {
        padding: 0.9em 0.4em !important;
        width: 130px;
      }

      tbody tr:nth-of-type(odd) {
        @include themify($themes) {
          background-color: themed("tableOddColor") !important;
        }
      }
    }

    .italic {
      font-style: italic;
    }
  }

  .tile {
    @include themify($themes) {
      background-color: themed("primary-tile-background");
      color: themed("primary-font-color");
    }

    border: none !important;
    border-radius: 0 !important;
  }

  .min-h-20 {
    min-height: 20rem;
  }

  .h-15 {
    height: 15rem;
  }

  .h-30 {
    height: 30rem;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 2em;

    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  .devicesContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    width: 100%;
    gap: 2em;
    padding: 0 2em;
    text-align: center;

    .device {
      display: flex;
      flex-direction: column;
      margin: auto;
    }

    .rating {
      font-size: var(--small_number);
      line-height: 0.5em;

      .rating-subtext {
        line-height: 0.5em;
        font-size: var(--smaller_regular_font_size);
      }
    }

    .variance {
      font-size: var(--smaller_regular_font_size);
    }
  }

  .deviceIcon {
    margin-top: 0.5rem;
    width: 100%;
    height: 10em;
    object-fit: contain;
  }

  .tableIcon {
    transform: rotate(90deg);
  }

  .row {
    margin-bottom: 2em;
  }

  $start: 1;
  $end: 6;

  @for $i from $start through $end {
    .grid-col-span-#{$i} {
      grid-column: span #{$i};
    }
  }

  .tileHeaderContainer {
    font-size: var(--header_font);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tileHeader {
    font-size: var(--header_font);
    padding: 0.5em;
    display: flex;
  }

  .ratings {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--smaller_regular_font_size);
    margin: auto;

    .value {
      text-align: center;
    }

    .actualValue {
      font-size: var(--small_number);
    }
  }

  .textGreen {
    color: $green;
  }

  .textAmber {
    color: $charts_yellow;
  }

  .textRed {
    color: $charts_red;
  }

  .m-auto {
    margin: auto;
  }

  .text-center {
    text-align: center;
  }

  .space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .full-height {
    height: 100%;
  }

  #marketBreakdownTablePt1,
  #marketBreakdownTablePt2,
  #electrificationTable,
  #vlpFocusTable,
  #otherTouchpointsTable,
  #mediaCampaignsTable,
  #ownershipTable,
  #editorialTable,
  #CompletionRateTable {
    .disabled {
      background-color: inherit;
    }

    .Metric {
      text-align: left;
    }

    td[rowspan]:nth-of-type(even) {
      @include themify($themes) {
        background-color: themed("primary-tile-background");
      }
    }

    tr.odd {
      td:first-of-type {
        width: 30%;
      }

      td:nth-of-type(2),
      td:nth-of-type(3),
      td:nth-of-type(5) {
        width: calc(60% / 3);
      }

      td:nth-of-type(4),
      td:nth-of-type(6) {
        width: calc(10% / 2);
      }
    }
  }

  #otherTouchpointsTable,
  #mediaCampaignsTable,
  #marketBreakdownTablePt1 {
    tbody {
      tr:nth-of-type(1),
      tr:nth-of-type(2) {
        background-color: $cyan !important;
      }
    }

    tfoot {
      display: none;
    }
  }

  .chipContainer {
    display: flex;

    .chip {
      @include themify($themes) {
        color: themed("primary-font-color");
        font-size: var(--tooltip_font);
      }

      padding: 0.3em 1.8em;
      font-weight: 900;
      border-radius: 0.5em;
      margin: 1em;

      @media screen and (max-width: 767px) {
        padding: 0.3em 1em;
        margin: 1em 0.7em;
      }
    }
  }

  .market-answers {
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: var(--header_font);
    width: 10%;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .bold {
    font-weight: 900;
  }

  .flex-row {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .categoryContainer {
    width: 90%;
    height: 100%;
  }

  .verbatimTile {
    padding-top: unset;
    overflow-x: hidden;
    overflow-y: auto;
    @include scroll-bar;

    @media screen and (max-width: 767px) {
      overflow-x: auto;
    }

    .verbatim-tile-header {
      padding-top: 0.5em;
      position: sticky;
      top: 0;

      @include themify($themes) {
        background-color: themed("primary-tile-background");
      }

      z-index: 2;
    }
  }

  .filter {
    border-right: none;
    width: 250px;
  }

  .verbatimTable {
    table-layout: fixed;

    thead {
      position: sticky;
      top: 44px;

      @include themify($themes) {
        background-color: themed("primary-tile-background");
      }

      z-index: 0;
    }

    .Market {
      width: 80px;
    }

    .Date {
      width: 80px;
    }

    .Experience {
      width: 120px;
    }

    .Topic {
      width: 150px;
      word-wrap: break-word;

      .link {
        color: $cyan;
      }
    }

    .Touchpoints {
      width: 150px;
    }

    .Subcategory {
      width: 150px;
    }

    .Device {
      width: 150px;
    }

    .URL {
      width: 250px;
      word-wrap: break-word;

      .link {
        color: $cyan;
      }
    }

    .Comment {
      word-wrap: break-word;

      @media screen and (max-width: 767px) {
        word-wrap: normal;
      }
    }
  }

  .verbatim-count {
    font-size: var(--header_font);
    padding: 10px;
    margin-right: 6rem;
  }

  .market-overview-toggle-container {
    display: flex;
    justify-content: flex-end;
  }

  .market-overview-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .tippy-tooltip {
    width: 300px;
  }
}

.tippy-tooltip-content {
  width: 100%;
}

.no-data-tile {
  width: 100%;
  height: 75vh;

  @include themify($themes) {
    background-color: themed("primary-tile-background");
    color: themed("secondary-font-color");
  }

  font-size: var(--regular_font_size);
  display: flex;
  align-items: center;
  justify-content: center;
}
