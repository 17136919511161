@import "../colors";
@import "../themes";

.profileWrapper {
  @include themify($themes) {
    color: themed("secondary-font-color");
  }
  font-size: var(--regular_font_size);
  display: grid;
  grid-area: body;
  grid-template-areas:
    "header"
    "body";
  align-items: center;
  grid-gap: 2em;
  padding: 1em 2em 0;
}

.user_header {
  grid-area: header;
  display: flex;
  align-items: center;
  @include themify($themes) {
    color: themed("secondary-font-color");
  }
}

.profile_settings {
  grid-area: body;
  // Todo: remove when preferences are added
  // max-width: 950px;
  // justify-self: center;
  // width: 100%;

  input {
    @include themify($themes) {
      border-color: themed("primary-font-color");
      color: themed("primary-font-color");
    }
    text-overflow: ellipsis;
  }

  input[readonly] {
    @include themify($themes) {
      border-color: themed("secondary-font-color");
      color: themed("secondary-font-color");
    }
    cursor: unset;
  }

  input[type="submit"] {
    border: none;
    @include themify($themes) {
      background-color: $cyan;
    }
    color: $bright_white;
    cursor: pointer;

    &:disabled {
      background-color: $white;
      cursor: unset;
    }
  }
}

.profile_name {
  margin-left: 1em;
  font-size: var(--regular_font_size);
}

.profile_section {
  margin-bottom: 5em;
}

.profile_section__header {
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}

.profile_section__header--icon {
  width: 1.75em;
  height: 1.75em;
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

.profile_section__header--title {
  margin-left: 0.5em;
}

#myProfile {
  .profile_section__header--icon {
    @include themify($themes) {
      background-image: themed("profileIcon");
    }
  }
}

#myPreferences {
  .profile_section__header--icon {
    @include themify($themes) {
      background-image: themed("preferencesIcon");
    }
    transform: rotate(-90deg);
  }
}

.profile_section__body {
  .input_field {
    display: flex;
    align-content: center;
    flex-flow: row;
    justify-content: flex-end;

    label {
      margin: auto;
      margin-left: 0.5em;
      white-space: nowrap;
    }

    .field {
      position: relative;
      width: 85%;

      .dropdown {
        @include themify($themes) {
          border: solid 1.5px themed("primary-font-color");
          color: themed("primary-font-color");
        }
        input {
          @include themify($themes) {
            color: themed("primary-font-color");
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  #userProfile {
    grid-template-areas: "header body" "header body" "header body";
  }
  .profileWrapper {
    grid-template-rows: auto 1fr;
    align-self: baseline;
  }
}

@media screen and (min-width: 1250px) {
  .profile_body {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .profile_section {
    width: 100%;
  }
  .profile_section_gap {
    width: 5em;
  }
}

@media screen and (min-width: 1350px) {
  .profile_section__body {
    .input_field {
      display: flex;
      align-content: center;
      flex-flow: row;
      justify-content: flex-end;

      label {
        margin: auto;
        margin-left: 0.5em;
        max-width: 50px;
        white-space: pre-wrap;
      }

      .field {
        position: relative;
        width: 85%;
      }
    }
  }
  .profile_section__header {
    width: 100%;
    justify-content: flex-end;
  }

  .profile_section__header--icon {
    margin-right: 2em;
    width: 1.45em;
    height: 1.45em;
  }

  .profile_section__header--title {
    width: 85%;
  }
}

@media screen and (min-width: 1400px) {
  .profile_section__body {
    .field {
      width: 90%;
    }
  }

  .profile_section__header--title {
    width: 90%;
  }
}

@media screen and (min-width: 1425px) {
  .profile_section__body {
    .field {
      width: 75%;
    }
  }
  .profile_section__header--title {
    width: 75%;
  }
}
