@import "../../components/Layouts/Ariya/styles/colors";

.btn {
  color: $accent_brown !important;
  border: 2px solid $accent_brown !important;
  border-radius: 15px !important;
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
  font-family: "nissan_bold", "Open Sans", sans-serif;
  padding: 13px !important;
  min-width: 15em !important;

  &:hover {
    border: 2px solid $accent_brown !important;
    background-color: $accent_brown !important;
  }
}
