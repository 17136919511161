@import "../colors";
@import "../effects";
@import "../themes";

.dis-table {
  border-spacing: 0;
  border: 0 !important;
  margin-bottom: 1rem;

  @include themify($themes) {
    border: 1px solid themed("primary-font-color");
    color: themed("primary-font-color");
  }

  thead {
    tr:first-of-type {
      th:first-of-type {
        border-bottom: 0;
      }

      th:nth-of-type(2),
      th:nth-of-type(3) {
        @include themify($themes) {
          border-top: 1px solid themed("primary-font-color");
          background-color: #aeabab;
          color: #fff;
        }
      }
    }
    tr:nth-of-type(2) {
      th:nth-of-type(2),
      th:nth-of-type(3),
      th:nth-of-type(4),
      th:nth-of-type(5),
      th:nth-of-type(6),
      th:nth-of-type(7) {
        @include themify($themes) {
          background-color: #aeabab;
          color: #fff;
        }
      }
    }
  }

  tbody {
    tr {
      td:first-of-type {
        @include themify($themes) {
          border-left: 1px solid themed("primary-font-color");
        }
      }
    }
  }

  tr:first-of-type {
    th {
      font-weight: bold;
    }
  }

  tr:last-of-type {
    td {
      @include themify($themes) {
        border-bottom: 1px solid themed("primary-font-color");
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;

    @include themify($themes) {
      border-right: solid 1px themed("primary-font-color");
      border-bottom: solid 1px themed("primary-font-color");
    }
  }

  .parent-row {
    background-color: #00a0dd;
    color: white;
  }

  .summary-row {
    background-color: #002060;
    color: white;
  }
}
