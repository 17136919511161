@import "../colors";
@import "../effects";
@import "../themes";

table {
  width: 100%;
  height: fit-content;

  &.media_mix,
  &.cpo_operational_table,
  .mtp {
    @include themify($themes) {
      border-top: solid 1px themed("primary-font-color") !important;
    }
  }

  &.media_mix,
  &.mtp {
    .blue {
      background-color: #3498db;
    }

    .dark_purple {
      background-color: #8e44ad;
    }

    .total_highlight {
      @include themify($themes) {
        background-color: themed("totalHighlight");
      }
    }

    .Var {
      @include themify($themes) {
        background-color: themed("varHighlight");
      }
    }
  }
}

table * {
  font-weight: 300;

  .bold_row {
    font-weight: 600;
  }

  .border_right {
    @include themify($themes) {
      border-right: solid 1px themed("primary-font-color") !important;
    }
  }
}

tr {
  &.odd {
    @include themify($themes) {
      background-color: themed("tableOddColor") !important;
    }

    & + tr {
      &.child-row {
        @include themify($themes) {
          background-color: themed("tableOddColor") !important;
        }
      }
    }
  }

  &.even {
    background-color: transparent;
  }
}

th,
td {
  font-size: var(--regular_font_size);
  text-align: center;

  &:first-of-type {
    text-align: left;
  }
}

.media_mix {
  th,
  td {
    text-align: right;

    &.odd {
      @include themify($themes) {
        background-color: themed("tableOddColor") !important;
      }
    }

    &.even {
      @include themify($themes) {
        background-color: themed("tableEvenColor") !important;
      }
    }

    &:first-of-type {
      text-align: left;
    }
  }

  &.one_yr_scnr {
    th,
    td {
      text-align: center;
    }
  }
}

.q1 {
  th,
  td {
    text-align: right;

    &:first-of-type {
      text-align: left;
    }
  }
}

.cpo_operational_table {
  th,
  td {
    text-align: center;
    min-width: 12% !important;
    max-width: 12% !important;

    &:first-of-type {
      text-align: left;
      min-width: 10% !important;
      max-width: 10% !important;
    }
  }

  .child_table {
    th,
    td {
      text-align: center;
      min-width: 12% !important;
      max-width: 12% !important;

      &:first-of-type {
        text-align: left;
        min-width: 10% !important;
        max-width: 10% !important;
      }
    }
  }
}

#dealer_breakdown {
  border-top: none !important;

  @include themify($themes) {
    border-bottom: solid 1px themed("primary-font-color") !important;
  }
}

.strategic {
  &.q1 {
    th,
    td {
      width: 22.5%;

      &:first-of-type {
        width: 55%;
      }
    }
  }

  &.combined {
    th,
    td {
      text-align: center;
      min-width: 80px !important;
      max-width: 80px !important;

      &:first-of-type {
        text-align: left;
        min-width: 81px !important;
        max-width: 81px !important;
      }
    }

    &.one_yr_scnr {
      th,
      td {
        text-align: center;

        &:first-of-type {
          text-align: left;
          min-width: 51px !important;
          max-width: 51px !important;
        }

        &:nth-of-type(2) {
          min-width: 69px !important;
          max-width: 69px !important;
        }

        &:last-of-type {
          min-width: 120px !important;
        }
      }
    }

    &.mediaMix {
      &.one_yr_scnr {
        th,
        td {
          text-align: center;

          &:first-of-type {
            text-align: left;
            min-width: 51px !important;
            max-width: 51px !important;
          }

          &:nth-of-type(2) {
            min-width: 69px !important;
            max-width: 69px !important;
          }

          &:last-of-type {
            min-width: 80px !important;
          }
        }
      }
    }
  }
}

td {
  padding: 0.4em !important;
}

.td_comparison {
  display: flex;
  justify-content: center;
  align-items: center;
}

.td_comparison_value {
  min-width: 3.5em;
  //text-align: center;
}

.clickable {
  cursor: pointer;
  text-decoration: underline;
}

.mtp {
  .clickable {
    display: inline-block;
    width: 100px;
    z-index: 2;
    position: relative;
  }
}

.disable_click {
  &:not(.filter-link) {
    cursor: unset;
    pointer-events: none;
  }
}

.specific_link {
  @include themify($themes) {
    color: themed("link_color");
  }

  // color: $cyan;
  cursor: pointer;

  &:hover {
    color: $cyan_bright;
  }
}

.child_table {
  .clickable {
    cursor: unset;
    text-decoration: none;
    @include no-select;
  }

  .filter-link {
    @include themify($themes) {
      color: themed("link_color");
    }

    // color: $cyan;
    cursor: pointer;

    &:hover {
      color: $cyan_bright;
    }
  }

  th,
  td {
    &:first-of-type {
      padding-left: 1em !important;
    }
  }
}

td[colspan] {
  padding: 0 !important;
}

.sortable {
  &:after {
    content: "";
    margin-left: 0.25rem;
    padding: 0 0.25em;

    @include themify($themes) {
      background-image: themed("tableSortUnsorted");
    }

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.up:after {
    @include themify($themes) {
      background-image: themed("tableSortAsc");
    }
  }

  &.down:after {
    @include themify($themes) {
      background-image: themed("tableSortDesc");
    }
  }

  &.disabled:after {
    background-image: none !important;
  }
}

.specified_metric {
  .active {
    @include themify($themes) {
      color: themed("primary-font-color");
    }
  }

  td {
    @include themify($themes) {
      color: themed("secondary-font-color");
    }
  }

  .specific_link {
    pointer-events: none;

    @include themify($themes) {
      color: themed("secondary-font-color");
    }

    &.active {
      @include themify($themes) {
        color: themed("link_color");
      }

      cursor: pointer;
      pointer-events: unset !important;
    }
  }
}

#visits_trend_breakdown {
  position: relative;

  thead {
    position: sticky;
    top: 0;

    @include themify($themes) {
      background: themed("sectionBackgroundColor") !important;
    }
  }

  tr {
    th,
    td {
      width: 20%;

      &:first-of-type {
        width: 40%;
      }
    }
  }
}

.q1 {
  th,
  td {
    text-align: right;

    &:first-of-type {
      text-align: left;
    }
  }
}

.mtp,
.cpo_operational_table {
  tr {
    @include themify($themes) {
      border-bottom: solid 1px themed("primary-font-color") !important;
    }
  }

  th,
  td {
    text-align: center;

    @include themify($themes) {
      border-top: solid 1px themed("primary-font-color") !important;
    }

    &.odd {
      @include themify($themes) {
        background-color: themed("tableOddColor") !important;
      }
    }

    &.even {
      @include themify($themes) {
        background-color: themed("tableEvenColor") !important;
      }
    }

    &:first-of-type {
      text-align: left;
    }
  }
}

#mtp_category_breakdown_child,
#dealer_breakdown_child {
  tr {
    &.even {
      display: none;
    }
  }
}

.combined {
  tr {
    th,
    td {
      min-width: 10rem !important;
      max-width: 10rem !important;

      &:first-of-type {
        // min-width: 220px !important;
        // max-width: 220px !important;
        min-width: 18rem !important;
        max-width: 18rem !important;
      }
    }
  }

  .child_table {
    tr {
      th,
      td {
        min-width: 10rem !important;
        max-width: 10rem !important;
        border-top: none !important;

        &:first-of-type {
          // min-width: 218px !important;
          // max-width: 218px !important;
          min-width: 18rem !important;
          max-width: 18rem !important;
        }
      }
    }
  }
}

.modelsTable {
  tr {
    th,
    td {
      min-width: 18rem !important;
      max-width: 18rem !important;
    }
  }

  .child_table {
    tr {
      th,
      td {
        min-width: 18rem !important;
        max-width: 18rem !important;
        border-top: none !important;
      }
    }
  }
}

#appRatingsComments {
  th,
  td {
    text-align: left;

    &:first-of-type {
      width: 12%;
    }

    &:nth-of-type(2) {
      text-align: center;
      width: 4%;
      padding-right: 1.5em;
    }

    &:nth-of-type(3) {
      width: 47%;
      word-break: break-word;
      overflow-wrap: break-word;
    }

    &:nth-of-type(4) {
      width: 12%;
      word-break: break-word;
      overflow-wrap: break-word;
    }

    &:nth-of-type(5) {
      width: 25%;
      word-break: break-word;
      overflow-wrap: break-word;
    }

    &.red {
      background-color: rgba(red, 0.5);
    }

    &.orange {
      background-color: rgba(orange, 0.5);
    }

    &.yellow {
      background-color: rgba(yellow, 0.5);
    }

    &.light-green {
      background-color: rgba(greenyellow, 0.5);
    }

    &.dark-green {
      background-color: rgba(green, 0.5);
    }
  }
}

.kpi_table {
  th,
  td {
    font-size: var(--regular_font_size);
    text-align: left;
  }

  th {
    padding-left: 1em;
  }

  td {
    padding: 0.4em 1em !important;
  }
}

.filter_reset {
  padding: 1em;
  font-size: var(--regular_font_size);
  text-align: left;

  span {
    color: $cyan;
    cursor: pointer;
  }
}

.table_div {
  text-align: left;

  li {
    list-style: none;
    padding: 0.2em 1em;

    &:before {
      display: inline-block;
      content: "-";
      width: 1em;
      margin-left: -1em;
    }
  }
}

.sort_filter_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.column_filter {
  width: 2rem;

  &_icon {
    background-image: url("../../../assets/images/Filter/filter-results-button.svg");
    background-position: center;
    width: 100%;
    height: var(--regular_font_size);
    background-size: contain;
    background-repeat: no-repeat;

    &.active {
      background-image: url("../../../assets/images/Filter/filter-results-button-blue.svg");
    }
  }

  input {
    color: rgba(44, 57, 78, 0.8);
    margin: 0.5em;
    border-color: $cyan;
  }
}

#total_handraiser_table,
#preorder_city_table {
  td,
  th {
    padding: 2px;
    font-size: 0.85rem;
    text-align: center;
    line-height: 0.9rem;
    height: 2rem;

    &:first-of-type {
      text-align: right;
      width: 20%;
    }

    &:nth-of-type(2) {
      width: auto;
    }

    &:nth-of-type(3),
    &:nth-of-type(4) {
      width: 20%;
      text-align: right;
      padding-right: 1.5em;
    }
  }

  th {
    @include themify($themes) {
      color: themed("primaryFontColor");
    }

    outline: none !important;
    font-weight: normal;

    &:nth-of-type(3),
    &:nth-of-type(4) {
      span {
        @media screen and (min-width: 666px) {
          white-space: nowrap;
        }
      }
    }

    &:focus {
      outline: none;
    }
  }

  thead,
  tbody {
    tr {
      th,
      td {
        &:first-of-type {
          width: auto;
        }

        &:nth-of-type(2) {
          width: 100%;
        }

        &:nth-of-type(3) {
          width: 35%;
        }

        &:nth-of-type(3),
        &:nth-of-type(4) {
          padding-right: 1.5em !important;
        }
      }
    }
  }

  tbody {
    tr {
      &.odd {
        @include themify($themes) {
          background-color: themed("tableOddBackground");
        }
      }
    }
  }

  .progress-bar {
    position: relative;
    display: block;

    span {
      background-color: #95685b;
      display: block;
      text-align: right;
      padding-right: 2px;
      line-height: 1.05rem;
    }
  }
}

.pagination {
  display: flex;
  width: 100%;
  justify-content: end;
  margin-bottom: 1rem;
  align-items: center;
  font-size: var(--regular_font_size);
}
